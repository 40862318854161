import Constants from 'arts/config/constants';
import { loadWallApi } from 'arts/config/api';

export const fetchWall = (id, sharingToken) => (dispatch) => {
  dispatch({
    type: Constants.Wall.FETCH,
    id,
    sharingToken
  });
  loadWallApi(id, sharingToken).then((wall) => dispatch({
    type: Constants.Wall.FETCH_SUCCESS,
    wall,
    sharingToken
  })).catch((error) => {
    if (error.status === 404) {
      window.location.href = '/walls';
    }
  });
};
