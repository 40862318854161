import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Classes from './styles';

const Warning = ({ warning, flat }) => (
  warning ? (
    <div className={ classnames(Classes.warning, { [Classes.flatWarning]: flat }) }>
      { warning }
    </div>
  ) : null
);

Warning.propTypes = {
  warning: PropTypes.string,
  flat: PropTypes.bool
};

export default Warning;
