import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  status: 'select',
  method: null
});

const checkoutProcess = createReducer(getDefaultState(), {
  [Constants.checkoutProcess.setMethod]: (state, { method }) => ({
    ...state,
    method
  }),
  [Constants.applePaySession.SUCCESS]: (state) => ({
    ...state,
    status: 'select'
  }),
  [Constants.checkoutProcess.process]: (state) => ({
    ...state,
    status: 'processing'
  }),
  [Constants.checkoutProcess.placementError]: (state, { error }) => ({
    ...state,
    status: 'error',
    error
  })
});

export default checkoutProcess;
