import { connect } from 'react-redux';
import {
  fetchOriginalArts, resetOriginalArts, fetchOriginalArtsFilters
} from 'arts/actions/original_arts';
import OriginalArts from './original_arts';

const mapStateToProps = ({ originalArts, originalArtFilters: { filters } }) => ({
  ...originalArts,
  originalArtFilters: filters
});

export default connect(mapStateToProps, {
  fetchOriginalArts, resetOriginalArts, fetchOriginalArtsFilters
})(OriginalArts);
