import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Classes from './styles';

const ShuffleButton = ({
  setShuffleMode, shuffleMode, className, isDarkBackground
}) => {
  if (!window.hasShuffle) { return null; }
  const changeShuffleMode = (e, { checked }) => setShuffleMode(checked);
  return (
    <div className={ classnames(Classes.swap, className, { [Classes.darkBG]: isDarkBackground }) }>
      <span className={ Classes.shuffleLabel }>SWAP</span>
      <Checkbox
        toggle
        checked={ shuffleMode }
        className={ Classes.shuffleToggle }
        onChange={ changeShuffleMode }
      />
    </div>
  );
};

ShuffleButton.propTypes = {
  setShuffleMode: PropTypes.func.isRequired,
  shuffleMode: PropTypes.bool.isRequired,
  className: PropTypes.string,
  isDarkBackground: PropTypes.bool
};

export default ShuffleButton;
