import React from 'react';
import { connect } from 'react-redux';
import CollectionsRenderer from './collections';
import { loadCollections, loadDesignerPicks, loadGiftGuides } from '../../actions/collections';

const mapStateToPropsCollections = ({ collections }) => collections;
const mapStateToPropsDesignerPicks = ({ designerPicks }) => designerPicks;
const mapStateToPropsGiftGuides = ({ giftGuides }) => giftGuides;

const CollectionsWrapper = connect(mapStateToPropsCollections, {
  loadCollections
})(CollectionsRenderer);

const PicksWrapper = connect(mapStateToPropsDesignerPicks, {
  loadCollections: loadDesignerPicks
})(CollectionsRenderer);

const GiftGuiddesWrapper = connect(mapStateToPropsGiftGuides, {
  loadCollections: loadGiftGuides
})(CollectionsRenderer);

export const Collections = () => <CollectionsWrapper title="Shops" />;
export const DesignerPicks = () => <PicksWrapper title="Designer Picks" />;
export const GiftGuides = () => <GiftGuiddesWrapper title="Gift Guides" withGiftCard />;
