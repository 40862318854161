import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { workShape } from 'arts/reducers/work';
import Info from './info';
import ScrollAppearMonitor from './scroll_appear_monitor';
import MoreLikeBar from './more_like_bar';

import Classes from './styles';

const WorkListItem = ({
  data,
  loading,
  onAppear,
  position,
  onClick,
  setMlBannerWorkId,
  mlBannerWorkId,
  bannerIndex,
  skipAsyncLoading
}) => {
  const {
    href,
    title,
    imageUrl,
    description,
    id,
    skipMoreLikeThis
  } = data;

  const to = {
    pathname: href,
    state: data
  };

  const shareHref = `${ window.location.protocol }//${ window.location.host }${ href }`;
  const shareDesc = `${ title } at ArtfullyWalls, ${ description }`;
  const likesProps = {
    href: shareHref,
    imageUrl,
    description: shareDesc,
    id,
    setMlBannerWorkId
  };

  const onAppearPosition = () => onAppear(position);

  const onItemClick = () => onClick(position);

  const moreLikeBar = !skipMoreLikeThis && bannerIndex === position + 1 ? (
    <MoreLikeBar
      workId={ mlBannerWorkId }
      setMlBannerWorkId={ setMlBannerWorkId }
      key={ mlBannerWorkId }
    />
  ) : null;

  return (
    <>
      <li className={ classnames(Classes.root, `work-${ id }`, { [Classes.loading]: loading }) }>
        <ScrollAppearMonitor
          onAppear={ onAppear ? onAppearPosition : null }
          className={ Classes.anchor }
        >
          {
            (inView) => (
              <Link to={ to } className={ Classes.keeper } title={ title } onClick={ onItemClick }>
                {
                  inView || skipAsyncLoading ? (
                    <img
                      alt={ title }
                      src={ imageUrl }
                      className={ Classes.image }
                    />

                  ) : null
                }
              </Link>
            )
          }
        </ScrollAppearMonitor>
        <Info { ...data } likesProps={ likesProps } setMlBannerWorkId={ setMlBannerWorkId } />
        <div className={ Classes.loader } />
      </li>
      { moreLikeBar }
    </>
  );
};

WorkListItem.propTypes = {
  data: workShape,
  loading: PropTypes.bool.isRequired,
  onAppear: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  position: PropTypes.number,
  setMlBannerWorkId: PropTypes.func,
  mlBannerWorkId: PropTypes.number,
  bannerIndex: PropTypes.number,
  skipAsyncLoading: PropTypes.bool
};

export default WorkListItem;
