import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  error: null,
  loading: false,
  balance: null,
  loaded: false,
  giftCardNumber: null,
  showForm: true,
  locale: null
});

const giftCardBalance = createReducer(getDefaultState(), {
  [Constants.GiftCardBalance.FETCH](state, { giftCardNumber }) {
    return {
      ...state, loaded: false, loading: true, giftCardNumber, showForm: false
    };
  },
  [Constants.GiftCardBalance.FETCH_SUCCESS](state, { balance, giftCardNumber, locale }) {
    if (giftCardNumber === state.giftCardNumber) {
      return {
        ...state, balance, loaded: true, loading: false, locale
      };
    }
    return { ...state };
  },
  [Constants.GiftCardBalance.FETCH_FAILURE](state, { error }) {
    return {
      ...state, loaded: true, loading: false, error
    };
  },
  [Constants.GiftCardBalance.CLEAR]() {
    return getDefaultState();
  }
});

export default giftCardBalance;
