import React from 'react';

const SvgComponent = (props) => (
  <svg width={ 15 } height={ 16 } viewBox="0 0 15 16" fill="none" { ...props }>
    <g clipPath="url(#clip0_303_259)">
      <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM14.3536 4.35355C14.5488 4.15829 14.5488 3.84171 14.3536 3.64645L11.1716 0.464467C10.9763 0.269205 10.6597 0.269205 10.4645 0.464467C10.2692 0.659729 10.2692 0.976312 10.4645 1.17157L13.2929 4L10.4645 6.82843C10.2692 7.02369 10.2692 7.34027 10.4645 7.53553C10.6597 7.7308 10.9763 7.7308 11.1716 7.53553L14.3536 4.35355ZM1 4.5L14 4.5L14 3.5L1 3.5L1 4.5Z" fill="black" />
      <path d="M14 12.5C14.2761 12.5 14.5 12.2761 14.5 12C14.5 11.7239 14.2761 11.5 14 11.5L14 12.5ZM0.646447 11.6464C0.451184 11.8417 0.451184 12.1583 0.646447 12.3536L3.82843 15.5355C4.02369 15.7308 4.34027 15.7308 4.53553 15.5355C4.7308 15.3403 4.7308 15.0237 4.53553 14.8284L1.70711 12L4.53553 9.17157C4.7308 8.97631 4.7308 8.65973 4.53553 8.46447C4.34027 8.2692 4.02369 8.2692 3.82843 8.46447L0.646447 11.6464ZM14 11.5L1 11.5L1 12.5L14 12.5L14 11.5Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_303_259">
        <rect width="15" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
