import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import Routes from './routes';
import SearchIcon from './search_icon';
import CartItemsPopup from '../cart_items_popup';
import LocaleSelection from '../../../shared/locale_selection';
import CookiesConsent from '../../../shared/cookies_consent';

const history = createBrowserHistory();

const Application = ({ store }) => (
  <Provider store={ store }>
    <Router history={ history } basename={ window.currentLocale.basename }>
      <Routes />
    </Router>
    <SearchIcon history={ history } />
    <CartItemsPopup />
    <LocaleSelection />
    <CookiesConsent />
  </Provider>
);

Application.propTypes = {
  store: PropTypes.object.isRequired
};

export default Application;
