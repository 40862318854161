import { connect } from 'react-redux';
import { addGiftCardToCart } from 'arts/actions/cart_items';
import GiftCardAddToCart from './gift_card_add_to_cart';

const mapStateToProps = ({ cartItems: { added, adding } }) => ({
  added, adding
});

export default connect(mapStateToProps, {
  addGiftCardToCart
})(GiftCardAddToCart);
