import { connect } from 'react-redux';
import { fetchProductsList } from 'arts/actions/productsList';
import { addProductToCart } from 'arts/actions/cart_items';
import ProductsList from './productsList';

const mapStateToProps = ({ productsList: { data, error, loading } }) => ({
  data,
  error,
  loading
});

export default connect(mapStateToProps, {
  addProductToCart, fetchProductsList
})(ProductsList);
