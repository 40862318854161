import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import { toCamelCase } from '../../wall_builder/config/utils';

const getDefaultState = () => ({
  loading: false,
  items: toCamelCase(window.designer_picks || [])
});

export default createReducer(getDefaultState(), {
  [Constants.DesignerPicks.LOAD]: () => ({
    ...getDefaultState(),
    loading: true
  }),
  [Constants.DesignerPicks.LOADED]: (_, { items }) => ({
    loading: false,
    items
  })
});
