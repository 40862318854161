import { connect } from 'react-redux';
import {
  fetchOriginalAuthorArt
} from 'arts/actions/original_arts';
import ArtistOriginalArts from './artist_original_arts';

const mapStateToProps = ({ authorOriginalArt }, { id }) => ({
  id, ...authorOriginalArt[id]
});

export default connect(mapStateToProps, {
  fetchOriginalAuthorArt
})(ArtistOriginalArts);
