export const uniqCollection = (collection, batch) => {
  const res = [...collection];
  batch.forEach((w) => {
    if (!res.find((ew) => ew.id === w.id)) {
      res.push(w);
    }
  });
  return res;
};

const splitCollection = (collection, id) => {
  const index = collection.findIndex((i) => i.id === id);
  if (index === -1) {
    return [collection, null, null];
  }
  const before = collection.slice(0, index);
  const after = collection.slice(index + 1);
  const item = collection[index];
  return [before, after, item];
};

export const removeItemById = (collection, id) => {
  // eslint-disable-next-line no-unused-vars
  const [before, after, item] = splitCollection(collection, id);
  if (!after) {
    return before;
  }
  return [...before, ...after];
};

export const updateCollectionStoreItems = (collection, id, storeItemId) => {
  const [before, after, item] = splitCollection(collection, id);
  if (!after) {
    return before;
  }
  const newItem = { ...item, storeItemId };
  return [...before, newItem, ...after];
};
