import React from 'react';

const SvgComponent = (props) => (
  <svg
    viewBox="0 0 100 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93 7H7v63h86V7ZM0 0v77h100V0H0Z"
    />
    <path d="M33 23h34v29H33V23Z" fill="#D9D9D9" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 26H36v23h28V26Zm-31-3v29h34V23H33Z"
      fill="#676767"
    />
  </svg>
);

export default SvgComponent;
