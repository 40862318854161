import React from 'react';
import { connect } from 'react-redux';
import { fetchArtFilters } from 'arts/actions/works';
import { fetchWorksPage } from 'arts/actions/tapestries';
import Works from '../works/works';
import TopBanner from './top_banner';

const mapStateToProps = (
  {
    tapestries: {
      collection, error, loading, page, totalPages, loadedAt
    },
    artFilters: { filters }
  }
) => ({
  works: collection,
  error,
  loading,
  page,
  totalPages,
  artFilters: filters,
  loadedAt,
  hideFilters: true,
  gtmSection: 'wall tapestries',
  topBanner: <TopBanner />
});

export default connect(mapStateToProps, {
  fetchWorksPage,
  fetchArtFilters
})(Works);
