/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SubscribePopup from '../subscribe_to_newsletter/subscribe_popup';

const About = ({ allowMailSubscription, disallow }) => {
  const [doShow, setShow] = useState(false);

  const hidePopup = () => setShow(false);

  const popup = doShow ? <SubscribePopup hidePopup={ hidePopup } disallow={ disallow } /> : null;

  const onClick = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const content = allowMailSubscription ? (
    <div>
      Quality&nbsp;
      <a href={ `${ window.currentLocale.basename }/works?src=top` }>Hand-Picked Prints</a>
      &nbsp; / &nbsp;Affordable Prices&nbsp; / &nbsp;Save 15% Off Your Purchase!&nbsp;
      <a href="#" onClick={ onClick } style={ { textDecoration: 'underline' } }>Details</a>
      { popup }
    </div>
  ) : (
    <div>
      Quality&nbsp;
      <a href={ `${ window.currentLocale.basename }/works?src=top` }>Hand-Picked Prints</a>
      &nbsp; / &nbsp;
      <a href={ `${ window.currentLocale.basename }/walls?src=top` }>Gallery Walls</a>
      &nbsp;to Inspire and Shop &nbsp;/&nbsp; Affordable Prices
    </div>
  );
  return content;
};

About.propTypes = {
  allowMailSubscription: PropTypes.bool.isRequired,
  disallow: PropTypes.func.isRequired
};

export default About;
