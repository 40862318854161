import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';

const PleaseWait = ({ method, skipRedirectingToPaypal }) => {
  let extra = null;
  if (method === 'paypal' && !skipRedirectingToPaypal) {
    extra = (
      <p>Redirecting to PayPal.</p>
    );
  } else if (method === 'affirm') {
    extra = (
      <p>Redirecting to Affirm.</p>
    );
  }
  return (
    <>
      <Loader active inline>Please wait...</Loader>
      { extra }
    </>
  );
};

PleaseWait.propTypes = {
  method: PropTypes.string.isRequired,
  skipRedirectingToPaypal: PropTypes.bool
};

export default PleaseWait;
