import { connect } from 'react-redux';
import { fetchArtistInfo } from 'arts/actions/artistWorks';
import ArtistWorks from './ArtistWorks';

const mapStateToProps = ({ artistWorks, authorOriginalArt }, ownProps) => {
  const { artistId } = ownProps.match.params;
  const aSplit = artistId.split(/-/);
  const id = parseInt(aSplit[aSplit.length - 1], 10);
  aSplit.pop();
  const slug = aSplit.join('-');
  const { collection, artistInfo } = artistWorks;

  const { id: currentId } = artistInfo || {};

  return {
    id,
    slug,
    works: id === currentId ? collection : [],
    artistInfo: id === currentId ? artistInfo : {},
    hasOriginalArt: !!(
      ((authorOriginalArt || {})[id] || {}).userOriginalArt || []
    ).length
  };
};

export default connect(mapStateToProps, { fetchArtistInfo })(ArtistWorks);
