import React, { useEffect, useState } from 'react';
import Loader from 'arts/components/Loader';
import classnames from 'classnames';
import { request, toCamelCase } from 'arts/../shared/request';
import GTMPageView from '../Application/data_layer';
import { trackEvent } from '../../config/analytics';
import Classes from '../collections/styles';
import Collection from '../collections/collection';
import AppClasses from '../Application/styles';

export const POPULAR_CATEGORIES = 'Popular Wall Art Categories';

const WallArts = () => {
  const [seoCategories, setSeoCategories] = useState(null);

  useEffect(() => {
    if (window.serializedCategories) {
      setSeoCategories(toCamelCase(window.serializedCategories));
    } else {
      request('/api/wall-art')
        .then((data) => {
          setSeoCategories(toCamelCase(data));
        }).catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (seoCategories && seoCategories.length) {
      trackEvent(POPULAR_CATEGORIES.toLowerCase(), { 'items count': seoCategories.length });
    }
  }, [seoCategories && seoCategories.length]);

  const collection = !seoCategories ? (
    <Loader text="Loading, hold on for a sec..." />
  ) : (
    <>
      <h1>{ POPULAR_CATEGORIES }</h1>
      <ul className={ classnames(Classes.grid, AppClasses.gridContainer2inRow) }>
        {
          seoCategories.map((i) => (
            <Collection
              key={ i.id }
              name={ i.title }
              collectionHref={ `/wall-art/${ i.slug }` }
              description={ i.subTitle }
              topMenuImage={ i.imageHref }
              actionLabel="Discover"
            />
          ))
        }
      </ul>
    </>
  );

  return (
    <div className={ AppClasses.container20 }>
      { seoCategories ? <GTMPageView pageType="category" section={ POPULAR_CATEGORIES } /> : null }
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        { collection }
      </div>
    </div>

  );
};

export default WallArts;
