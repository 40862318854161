import React from 'react';

const WA = (props) => (
  <svg
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      d="m21.559 25.084 2.367-1.608h-.001a1.852 1.852 0 0 1 1.959-.071l3.02 1.737c.25.143.459.341.61.58a1.683 1.683 0 0 1 .122 1.6 1.746 1.746 0 0 1-.515.658l-1.064.838c-1.043.82-2.52 1.339-4.084.952-1.94-.483-5.257-1.724-8.521-4.873a20.84 20.84 0 0 1-5.212-8.036c-.67-1.915.138-3.805 1.505-4.955l.569-.478c.204-.172.446-.297.707-.366a1.864 1.864 0 0 1 1.54.273c.218.154.398.354.525.585l1.916 3.474c.193.35.256.754.179 1.143-.078.39-.29.741-.604.998l-1.503 1.231a17.815 17.815 0 0 0 2.924 3.673 16.754 16.754 0 0 0 3.561 2.645Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.5 20c0 3.367.854 6.538 2.359 9.304L.56 37.574a1.493 1.493 0 0 0 .38 1.489 1.49 1.49 0 0 0 1.056.437c.147 0 .29-.02.428-.061l8.271-2.298A19.419 19.419 0 0 0 20 39.5c10.77 0 19.5-8.73 19.5-19.5S30.77.5 20 .5.5 9.23.5 20ZM20 3.5C10.887 3.5 3.5 10.887 3.5 20c0 2.857.725 5.541 2 7.882a1.5 1.5 0 0 1 .445 1.52l-1.79 6.442 6.444-1.79a1.5 1.5 0 0 1 1.52.446 16.42 16.42 0 0 0 7.881 2c9.113 0 16.5-7.387 16.5-16.5S29.113 3.5 20 3.5Z"
    />
  </svg>
);

export default WA;
