import { connect } from 'react-redux';
import {
  setFrame,
  resetSelection,
  setNonGlare
} from 'arts/actions/selection';
import PapersMenu from './papers_menu';
import { currentPriceAndNonGlare } from '../AddToCart/add_to_cart';

const mapStateToProps = ({ selection, work: { thumbnails, data: { currency } } }) => ({
  selection,
  ...currentPriceAndNonGlare(selection, 'currentPrice'),
  thumbnails,
  currency
});

export default connect(mapStateToProps, { setFrame, resetSelection, setNonGlare })(PapersMenu);
