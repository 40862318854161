import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MODAL_SELECTOR } from 'arts/config/constants';
import { trackEvent } from 'arts/config/analytics';
import AppClasses from '../Application/styles';
import Modal from '../Modal';

const ThanksPopup = ({ onCancel }) => {
  useEffect(() => {
    trackEvent('subscribe thanks');
  }, []);

  return (
    <Modal
      selector={ MODAL_SELECTOR }
      onCancel={ onCancel }
      className={ AppClasses.thanksForAction }
    >
      <h2>
        Thank you!
      </h2>
      <p>
        You are now subscribed to get our latest news.
      </p>
      <div className={ AppClasses.div } />
      <div className={ AppClasses.action }>
        <button type="button" className={ AppClasses.button } onClick={ onCancel }>
          CONTINUE
        </button>
      </div>
    </Modal>
  );
};

ThanksPopup.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default ThanksPopup;
