import T from 'prop-types';

import {
  workShape
} from 'arts/reducers/work';

const worksShape = T.arrayOf(workShape);

export {
  worksShape
};
