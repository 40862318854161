import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { FORMAT } from '../../checkout_summary/checkout_summary';
import { renderItem } from '../../collection_details/collection';
import AppClasses from '../../Application/styles';
import Classes from '../styles';

const Items = ({
  id, items, hideSibar, addShoppableLookToCart
}) => {
  if (!items) {
    return null;
  }

  const renderedItems = items.map((i) => renderItem(null, i, null, true)[1]);
  const totalPirce = items.reduce((acc, i) => acc + parseFloat(i.workPicture.price, 10), 0);
  numeral.locale(window.currentLocale.currentLocale);
  const renderTotal = numeral(totalPirce).format(FORMAT);

  const addToCard = () => addShoppableLookToCart(id);

  return (
    <>
      <div className={ Classes.header }>
        <h2 className={ Classes.shopTheLook }>
          SHOP THIS LOOK
        </h2>
        <button type="button" onClick={ hideSibar } className={ Classes.close }>&times;</button>
      </div>
      <ul className={ AppClasses.gridContainer1inRow }>
        { renderedItems }
      </ul>
      <div className={ Classes.footer }>
        <div className={ Classes.total }>
          Total:&nbsp;
          { renderTotal }
        </div>
        <button type="button" onClick={ addToCard }>
          ADD TO CART
        </button>
      </div>
    </>
  );
};

Items.propTypes = {
  id: PropTypes.number.isRequired,
  items: PropTypes.array,
  hideSibar: PropTypes.func.isRequired,
  addShoppableLookToCart: PropTypes.func.isRequired
};

export default Items;
