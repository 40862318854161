import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import StripeForm from './stripe_form';

const Stripe = ({
  stripeKey, clientSecret, redirectUrl, billingAddress
}) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(stripeKey));
  }, []);

  if (!stripePromise) {
    return null;
  }

  const appearance = {
    theme: 'stripe'
  };

  const options = {
    clientSecret,
    appearance
  };

  return (
    <Elements stripe={ stripePromise } options={ options }>
      <StripeForm
        clientSecret={ clientSecret }
        redirectUrl={ redirectUrl }
        billingAddress={ billingAddress }
      />
    </Elements>
  );
};

Stripe.propTypes = {
  stripeKey: PropTypes.string.isRequired,
  clientSecret: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  billingAddress: PropTypes.object
};

export default Stripe;
