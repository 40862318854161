import Constants from 'arts/config/constants';
import { placeOrderApi } from 'arts/config/api';
import { trackEvent } from 'arts/config/analytics';

export const setMethod = (method) => ({
  type: Constants.checkoutProcess.setMethod,
  method
});

let onCloseBound = false;

const onError = (dispatch, error) => dispatch({ type: Constants.checkoutProcess.placementError, error: error || 'Your order cannot be placed at this moment, please try again later.' });

export const placeOrder = (orderId, unsubscribe) => (dispatch, getState) => {
  dispatch({ type: Constants.checkoutProcess.process });
  const { method } = getState().checkoutProcess;
  // TODO mixpanel event here
  trackEvent('order placed web', { method, orderId, unsubscribe });
  if (method === 'cc') {
    document.getElementById('creditcard-form').submit();
    return;
  }
  placeOrderApi(method, orderId, unsubscribe).then((response) => {
    const {
      placementError: error, paymentHref, affirmCheckoutObject, applePaySessionConfig
    } = response;
    if (error || response.error) {
      onError(dispatch, error || response.error);
      return;
    }
    // cc - has a regular submit form, cannit be here
    // paypal && pp_order
    if (paymentHref) {
      window.location.href = paymentHref;
      return;
    }
    // affirm
    if (affirmCheckoutObject) {
      const affirmObject = JSON.parse(affirmCheckoutObject);
      if (!onCloseBound) {
        window.affirm.ui.ready(() => {
          onCloseBound = true;
          window.affirm.ui.error.on('close', () => {
            onError(dispatch);
          });
        });
      }
      window.affirm.checkout(affirmObject);
      window.affirm.checkout.open();
    }
    // apple pay
    if (applePaySessionConfig) {
      dispatch({ type: Constants.applePaySession.SUCCESS, applePaySessionConfig });
    }
  }).catch(() => {
    onError(dispatch);
  });
};
