import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import SearchBar from './search_bar';

const SearchIcon = (props) => {
  useEffect(() => {
    const ps = document.getElementById('permanent-search');
    if (ps) {
      const root = createRoot(ps);
      root.render(<SearchBar { ...props } />);
    }
    const msi = document.getElementById('mobile-search-item');
    if (msi) {
      const root = createRoot(msi);
      root.render(<SearchBar { ...props } isToggable />);
    }
  }, []);
  return null;
};
export default SearchIcon;
