import React, { useMemo } from 'react';
import queryString from 'query-string';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useWindowSize } from './use_window_size';
import Eye from '../Viewer/eye';
import Favorite from '../favorite';
import Classes from './styles';

const {
  w1, w2, w3, w4
} = Classes;

const ROWS_MAPPING = [
  [parseInt(w4, 10), 3, 'c3w'],
  [parseInt(w3, 10), 3, 'c3n'],
  [parseInt(w2, 10), 2, 'c2w'],
  [parseInt(w1, 10), 2, 'c2n']
];

const columnsPerWidth = (width) => {
  const w = ROWS_MAPPING.find(v => (v[0] <= width));
  if (w) {
    return { columnsCount: w[1], className: w[2], groupsCount: 3 };
  }
  return { columnsCount: 2, className: 'c2m', groupsCount: 1 };
};

const linkPath = ({ id, exploreContext, explore }) => {
  const path = `/more-like-this/${ id }`;
  const o = {};
  if (explore) { o.explore = true; }
  if (exploreContext) { o.explore_context = exploreContext; }
  const extraPath = queryString.stringify(o);
  if (extraPath) {
    return `${ path }?${ extraPath }`;
  }
  return path;
};

const renderColumns = (columns) => (
  <>
    {
      columns.map((c, index) => (
        <div className={ Classes.column } key={ index }>
          {
            c.map(w => (
              <Link
                to={ linkPath(w) }
                className={ Classes.similar }
                key={ w.id }
                title={ w.title }
                style={ { paddingBottom: `${ w.aspectRatio * 100 }%` } }
              >
                <img src={ w.imgUrl } alt={ w.title } />
                <Eye />
              </Link>
            ))
          }
        </div>
      ))
    }
  </>
);

const renderWork = (work) => (
  <div className={ Classes.work }>
    <Link to={ work.path } className={ Classes.image }>
      <img src={ work.imgUrl } alt={ work.title } />
    </Link>
    <div className={ Classes.info }>
      <div className={ Classes.left }>
        <Link to={ work.path } className={ Classes.title }>
          <span>
            { work.title }
          </span>
        </Link>
        <div className={ Classes.author }>
          <span>
            By&nbsp;
            <Link to={ `/artists/${ work.authorId }/${ work.authorSlug }` }>
              { work.authorName }
            </Link>
          </span>
        </div>
      </div>
      <div className={ Classes.share }>
        <Favorite id={ work.id } type="Work" className={ Classes.favorite } />
      </div>
    </div>
  </div>
);

const render1Group = (work, columns, className) => (
  <div className={ classnames(Classes.centerColumn, Classes[className]) }>
    { work ? renderWork(work) : null }
    <div className={ Classes[className] }>
      { renderColumns(columns) }
    </div>
  </div>
);

const render3Groups = (work, groupsAndColumns, className) => (
  <div className={ Classes.grid }>
    <div className={ Classes[className] }>
      { renderColumns(groupsAndColumns[0]) }
    </div>
    {
      render1Group(work, groupsAndColumns[1], className)
    }
    <div className={ Classes[className] }>
      { renderColumns(groupsAndColumns[2]) }
    </div>
  </div>
);

const SimilarGrid = ({ work, items }) => {
  const { width } = useWindowSize();

  const { columnsCount, className, groupsCount } = useMemo(() => columnsPerWidth(width), [width]);

  const groupsAndColumns = useMemo(() => {
    const res = new Array(columnsCount * groupsCount);
    for (let i = 0; i < res.length; i += 1) { res[i] = []; }
    let columnIndex = 0;
    let index = 0;
    while (index < items.length) {
      const w = items[index];
      // delay middle group columns until first column has at least 5 items
      if (!res[columnIndex]) { res[columnIndex] = []; }

      if (work) {
        if (groupsCount > 1 && Math.floor(columnIndex / columnsCount) === 1) {
          if (res[0].length < 5) {
            // start of 3rd column
            columnIndex = columnsCount * 2;
          }
        }
      }

      if (!res[columnIndex]) { res[columnIndex] = []; }

      res[columnIndex].push(w);
      columnIndex += 1;
      if (columnIndex === res.length) {
        columnIndex = 0;
      }
      index += 1;
    }
    const groups = [];
    for (let g = 0; g < groupsCount; g += 1) {
      const group = [];
      for (let c = 0; c < columnsCount; c += 1) {
        columnIndex = g * columnsCount + c;
        group.push(res[columnIndex]);
      }
      groups.push(group);
    }
    return groups;
  }, [items, columnsCount, groupsCount]);

  // groups === 1 - work and the first group under it
  // groups === 3 - g1, work and g2 under it, g3

  if (groupsCount === 3) {
    return render3Groups(work, groupsAndColumns, className);
  }
  return render1Group(work, groupsAndColumns[0], className);
};

SimilarGrid.propTypes = {
  work: PropTypes.object,
  items: PropTypes.array
};

export default SimilarGrid;
