import { connect } from 'react-redux';
import SelectionDetails from './selection_details';

const mapStateToProps = ({ selection, work }) => {
  const { landscape } = ((work || {}).data || {}) || {};
  return {
    ...selection,
    hasSelection: !!selection.frame,
    landscape
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectionDetails);
