import React from 'react';

const CopyLink = (props) => (
  <svg
    viewBox="0 0 31 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 0A3.5 3.5 0 0 0 0 3.5v19.476a3.5 3.5 0 0 0 3.5 3.5h6.1v-3H3.5a.5.5 0 0 1-.5-.5V3.5a.5.5 0 0 1 .5-.5h14.9a.5.5 0 0 1 .5.5v4.905h3V3.5A3.5 3.5 0 0 0 18.4 0H3.5Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10.405a3.5 3.5 0 0 1 3.5-3.5h14.9a3.5 3.5 0 0 1 3.5 3.5V28.5a3.5 3.5 0 0 1-3.5 3.5H12.5A3.5 3.5 0 0 1 9 28.5V10.405Zm3.5-.5a.5.5 0 0 0-.5.5V28.5a.5.5 0 0 0 .5.5h14.9a.5.5 0 0 0 .5-.5V10.405a.5.5 0 0 0-.5-.5H12.5Z"
      fill="#fff"
    />
  </svg>
);

export default CopyLink;
