import React from 'react';
import PropTypes from 'prop-types';

import Works from '../works';
import Header from './header';
import CategoryDescription from './category_description';

const CategoryStyle = ({
  headerTitle,
  desktopImageHref,
  mobileImageHref,
  color,
  description,
  loadableDescription,
  ...rest
}) => {
  const hasTitle = !!headerTitle;
  const theHeader = hasTitle ? (
    <Header
      title={ headerTitle }
      desktopImageHref={ desktopImageHref }
      mobileImageHref={ mobileImageHref }
      color={ color }
      kind="prints"
    />
  ) : null;

  return (
    <>
      { theHeader }
      <Works { ...rest } />
      <CategoryDescription description={ description } loadadble={ loadableDescription } />
    </>
  );
};

CategoryStyle.propTypes = {
  headerTitle: PropTypes.string,
  desktopImageHref: PropTypes.string,
  mobileImageHref: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  loadableDescription: PropTypes.bool
};

export default CategoryStyle;
