import React, { useEffect } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Carousel from './carousel';
import Filter from '../works/filter';
import BreadCrumbsGeneric from '../bread_crumbs';
import GridSelection, { PAGE, THUMB } from '../walls/grid_selection';
import { clearFilters } from '../walls';
import { BREADCRUMBS_LINKS, VALID_QUERY_PARAMS } from '../walls/shared';
import GTMPageView from '../Application/data_layer';

import AppClasses from '../Application/styles';
import Classes from '../walls/styles';
import { trackEvent } from '../../config/analytics';
import { humanizeParams } from '../works/works';

const WallPage = ({
  walls,
  loading,
  hasMore,
  fetchWalls,
  resetWalls,
  wallFilters,
  page,
  history,
  id,
  addWallToCart,
  addWallPicturesToCart
}) => {
  const activeFilters = clearFilters(queryString.parse(window.location.search));

  const currentIndex = walls.findIndex(w => w.id === id);

  const canFetch = () => !loading && hasMore;

  const loadNextPage = () => {
    if (!canFetch()) { return; }
    fetchWalls(page + 1, clearFilters(queryString.parse(window.location.search)));
  };

  const renderBreadCrumbs = () => {
    const bc = [...BREADCRUMBS_LINKS];
    const { name, href } = walls[currentIndex];
    bc.push({ to: href, text: name });
    return bc;
  };

  const resetWallsFilter = () => {
    resetWalls();
    setTimeout(loadNextPage, 0);
  };

  useEffect(() => {
    if (!loading && walls.length && wallFilters) {
      const humanParams = humanizeParams(activeFilters, wallFilters);
      const wall = walls.find(w => w.id === Number(id));
      const { id: wallId, name } = wall || {};
      const eventProps = {
        ...humanParams,
        as: 'page',
        'walls count': walls.length,
        'wall id': wallId,
        'wall name': name
      };
      trackEvent('walls center', eventProps);
    }
  }, [loading, walls.length, wallFilters, id]);

  return (
    <div className="wall-page">
      <GTMPageView pageType="category" section="gallery walls" />
      <Filter
        path={ `/walls?as=${ THUMB }` }
        label="Search walls by"
        history={ history }
        { ...activeFilters }
        filters={ VALID_QUERY_PARAMS }
        artFilters={ wallFilters }
        onChange={ resetWallsFilter }
      >
        <div className={ Classes.bcrumbs }>
          <BreadCrumbsGeneric className={ Classes.wallBc } links={ renderBreadCrumbs() }>
            <GridSelection path="/walls" grid={ PAGE } params={ activeFilters } />
          </BreadCrumbsGeneric>
        </div>
      </Filter>
      <div className={ AppClasses.container20 }>
        <Carousel
          items={ walls }
          onLoad={ loadNextPage }
          loading={ loading }
          currentIndex={ currentIndex }
          params={ activeFilters }
          history={ history }
          addWallToCart={ addWallToCart }
          addWallPicturesToCart={ addWallPicturesToCart }
        />
      </div>
    </div>
  );
};

WallPage.propTypes = {
  id: PropTypes.number.isRequired,
  fetchWalls: PropTypes.func.isRequired,
  resetWalls: PropTypes.func.isRequired,
  fetchWallFilters: PropTypes.func.isRequired,
  // walls
  loading: PropTypes.bool.isRequired,
  walls: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  // filters
  wallFilters: PropTypes.arrayOf(PropTypes.any),
  history: ReactRouterPropTypes.history.isRequired,
  addWallToCart: PropTypes.func.isRequired,
  addWallPicturesToCart: PropTypes.func.isRequired
};

WallPage.defaultProps = {
  wallFilters: null
};

export default WallPage;
