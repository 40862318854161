import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import ScaleIcon from './scale_icon';
import ScaleModal from './scale_modal';

const ShowToScale = ({
  smallPreviewUrl,
  previewWidth2,
  previewHeight2
}) => {
  const [visible, setVisible] = useState(null);
  const hide = () => setVisible(false);
  const show = () => setVisible(true);

  const modal = useMemo(() => (
    visible ? (
      <ScaleModal
        img={ smallPreviewUrl }
        previewWidth2={ previewWidth2 }
        previewHeight2={ previewHeight2 }
        onHide={ hide }
      />
    ) : null
  ), [visible]);

  return useMemo(() => {
    if (!smallPreviewUrl || !previewWidth2) {
      return null;
    }

    return (
      <>
        { modal }
        <button onClick={ show } type="button" title="Show To Scale">
          <ScaleIcon />
          <span>Show To Scale</span>
        </button>
      </>
    );
  }, [smallPreviewUrl, previewWidth2, visible]);
};

ShowToScale.propTypes = {
  smallPreviewUrl: PropTypes.string,
  previewWidth2: PropTypes.number,
  previewHeight2: PropTypes.number
};

export default ShowToScale;
