import React from 'react';
import PropTypes from 'prop-types';
import Classes from './styles';

const Container = React.forwardRef(({ children }, ref) => (
  <div className={ Classes.grid } ref={ ref }>
    <div className={ Classes.title }>
      <h1>My Designed Walls</h1>
      <a href={ `${ window.currentLocale.basename }/stylist/walls/new` } className={ Classes.create } rel="noopener noreferrer nofollow">
        CREATE A NEW WALL
      </a>
    </div>
    <ul>
      <li className={ Classes.header }>
        <div>Preview</div>
        <div className={ Classes.name }>Name</div>
        <div>&nbsp;</div>
      </li>
      { children }
    </ul>
  </div>
));

Container.propTypes = {
  children: PropTypes.node.isRequired
};

export default Container;
