import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({});

const originalArtReducer = createReducer(getDefaultState(), {
  [Constants.OriginalArt.FETCH]: (state, { id }) => ({
    ...state,
    [id]: {
      loading: true,
      id
    }
  }),
  [Constants.OriginalArt.FETCH_SUCCESS]: (state, { originalArt }) => ({
    ...state,
    [originalArt.id]: originalArt
  })

});

export default originalArtReducer;
