import { connect } from 'react-redux';
import {
  fetchArtist as loadMoreWorks
} from 'arts/actions/artist';
import MoreArtistWorks from './more_artist_works';

const mapStateToProps = ({ artist }) => ({
  artist
});

const mapDispatchToProps = (dispatch) => ({
  loadMoreWorks: (id) => { dispatch(loadMoreWorks(id)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(MoreArtistWorks);
