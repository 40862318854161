import Constants from 'arts/config/constants';
import { addToStoreItemsApi, removeFromStoreItemsApi } from 'arts/config/api';

export const updateUI = (totalItems) => {
  document.querySelector('.my-store-status strong').innerHTML = totalItems;
};

export const add = (opts) => (dispatch) => {
  addToStoreItemsApi(opts)
    .then(
      (data) => {
        if (!data) { return; }
        const { id, storableId, totalItems } = data;
        dispatch({
          storeItemId: id,
          wallId: storableId,
          type: Constants.WallStoreItems.ADDED
        });
        updateUI(totalItems);
      }
    ).catch(() => { });
};

export const remove = (wallId, storeItemId) => (dispatch) => {
  removeFromStoreItemsApi(storeItemId)
    .then(
      (data) => {
        if (!data) { return; }
        const { totalItems } = data;
        dispatch({
          wallId,
          type: Constants.WallStoreItems.REMOVED
        });
        updateUI(totalItems);
      }
    ).catch(() => { });
};
