import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Classes from './styles';
import Wall from './wall';
import Artist from './artist';
import Work from './work';

const Collection = ({
  items,
  remove,
  name,
  className
}) => {
  if (!Object.keys(items).length) {
    return null;
  }

  let Klass = null;
  let collClass = null;
  if (name === 'Walls') {
    Klass = Wall;
    collClass = Classes.walls;
  } else if (name === 'Art') {
    Klass = Work;
  } else if (name === 'Artists') {
    Klass = Artist;
  }

  return (
    <div className={ classnames(Classes.collection, collClass) }>
      <h2>
        <span>{ name }</span>
      </h2>
      <ul className={ className }>
        {
          Object.keys(items).map((id) => (
            <Klass
              { ...items[id] }
              key={ `${ id }-${ items[id].type }` }
              remove={ remove }
            />
          ))
        }
      </ul>
    </div>
  );
};

Collection.propTypes = {
  remove: PropTypes.func.isRequired,
  items: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

Collection.defultProps = {
  className: null
};

export default Collection;
