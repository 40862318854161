import React from 'react';
import { shim } from 'object.hasown';
import { createRoot, hydrateRoot } from 'react-dom/client';
import Arts, { store } from 'arts';
import Footer from 'arts/components/footer';
import About from 'arts/components/about';
import HpPromoV2 from 'arts/components/hp_promo/hp_promo_v2';
import TopArtists from 'arts/components/top_artists';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { setupAnalytics } from 'arts/config/analytics';

document.addEventListener('DOMContentLoaded', () => {
  if (window.sentry_dsn) {
    Sentry.init({
      dsn: window.sentry_dsn,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0,
      ignoreErrors: [
        'Failed to fetch',
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:2',
        'Can\'t find variable: instantSearchSDKJSBridgeClearHighlight'
      ],
      ignoreUrls: [
        /cdn1\.affirm\.com/i,
        /www\.paypal\.com/i,
        /^chrome-extension:\/\//i,
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /webappstoolbarba\.texthelp\.com\//i,
        /affirm\.com/i,
        /www\.googletagmanager\.com/i
      ],
      beforeSend: (event, hint) => {
        const error = hint.originalException;
        if (error && error.stack && error.stack.toString().includes('puppeteer')) {
          return null;
        }
        return event;
      }
    });
    window.Sentry = Sentry;
  }

  if (!Object.hasOwn) {
    shim();
  }

  setupAnalytics();

  const hproot = document.getElementById('b-topwide');
  if (hproot) {
    const root = createRoot(hproot);
    root.render(<HpPromoV2 config={ JSON.parse(hproot.dataset.config) } />);
  }

  const rootElement = document.getElementById('client-application-root');
  if (rootElement) {
    if (rootElement.innerHTML.trim()) {
      window.hydrated = true;
      hydrateRoot(rootElement, <Arts store={ store } />);
    } else {
      const root = createRoot(rootElement);
      root.render(<Arts store={ store } />);
    }
  }

  const footerEl = document.getElementById('footer');
  if (footerEl) {
    const root = createRoot(footerEl);
    root.render(<Footer store={ store } />);
  }

  const aboutB = document.getElementById('about-b');
  if (aboutB && !window.skipAbout) {
    const root = createRoot(aboutB);
    root.render(<About store={ store } />);
  }

  const topArtist = document.getElementById('top-artists');
  if (topArtist) {
    const root = createRoot(topArtist);
    root.render(<TopArtists />);
  }
});
