import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import PinIt from 'arts/components/Social/pin_it';
import Favorite from '../favorite';
import { WallStoreItem } from '../store_item';
import { onWallClick } from '../../config/analytics';

import AppClasses from '../Application/styles';
import Classes from '../walls/styles';
import ZoomModal from '../Viewer/zoom_modal';

const Wall = ({
  id, name, description, imageUrl, href, allowStores, storeItemId,
  wallPictures, discountPercent, position
}) => {
  const [displayPreview, setDisplayPreview] = useState(false);
  const showPreview = () => setDisplayPreview(true);
  const hidePreview = () => setDisplayPreview(false);

  const preview = displayPreview ? (
    <ZoomModal img={ imageUrl } onHide={ hidePreview } />
  ) : null;

  const storeOpts = allowStores ? (
    <WallStoreItem
      storeItemId={ storeItemId }
      wallId={ id }
    />
  ) : null;

  const onClick = () => onWallClick({
    id, name, wallPictures, discountPercent
  }, position);

  return (
    <li id={ `walls-grid-${ id }` }>
      { preview }
      <div className={ Classes.sharing }>
        <PinIt
          href={ href }
          description={ description }
          imageUrl={ imageUrl }
        />
        <Favorite id={ id } type="Wall" className={ Classes.favorite } />
        <button type="button" onClick={ showPreview } className={ Classes.mobileHidden } aria-label="Full Screen">
          <i className="icon-fullscreen" />
        </button>
        { storeOpts }
      </div>
      <Link to={ href } className={ Classes.wall } onClick={ onClick }>
        <img src={ imageUrl } alt={ name } />
        <h3>{ name }</h3>
        <p style={ { '-webkit-box-orient': 'vertical' } }>
          { description }
        </p>
      </Link>
      <div className={ Classes.shop }>
        <Link to={ href } className={ AppClasses.actionButton } onClick={ onClick }>
          SHOP THIS WALL
        </Link>
      </div>
    </li>
  );
};

Wall.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  allowStores: PropTypes.bool,
  storeItemId: PropTypes.number,
  discountPercent: PropTypes.number.isRequired,
  wallPictures: PropTypes.array.isRequired,
  position: PropTypes.number.isRequired
};

Wall.defaultProps = {
  allowStores: false,
  storeItemId: null
};

export default Wall;
