import React from 'react';
import T from 'prop-types';
import Classes from './styles';

const Delimiter = ({ notop }) => (
  <div className={ notop ? Classes.notop : Classes.root } />
);

Delimiter.propTypes = {
  notop: T.bool
};

Delimiter.defaultProps = {
  notop: false
};

Delimiter.defautProps = {
  notop: false
};

export default Delimiter;
