import Constants from 'arts/config/constants';
import { loadArtistsApi, loadArtistFiltersApi } from 'arts/config/api';

export const resetArtists = () => ({
  type: Constants.Artists.RESET
});

export const fetchArtists = (page, params) => (dispatch) => {
  dispatch({
    type: Constants.Artists.FETCH
  });

  loadArtistsApi(page, params)
    .then((artists) => dispatch({
      type: Constants.Artists.FETCH_SUCCESS,
      artists,
      page,
      params
    }));
};

export const fetchArtistFilters = () => (dispatch) => {
  loadArtistFiltersApi()
    .then(
      (filters) => dispatch({
        type: Constants.ArtistFilters.FETCH_SUCCESS,
        filters
      })
    ).catch(() => {});
};
