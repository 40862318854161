import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  loading: false,
  items: []
});

const similarWorks = createReducer(getDefaultState(), {
  [Constants.SimilarWorks.FETCH](state) {
    return {
      ...state,
      loading: true,
      items: []
    };
  },

  [Constants.SimilarWorks.FETCH_SUCCESS](state, { items }) {
    return {
      ...state,
      loading: false,
      items
    };
  }
});

export default similarWorks;
