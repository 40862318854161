import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import numeral from 'arts/config/numeral';
import CartItem from '../cart_items/cart_item';
import CheckoutStyles from '../checkout/styles';
import Classes from '../cart_items/styles';
import { FORMAT } from '../checkout_summary/checkout_summary';

const renderItem = (label, value) => (
  <div className={ classnames(Classes.item, Classes.classic, Classes.noBorder) }>
    <div className={ Classes.image }>
      { label }
    </div>
    <div className={ Classes.info }>
      { value }
    </div>
  </div>
);

const OrderDetails = ({
  userStatus, cartItems, locale, id, total, tax, shipping, merchandise, giftCards, shippingPackages
}) => {
  if (locale) { numeral.locale(locale); }
  let tracking = null;
  if (shippingPackages) {
    if (shippingPackages.length === 1) {
      tracking = renderItem('', (
        <a href={ shippingPackages[0] } target="_blank" rel="noopener noreferrer">Track delivery</a>
      ));
    } else if (shippingPackages.length > 1) {
      tracking = (
        <div className={ classnames(Classes.item, Classes.classic, Classes.noBorder) }>
          <div className={ Classes.image }>
            Track delivery
          </div>
          <div className={ Classes.info }>
            {
              shippingPackages.map((href, index) => (
                <div key={ index }>
                  <a href={ href } target="_blank" rel="noopener noreferrer">
                    Package #
                    { index + 1}
                  </a>
                </div>
              ))
            }
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <h1>
        Order Status /&nbsp;
        { userStatus }
      </h1>
      <div>
        { renderItem('Order Number', `AW${ id }`) }
        { renderItem('Order Status', userStatus) }
        { tracking }
        { renderItem('Merchandise', numeral(merchandise).format(FORMAT)) }
        { tax ? renderItem('Tax', numeral(tax).format(FORMAT)) : null }
        { renderItem('Shipping', numeral(shipping).format(FORMAT)) }
        { giftCards ? renderItem('Gift Card', numeral(giftCards).format(FORMAT)) : null }
        { renderItem('Total', numeral(total).format(FORMAT)) }
        <div className={ CheckoutStyles.section }>
          <h2 className={ classnames(Classes.item, Classes.classic) }>
            <div className={ Classes.image }>
              ORDER ITEMS
            </div>
          </h2>
          <div>
            {
              cartItems.map(
                (c) => (
                  <CartItem
                    { ...c }
                    key={ c.id }
                    editable={ false }
                    removeCartItem={ null }
                    locale={ locale }
                  />
                )
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

OrderDetails.propTypes = {
  locale: PropTypes.string.isRequired,
  userStatus: PropTypes.string.isRequired,
  cartItems: PropTypes.array.isRequired,
  tax: PropTypes.number,
  giftCards: PropTypes.number,
  shipping: PropTypes.number.isRequired,
  merchandise: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  shippingPackages: PropTypes.arrayOf(PropTypes.string)
};

export default OrderDetails;
