import React from 'react';
import T from 'prop-types';
import numeral from 'arts/config/numeral';
import Classes from './styles';
import { FORMAT } from '../checkout_summary/checkout_summary';

const ProductAddToCard = ({
  retailPrice, id, shippingPrice, addProductToCart
}) => {
  const doAddToCart = () => addProductToCart({ productId: id });

  const renderShipping = () => {
    if (shippingPrice === 0) {
      return null;
    }

    return (
      <div className={ Classes.shipping }>
        * Shipping per Trios:&nbsp;
        { numeral(shippingPrice).format(FORMAT) }
      </div>
    );
  };

  return (
    <div className={ Classes.bar }>
      <div>
        <div className={ Classes.total } key="price">
          Price per Trios:&nbps;
          { numeral(retailPrice).format(FORMAT) }
        </div>
        { renderShipping() }
      </div>
      <button type="button" className={ Classes.button } key="button" onClick={ doAddToCart }>
        add to cart
      </button>
    </div>
  );
};

ProductAddToCard.propTypes = {
  id: T.number,
  retailPrice: T.number,
  shippingPrice: T.number,
  addProductToCart: T.func.isRequired
};

ProductAddToCard.defaultProps = {
  id: null,
  retailPrice: null,
  shippingPrice: null
};

export default ProductAddToCard;
