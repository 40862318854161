/* eslint-disable react/no-children-prop */
import React from 'react';
import PropTypes from 'prop-types';
import Expandable from 'arts/components/expandable';
// TODO: make smart add to cart when products are back
import ProductAddToCard from 'arts/components/AddToCart/product_add_to_card';
import { sectionsShape, userShape } from 'arts/reducers/product';
import ReactMarkdown from 'react-markdown';

import Classes from './styles';

const renderUser = (user) => (
  <p className={ Classes.author }>
    by&nbsp;
    <a
      className={ Classes.name }
      href={ user.id ? `/artists/${ user.id }` : null }
    >
      { user.name }
    </a>
  </p>
);

const renderSection = ({ id, name, content }) => (
  <Expandable
    title={ name }
    eKey={ `e:k:product:section:${ id }` }
    key={ id }
  >
    <p className={ Classes.sectionContent }>
      { content }
    </p>
  </Expandable>
);

renderSection.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  content: PropTypes.string
};

const Details = ({
  title, user, description, sections, id, retailPrice, shippingPrice, addProductToCart
}) => (
  <div className={ Classes.info }>
    <p className={ Classes.title }>
      { title }
    </p>
    { renderUser(user) }
    <ReactMarkdown
      className={ Classes.description }
      escapeHtml
      children={ description }
    />
    <ProductAddToCard
      id={ id }
      retailPrice={ parseFloat(retailPrice) }
      shippingPrice={ parseFloat(shippingPrice) }
      addProductToCart={ addProductToCart }
    />
    { sections.map(renderSection) }
  </div>
);

Details.propTypes = {
  title: PropTypes.string,
  user: userShape,
  description: PropTypes.string,
  id: PropTypes.number,
  retailPrice: PropTypes.string,
  shippingPrice: PropTypes.string,
  sections: sectionsShape,
  addProductToCart: PropTypes.func.isRequired
};

export default Details;
