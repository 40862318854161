import React  from 'react';
import T from 'prop-types';
import Classes from './styles';

const NextArrow = (props) => (
  <div
    className={ Classes.nextButton }
    onClick={ props.onClick }
  />
);
NextArrow.propTypes = {
  onClick: T.func
};

const PrevArrow = (props) => (
  <div
    className={ Classes.prevButton }
    onClick={ props.onClick }
  />
);

PrevArrow.propTypes = {
  onClick: T.func
};

// eslint-disable-next-line react/prop-types
const renderPrevButton = ({ isDisabled }) => (isDisabled ? null : <PrevArrow />);

// eslint-disable-next-line react/prop-types
const renderNextButton = ({ isDisabled }) => (isDisabled ? null : <NextArrow />);

export const aliceSliderSettins = (useKeys = true) => ({
  disableDotsControls: true,
  disableSlideInfo: true,
  mouseTracking: true,
  autoWidth: true,
  renderPrevButton,
  renderNextButton,
  key: useKeys ? (new Date()).getTime() : null
});

export const handleDragStart = (e) => e.preventDefault();
