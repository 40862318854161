export const VALID_QUERY_PARAMS = [
  'newest',
  'with_home_decor_style_id',
  'with_room_id',
  'with_price',
  'query',
  'with_work_id'
];

export const BREADCRUMBS_LINKS = [{
  text: 'Gallery Walls',
  to: '/walls'
}];
