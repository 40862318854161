import { connect } from 'react-redux';
import { loadAlsoLike } from 'arts/actions/relatedWorks';
import MayAlsoLike from './may_also_like';

const mapStateToProps = ({ relatedWorks: { alsoLike } }) => ({
  data:    alsoLike.data,
  error:     alsoLike.error
});

const mapDispatchToProps = (dispatch) => ({
  loadAlsoLike: (id) => { dispatch(loadAlsoLike(id)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(MayAlsoLike);
