import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  collection: [],
  artistInfo: {}
});

const artistWorks = createReducer(getDefaultState(), {
  [Constants.ArtistInfo.FETCH](state, { id }) {
    return {
      ...state,
      error: null,
      artistInfo: { id }
    };
  },

  [Constants.ArtistInfo.FETCH_SUCCESS](state, { id, data }) {
    if (state.artistInfo.id === id) {
      return { ...state, artistInfo: { id, ...data } };
    }
    return state;
  },

  [Constants.ArtistWorks.FETCH](state, { id }) {
    if (id !== state.artistInfo.id) {
      return state;
    }
    return {
      ...state,
      collection: [],
      error: null
    };
  },

  [Constants.ArtistWorks.FETCH_SUCCESS](state, { id, data }) {
    if (id !== state.artistInfo.id) {
      return state;
    }
    return {
      ...state,
      collection: data,
      error: null
    };
  },

  [Constants.ArtistWorks.FETCH_FAILURE](state, { error, id }) {
    if (id !== state.artistInfo.id) {
      return state;
    }
    return {
      ...state,
      error: error.message,
      collection: []
    };
  }
});

export default artistWorks;
