import React from 'react';
import T from 'prop-types';
import Classes from './styles';

const Loader = ({ text, style }) => (
  <div className={ Classes.root } style={ style }>
    <i className="icon-spinner icon-spin" />
    <span>
      { text}
    </span>
  </div>
);

Loader.propTypes = {
  text: T.string,
  /* eslint-disable react/forbid-prop-types */
  style: T.any
  /* eslint-enable react/forbid-prop-types */
};

Loader.defaultProps = {
  text: null,
  style: null
};

export default Loader;
