import { connect } from 'react-redux';
import CategoryStyle from './category_style';
import { WITH_CATEGORY_ID } from '../works/works';

const ART_PRINTS = ' Art Prints';

export const dyanamicMapToProps = (filterKey) => (
  {
    artFilters: { filters }
  },
  {
    location: { pathname }
  }
) => {
  const entries = pathname.split(/\//);
  const currentId = entries[3];
  const index = (filters || []).findIndex(v => v.key === filterKey);

  if (index < 0) {
    return {};
  }

  const headers = filters[index].options.filter((v) => v.header).map(v => v.header);
  const header = headers.find(h => h.slug === currentId);
  if (header) {
    if (entries.length === 5 && filterKey === 'with_style_id') {
      // /art-prints/style/impressionist/botanicals
      const cIndex = (filters || []).findIndex(v => v.key === 'with_category_id');
      const cHeaders = filters[cIndex].options.filter((v) => v.header).map(v => v.header);
      const cHeader = cHeaders.find(h => h.slug === entries[4]);
      if (cHeader) {
        const { headerTitle: cHeaderTitle } = cHeader;
        if (cHeaderTitle) {
          const injectTitle = cHeaderTitle.replace(ART_PRINTS, '');
          const { headerTitle, ...restOfHeader } = header;
          const newTitle = `${ headerTitle.replace(ART_PRINTS, '') } ${ injectTitle }${ ART_PRINTS }`;
          return {
            ...restOfHeader,
            headerTitle: newTitle
          };
        }
      }
    }
    // /art-prints/style/impressionist
    return header;
  }

  let cName = null;
  const mainCategory = filters[index].options.find(
    v => v.slug === currentId || v.id === currentId
  );

  let loadableDescription = false;

  if (mainCategory) {
    cName = mainCategory.name;
    if (filterKey !== 'with_price') {
      cName = `${ cName } Prints`;
    }
    loadableDescription = mainCategory.loadableDescription;
  }

  const { defaultHeader } = filters[index];
  if (defaultHeader) {
    return {
      ...defaultHeader,
      headerTitle: cName,
      loadableDescription
    };
  }

  const otherHeader = filters.find(f => f.key === WITH_CATEGORY_ID)
    .options.find(v => v.allPrints).header;

  if (filterKey === 'by_color') {
    if (entries.length === 3 && filters[index].mapping[entries[2]]) {
      cName = `${ entries[2].toUpperCase() } Art Prints`;
    } else {
      cName = ART_PRINTS;
    }
  }

  if (otherHeader) {
    return {
      ...otherHeader,
      headerTitle: cName
    };
  }

  return {};
};

export const mapHiddenCategory = (key) => ({ artFilters: { filters } }) => {
  if (!(filters || []).length) { return {}; }
  const defaultHeader = filters
    .find(f => f.key === WITH_CATEGORY_ID).options.find(v => v[key]).header;
  return defaultHeader;
};

const mapStateToProps = (state, props) => dyanamicMapToProps(WITH_CATEGORY_ID)(state, props);

export default connect(mapStateToProps, {})(CategoryStyle);
