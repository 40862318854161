import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useInView } from 'react-intersection-observer';
import { renderPicture } from '../hp_promo/hp_promo_v2';
import Classes from './styles';
import Bag from '../../assets/bag';
import { trackEvent } from '../../config/analytics';

const Look = ({
  id, kind, img, name, onClicked
}) => {
  const onClick = (e) => {
    e.preventDefault();
    onClicked(id);
    trackEvent('shop this look', { 'look id': id, kind, name });
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-200px 0px'
  });

  const newKind = kind === 'wide' ? kind : 'narrow';

  return (
    <div
      className={
        classnames(
          Classes.look,
          Classes[newKind],
          { [Classes.inView]: inView }
        )
      }
      onClick={ onClick }
      ref={ ref }
    >
      <div className={ Classes.lookImage }>
        { renderPicture(img, name) }
        <div className={ Classes.shop }>
          <Bag style={ { stroke: '#000' } } />
          <span>
            Shop This Look
          </span>
        </div>
      </div>
      <div className={ Classes.credentials }>
        { name }
        &nbsp;
      </div>
    </div>
  );
};

Look.propTypes = {
  id: PropTypes.number.isRequired,
  img: PropTypes.object.isRequired,
  kind: PropTypes.string.isRequired,
  name: PropTypes.string,
  onClicked: PropTypes.func.isRequired
};

export default Look;
