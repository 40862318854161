import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  frame: null,
  paper: null,
  otherImageUrl: null,
  nonGlare: false
});

const selection = createReducer(getDefaultState(), {
  [Constants.Selection.RESET_OTHER](state) {
    return { ...state, otherImageUrl: null };
  },
  [Constants.Selection.SET_OTHER](state, { url }) {
    return {
      ...state,
      otherImageUrl: url
    };
  },
  [Constants.Selection.SET_NON_GLARE](state, { nonGlare }) {
    return {
      ...state,
      nonGlare
    };
  },
  [Constants.Selection.SET_FRAME](state, { paper, frame }) {
    return {
      ...state,
      paper,
      frame
    };
  },
  [Constants.Selection.RESET]() {
    return getDefaultState();
  }
});

export default selection;
export * from './proptypes';
