import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import { uniqCollection } from './utils';

const getDefaultState = () => ({
  loading: false,
  artists: [],
  hasMore: true,
  page: -1,
  params: {}
});

const artistsReducer = createReducer(getDefaultState(), {
  [Constants.Artists.RESET]() {
    return getDefaultState();
  },
  [Constants.Artists.FETCH](state) {
    return {
      ...state,
      loading: true
    };
  },

  [Constants.Artists.FETCH_SUCCESS](state, { artists, page, params }) {
    return {
      ...state,
      loading: false,
      artists: uniqCollection(state.artists, artists),
      hasMore: artists.length > 0,
      page,
      params
    };
  }
});

export default artistsReducer;
