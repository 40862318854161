import { connect } from 'react-redux';
import { loadCollectionDetails } from 'arts/actions/collection_details';
import CollectionDetails from './collection_details';

const mapStateToProps = ({ collectionDetails }, { match: { params: { slug } } }) => ({
  ...(collectionDetails || {})[slug],
  slug
});

export default connect(mapStateToProps, { loadCollectionDetails })(CollectionDetails);
