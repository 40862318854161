import { connect } from 'react-redux';
import CheckoutSummary from './checkout_summary';

const mapStateToProps = ({ cartItems }) => {
  const {
    summaryOfCharges, loading, collection, locale
  } = cartItems || {};
  return {
    loading,
    ...(summaryOfCharges || {}),
    allAvailable: !collection.find((c) => !c.isAvailable),
    locale
  };
};

export default connect(mapStateToProps)(CheckoutSummary);
