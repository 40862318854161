import React from 'react';

const SvgComponent = (props) => (
  <svg
    width={ 24 }
    height={ 24 }
    viewBox="0 0 24 24"
    strokeWidth={ 1 }
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    { ...props }
  >
    <path d="M0 0h24v24H0z" stroke="none" />
    <path d="M15 6l-6 6 6 6" />
  </svg>
);

export default SvgComponent;
