import Constants from 'arts/config/constants';
import {
  loadOriginalArtsApi, loadOriginalArtApi, loadAuthorOriginalArtApi, loadOriginalFiltersApi
} from 'arts/config/api';
import {
  toCamelCase
} from 'arts/config/convertCase';

export const resetOriginalArts = () => ({
  type: Constants.OrginalArts.RESET
});

export const fetchOriginalArts = (page, params) => (dispatch) => {
  dispatch({
    type: Constants.OrginalArts.FETCH
  });

  loadOriginalArtsApi(page, params).then(collection => {
    dispatch({
      type: Constants.OrginalArts.FETCH_SUCCESS,
      collection,
      params
    });
  }).catch(() => {});
};

export const fetchOriginalArtsFilters = () => (dispatch) => {
  loadOriginalFiltersApi()
    .then(
      (filters) => dispatch({
        type: Constants.OriginalArtFilters.FETCH_SUCCESS,
        filters
      })
    ).catch(() => {});
};

export const fetchOriginalAuthorArt = (id) => (dispatch, getState) => {
  const { authorOriginalArt } = getState();
  if (authorOriginalArt[id]) {
    return;
  }

  dispatch({
    type: Constants.AuthorOriginalArt.FETCH,
    id
  });

  loadAuthorOriginalArtApi(id).then(userOriginalArt => {
    dispatch({
      type: Constants.AuthorOriginalArt.FETCH_SUCCESS,
      id,
      userOriginalArt
    });
  }).catch(() => {});
};

export const fetchOriginalArt = (id) => (dispatch, getState) => {
  const oaId = parseInt(id, 10);
  const { originalArt, originalArts } = getState();
  const { cachedOriginalArt } = window;

  if ((originalArt[oaId] || {}).userOriginalArts) {
    // loaded
    return;
  }

  if (cachedOriginalArt && cachedOriginalArt.id === oaId) {
    // cached
    const art = toCamelCase(cachedOriginalArt);
    dispatch({
      type: Constants.OriginalArt.FETCH_SUCCESS,
      originalArt: art
    });
    return;
  }

  const art = originalArts.collection.find(a => a.id === oaId);
  if (art) {
    // loaded as a part of a collection
    dispatch({
      type: Constants.OriginalArt.FETCH_SUCCESS,
      originalArt: art
    });
    // and continue loading userOriginalArts
  }

  loadOriginalArtApi(oaId).then(a => {
    dispatch({
      type: Constants.OriginalArt.FETCH_SUCCESS,
      originalArt: a
    });
  }).catch(() => {});
};
