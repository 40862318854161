import React, { useMemo } from 'react';
import queryString from 'query-string';
import { pathWithoutQuickShop } from '../WorkListItem/info';
import QuickShopModal from './index';

const QuickShop = () => {
  const { quick } = queryString.parse(window.location.search);
  return useMemo(() => (
    quick ? (
      <QuickShopModal id={ parseInt(quick, 10) } cancelUrl={ pathWithoutQuickShop() } />
    ) : null
  ), [quick]);
};

export default QuickShop;
