import React, { PureComponent } from 'react';
import classnames from 'classnames';
import T from 'prop-types';
import Collapsible from 'arts/components/Collapsible';
import Classes from './styles';

export default class Expandable extends PureComponent {
  static propTypes = {
    title: T.string.isRequired,
    children: T.node.isRequired,
    eKey: T.string.isRequired,
    open: T.bool.isRequired,
    expand: T.func.isRequired,
    className: T.string
  };

  toggle = (e) => {
    const { eKey, expand } = this.props;
    e.preventDefault();
    expand(eKey);
  }

  render() {
    const {
      className, open, title, children
    } = this.props;
    return (
      <div className={ classnames(Classes.expandable, className) }>
        <a href="#expand" onClick={ this.toggle }>
          <span className={ `${ Classes.triangle } ${ open ? Classes.open : '' }` } />
          <span>{ title }</span>
        </a>
        <Collapsible collapsed={ !open }>
          <div className={ Classes.content }>
            { children }
          </div>
        </Collapsible>
      </div>
    );
  }
}
