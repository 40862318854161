import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => null;

const applePaySession = createReducer(getDefaultState(), {
  [Constants.applePaySession.SUCCESS](_, { applePaySessionConfig }) {
    return applePaySessionConfig;
  }
});

export default applePaySession;
