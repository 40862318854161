import React from 'react';

const SvgComponent = (props) => (
  <svg viewBox="0 0 16 15" { ...props }>
    <g>
      <path className="prefix__st0" d="M0 0h16v2H0zM0 13h16v2H0z" />
      <g>
        <path
          d="M4.02 7.5c0-.62.1-1.17.29-1.65s.49-.94.89-1.36c.39-.39.81-.69 1.28-.89s.96-.3 1.5-.3 1.04.1 1.51.3.88.5 1.27.89c.41.41.71.86.9 1.35s.29 1.04.29 1.66-.1 1.17-.29 1.66-.49.94-.9 1.35c-.38.39-.8.69-1.27.88s-.96.29-1.51.29c-.54 0-1.04-.1-1.51-.29s-.88-.49-1.27-.88c-.4-.42-.7-.87-.89-1.35s-.29-1.04-.29-1.66zm2.44-.01c0 1.61.11 2.69.33 3.22s.61.8 1.18.8.97-.26 1.19-.79.33-1.6.33-3.22-.11-2.69-.33-3.21-.61-.79-1.18-.79c-.56 0-.96.27-1.18.8s-.34 1.6-.34 3.19z"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
