import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  filters: null
});

const artistFilters = createReducer(getDefaultState(), {
  [Constants.ArtistFilters.FETCH_SUCCESS](state, { filters }) {
    return { ...state, filters };
  }
});

export default artistFilters;
