import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import AppClasses from '../Application/styles';
import Classes from '../checkout/styles';
import classes from './classes';

const StripeForm = ({ clientSecret, redirectUrl, billingAddress }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const onReady = () => setIsReady(true);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          // setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: redirectUrl },
      payment_method_data: {
        billing_details: {
          address: billingAddress
        }
      }
    });

    if (error) {
      setMessage(`Payment failed: ${ error.message }`);
      setIsLoading(false);
    }
  };

  const submitProps = {
    className: classnames(AppClasses.button, Classes.button, classes.button),
    disabled: isLoading
  };

  const options = {
    layout: 'tabs',
    defaultValues: {
      billingDetails: billingAddress
    }
  };

  return (
    <form onSubmit={ handleSubmit }>
      <PaymentElement options={ options } onReady={ onReady } />
      {
        isReady ? (
          <>
            <p>&nbsp;</p>
            <button { ...submitProps } type="submit">
              { isLoading ? 'Processing, please wait...' : 'Place Order' }
            </button>
          </>
        ) : (
          <Loader active inline>
            Connecting...
          </Loader>
        )
      }
      { message && <p className={ classes.error }>{ message }</p> }
    </form>
  );
};

StripeForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  billingAddress: PropTypes.object
};

export default StripeForm;
