import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';
import { humanSizes } from 'wb/config/utils';
import Classes from './styles';
import { calculatePaperProps } from '../Viewer/utils';
import PaperView from '../Viewer/paper_view';
import Warning from '../PapersMenu/warning';

const WallPicture = ({
  wallPictureId, landscape, title, frameOption, workHref, authorHref, authorName, skipFrames,
  onToggleSkipped, prices, selected, onToggleSelected, isAvailable, index, onClick
}) => {
  const hasFrameCheck = !!frameOption.frameId;

  const toggleSkipped = () => onToggleSkipped(wallPictureId);

  const toggleSelected = () => {
    if (isAvailable) { onToggleSelected(wallPictureId); }
  };

  const framedPrint = hasFrameCheck ? (
    <Checkbox
      label="Framed print"
      checked={ !skipFrames[wallPictureId] }
      onChange={ toggleSkipped }
      className={ Classes.checkBox }
    />
  ) : null;

  const price = skipFrames[wallPictureId] ? prices.noFramesPrice : prices.price;

  if (!price) {
    return null;
  }

  const workTo = `${ workHref }?paper_id=${ frameOption.paperId }&frame_id=${ frameOption.frameId }`;

  const renderPaper = () => {
    const {
      paperHeight, paperWidth, printHeight, printWidth
    } = frameOption;
    const { width, borderH, borderW } = calculatePaperProps({
      paperHeight, paperWidth, printHeight, printWidth, landscape
    });
    const paperProps = {
      width, borderH, borderW, imageUrl: frameOption.noFramePreviewUrl, title
    };
    return <PaperView unframed { ...paperProps } />;
  };

  const noFrame = (skipFrames[wallPictureId] || !frameOption.frameId);

  const previewImage = noFrame ? renderPaper() : (
    <img
      src={ frameOption.zoomPreviewUrl }
      alt={ title }
      className={ classnames({ [Classes.unselected]: (!selected || !isAvailable) }) }
    />
  );

  const {
    paperMaterial,
    frameApiName,
    frameFinalFramedSize,
    warning,
    displayPaperWidth,
    displayPaperHeight,
    displayPrintWidth,
    displayPrintHeight
  } = frameOption;

  const hs = humanSizes(landscape, displayPrintWidth, displayPrintHeight);
  const phs = humanSizes(landscape, displayPaperWidth, displayPaperHeight);

  let finalSize = null;
  if (paperMaterial !== 'Canvas') {
    finalSize = noFrame ? (
      <>
        Paper Size:&nbsp;
        <strong>{ phs }</strong>
      </>
    ) : (
      <>
        Final Framed Size:&nbsp;
        <strong>{ frameFinalFramedSize }</strong>
      </>
    );
  }

  const sizes = (
    <div className={ Classes.sizes }>
      <div>
        Image Size:&nbsp;
        <strong>{ hs }</strong>
      </div>
      <div>
        { finalSize }
      </div>
      <div>
        Printed on&nbsp;
        { paperMaterial }
      </div>
      {
        noFrame ? null : (
          <div>
            { frameApiName }
          </div>
        )
      }
    </div>
  );

  const renderAuthor = authorHref ? (
    <div className={ Classes.author }>
      By&nbsp;
      <Link to={ authorHref }>
        { authorName }
      </Link>
    </div>
  ) : null;

  const selectItem = isAvailable ? (
    <Checkbox
      label="SELECT ITEM"
      checked={ selected && isAvailable }
      onChange={ toggleSelected }
      className={ Classes.checkBox }
    />
  ) : null;

  const onLinkClick = () => onClick(index);

  return (
    <li>
      <div className={ Classes.ic }>
        <Link to={ workTo } className={ Classes.work } onClick={ onLinkClick }>
          { previewImage }
        </Link>
      </div>
      <div className={ Classes.space } />
      <div className={ Classes.right }>
        <div className={ Classes.t }>
          <div className={ Classes.dc }>
            <Link to={ workTo } className={ Classes.work } onClick={ onLinkClick }>
              { title }
            </Link>
            { renderAuthor }
            <div className={ Classes.details }>
              { sizes }
            </div>
            { framedPrint }
          </div>
          <div className={ Classes.space } />
          <div className={ Classes.other }>
            <div className={ Classes.price }>
              { prices.currency }
              { price.toFixed(2) }
            </div>
            { selectItem }
          </div>
        </div>
        <Warning warning={ warning } flat />
      </div>
    </li>
  );
};

WallPicture.propTypes = {
  // we should keep the id for the parent original wall picture
  wallPictureId: PropTypes.number.isRequired,

  landscape: PropTypes.bool.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  frameOption: PropTypes.object.isRequired,
  workHref: PropTypes.string.isRequired,
  authorHref: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  prices: PropTypes.object.isRequired,

  skipFrames: PropTypes.object.isRequired,
  onToggleSkipped: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onToggleSelected: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default WallPicture;
