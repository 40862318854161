import React, { useEffect } from 'react';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import AppClasses from '../Application/styles';
import Section from './section';
import Classes from './styles';
import CartItems from '../cart_items';
import CheckoutSummary from '../checkout_summary';
import GTMPageView from '../Application/data_layer';
import GuestCheckout from './guest_checkout';

const go = (e, eventName) => {
  e.preventDefault();
  const event = new CustomEvent(eventName);
  document.dispatchEvent(event);
};

const signIn = (e) => go(e, 'SignIn');
const onAuth = () => window.location.reload();

const SignInToCheckout = ({ history }) => {
  useEffect(() => {
    window.after_auth = onAuth;
    return () => {
      window.after_auth = null;
    };
  }, []);

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="cart" section="cart" />
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        <h1>Checkout</h1>
      </div>
      <div className={ AppClasses.gridCheckout }>
        <div className={ AppClasses.left }>
          <Section title="&nbsp;">
            <div className={ classnames(Classes.content, Classes.have) }>
              <p className={ Classes.t2 }>Already Have an Account?</p>
              <button type="button" className={ classnames(Classes.button, AppClasses.button) } onClick={ signIn }>
                SIGN IN
              </button>
            </div>
            <div className={ classnames(Classes.content, Classes.nothave) }>
              <GuestCheckout />
            </div>
          </Section>
          <Section title="SHIPPING ADDRESS" />
          <Section title="BILLING ADDRESS" />
          <Section title="PLACE ORDER" />
        </div>
        <div className={ AppClasses.right }>
          <Section title="SUMMARY OF CHARGES">
            <div className={ Classes.content }>
              <CheckoutSummary history={ history } />
            </div>
          </Section>
          <Section title="SHOPPING CART" edit="/cart_items">
            <div className={ Classes.content }>
              <CartItems narrow checkoutStep={ 2 } />
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};

SignInToCheckout.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};

export default SignInToCheckout;
