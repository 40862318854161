import Constants from 'arts/config/constants';
import { loadWork } from 'arts/config/api';
import { setRecentWorkIds } from './relatedWorks';

const fetchWorkStart = (id, detail) => ({
  type: Constants.Work.FETCH,
  id,
  detail
});

const fetchWorkSuccess = (data) => ({
  type: Constants.Work.FETCH_SUCCESS,
  data
});

const fetchWorkFailure = (error) => ({
  type: Constants.Work.FETCH_FAILURE,
  error
});

export const fetchWork = (id, detail = {}) => (dispatch) => {
  dispatch(fetchWorkStart(id, detail));

  loadWork(id).then(
    (data) => {
      const { recentWorkIds, ...rest } = data;
      dispatch(fetchWorkSuccess(rest));
      if (recentWorkIds && recentWorkIds.length) {
        dispatch(setRecentWorkIds(recentWorkIds));
      }
    },
    (error) => dispatch(fetchWorkFailure(error))
  );
};
