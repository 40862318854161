import { connect } from 'react-redux';
import { add as addWork, remove as removeWork } from 'arts/actions/work_store_items';
import { add as addWall, remove as removeWall } from 'arts/actions/wall_store_items';
import StoreItem from './store_item';

export const WorkStoreItem = connect(null, {
  add: addWork,
  remove: removeWork
})(StoreItem);

export const WallStoreItem = connect(null, {
  add: addWall,
  remove: removeWall
})(StoreItem);
