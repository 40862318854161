import { connect } from 'react-redux';
import { fetchWall } from 'arts/actions/wall';
import { addWallToCart, addWallPicturesToCart } from 'arts/actions/cart_items';
import WallSingle from './wall_single';

const mapStateToProps = ({ wall }, { id, sharingToken }) => {
  const loadedWall = wall[id] || wall[sharingToken];
  if (loadedWall) {
    return { ...loadedWall, sharingToken };
  }
  return { id, sharingToken };
};

export default connect(mapStateToProps, {
  fetchWall, addWallToCart, addWallPicturesToCart
})(WallSingle);
