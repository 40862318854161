import { connect } from 'react-redux';
import { expand } from 'arts/actions/expand';
import Expandable from './expandable';

const mapStateToProps = ({ expandable }, { eKey }) => ({
  open: !!expandable[eKey]
});

const mapDispatchToProps = (dispatch, { eKey }) => ({
  expand: () => dispatch(expand(eKey))
});

export default connect(mapStateToProps, mapDispatchToProps)(Expandable);
