import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import AppClasses from '../Application/styles';
import Classes from '../checkout/styles';

const AffirmPlaceOrderButton = ({
  placeOrder, orderId, method
}) => {
  if (!method) {
    return null;
  }

  const place = () => {
    placeOrder(orderId, false);
  };

  return (
    <div className={ Classes.placeOrderH }>
      <button
        className={ classnames(AppClasses.button, Classes.button) }
        onClick={ place }
        type="button"
        disabled={ !method }
      >
        Continue to Financing
      </button>
    </div>
  );
};

AffirmPlaceOrderButton.propTypes = {
  placeOrder: PropTypes.func.isRequired,
  paypalOrderText: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  method: PropTypes.string
};

export default AffirmPlaceOrderButton;
