import React, { PureComponent } from 'react';
import T from 'prop-types';
import {
  thumbnailsShape
} from 'arts/reducers/work';
import Entry from './entry';

const otherImageChanged = (prevThumbnails, thumbnails, prevSelectedIndex, selectedIndex) => {
  if (
    prevThumbnails[prevSelectedIndex] && thumbnails[selectedIndex]
    && prevThumbnails[prevSelectedIndex].type === thumbnails[selectedIndex].type
    && (
      prevThumbnails[prevSelectedIndex].type === 'second'
      || prevThumbnails[prevSelectedIndex].type === 'frame'
    )
  ) {
    return true;
  }
  return false;
};

export default class Thumbnails extends PureComponent {
  static propTypes = {
    thumbnails: thumbnailsShape.isRequired,
    setFramePreview: T.func.isRequired,
    setOtherImage: T.func.isRequired
  };

  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.state = { selectedIndex: 0 };
  }

  componentDidUpdate(prevProps, prevState) {
    if (otherImageChanged(
      prevProps.thumbnails, this.props.thumbnails,
      prevState.selectedIndex, this.state.selectedIndex
    )
    ) {
      this.props.setOtherImage(this.props.thumbnails[this.state.selectedIndex].url);
    }
  }

  onSelect(selectedIndex) {
    this.setState({ selectedIndex });
    if (this.props.thumbnails[selectedIndex].selection) {
      const { paper, frame } = this.props.thumbnails[selectedIndex].selection;
      this.props.setFramePreview(paper, frame);
    } else {
      this.props.setOtherImage(this.props.thumbnails[selectedIndex].url);
    }
  }

  render() {
    if (this.props.thumbnails.length < 1) {
      return null;
    }

    return (
      <>
        {
          this.props.thumbnails.map((e, index) => (
            <Entry
              url={ e.url }
              index={ index }
              key={ e.type }
              selectedIndex={ this.state.selectedIndex }
              onClick={ this.onSelect }
            />
          ))
        }
      </>
    );
  }
}
