import Constants from 'arts/config/constants';
import { getProduct } from 'arts/config/api';

const fetchProductStart = (id) => ({
  type: Constants.Product.FETCH,
  id
});

const fetchProductSuccess = (data) => ({
  type: Constants.Product.FETCH_SUCCESS,
  data
});

const fetchProductFailure = (error) => ({
  type: Constants.Product.FETCH_FAILURE,
  error
});

export const fetchProduct = (id) => (dispatch, getState) => {
  dispatch(fetchProductStart(id));

  const product = getState().productsList.data.find(p => String(p.id) === String(id));

  if (product) {
    dispatch(fetchProductSuccess(product));
  } else {
    getProduct(id).then(
      (data) => {
        if (data.product.id === parseInt(id, 10)) {
          dispatch(fetchProductSuccess(data.product));
        }
      },
      (error) => dispatch(fetchProductFailure(error))
    );
  }
};
