import Constants from 'arts/config/constants';
import { getGiftCardBalanceApi } from 'arts/config/api';

const fetchGiftCardBalanceStart = (giftCardNumber) => ({
  type: Constants.GiftCardBalance.FETCH,
  giftCardNumber
});

const fetchGiftCardBalanceSuccess = (giftCardNumber, balance, locale) => ({
  type: Constants.GiftCardBalance.FETCH_SUCCESS,
  giftCardNumber,
  balance,
  locale
});

const fetchGiftCardBalanceFailure = (error) => ({
  type: Constants.GiftCardBalance.FETCH_FAILURE,
  error
});

export const fetchGiftCardBalance = (cardNumber, pinCode) => (dispatch) => {
  dispatch(fetchGiftCardBalanceStart(cardNumber));
  getGiftCardBalanceApi(cardNumber, pinCode)
    .then(
      (data) => dispatch(fetchGiftCardBalanceSuccess(cardNumber, data.balance, data.locale)),
      (error) => {
        if (error.status === 404 || error.status === 422) {
          dispatch(fetchGiftCardBalanceFailure(error.body.errors));
        } else {
          dispatch(fetchGiftCardBalanceFailure('Please try again later.'));
        }
      }
    ).catch(() => {});
};

export const clearGiftCardBalance = () => ({
  type: Constants.GiftCardBalance.CLEAR
});
