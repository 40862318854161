import React from 'react';
import PropTypes from 'prop-types';
import WallSingle from '../wall_single';
import WallPage from './wall_page';

const Wall = ({ id, single, ...rest }) => {
  if (id === 'style' || id === 'room') {
    return null;
  }
  if (single) {
    return <WallSingle id={ id } { ...rest } singleWall />;
  }
  const { walls, ...props } = rest;
  return <WallPage id={ id } { ...props } { ...walls } />;
};

Wall.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  single: PropTypes.bool.isRequired
};

Wall.defaultProps = {
  id: null
};

export default Wall;
