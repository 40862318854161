import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import SmartAbount from './smart_about';

const AboutApplication = ({ store }) => (
  <Provider store={ store }>
    <SmartAbount />
  </Provider>
);

AboutApplication.propTypes = {
  store: PropTypes.object.isRequired
};

export default AboutApplication;
