import { connect } from 'react-redux';
import { loadRecentlyView } from 'arts/actions/relatedWorks';
import RecentlyView from './recently_view';

const mapStateToProps = ({ relatedWorks: { recentlyView } }) => ({
  data:    recentlyView.data,
  error:   recentlyView.error,
  ids: recentlyView.ids
});

const mapDispatchToProps = (dispatch) => ({
  loadRecentlyView: (id) => { dispatch(loadRecentlyView(id)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentlyView);
