import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import { uniqCollection } from './utils';
import { onOriginalArtItems } from '../config/analytics';

const getDefaultState = () => ({
  loading: false,
  error: null,
  collection: [],
  hasMore: true
});

const originalArts = createReducer(getDefaultState(), {
  [Constants.OrginalArts.FETCH](state) {
    return {
      ...state,
      error: null,
      loading: true
    };
  },

  [Constants.OrginalArts.FETCH_SUCCESS](state, { collection, params }) {
    // we assume that fetched page has already been shown
    onOriginalArtItems(collection, 'category', 'original-art-center');
    return {
      ...state,
      loading: false,
      collection: uniqCollection(state.collection, collection),
      hasMore: collection.length > 0,
      params
    };
  },

  [Constants.OrginalArts.RESET]() {
    return getDefaultState();
  }
});

export default originalArts;
