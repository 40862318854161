import React from 'react';

const SvgComponent = (props) => (
  <svg
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path d="M2.38462 3.16774C2.38462 2.35484 2.61538 1 4 1C5.38462 1 5.61538 2.35484 5.61538 3.16774M1 9.4L1.46154 3.16774H6.53846L7 9.4H1Z" />
  </svg>
);

export default SvgComponent;
