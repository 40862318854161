import { connect } from 'react-redux';
import AddToCart from './smart_add_to_cart';
import { currentPriceAndNonGlare } from './add_to_cart';

const mapStateToProps = ({ selection, work }) => ({
  ...currentPriceAndNonGlare(selection, 'price'),
  inStock: work && work.data ? work.data.inStock : null,
  selection: !!selection.frame,
  frameId: selection && selection.frame && selection.frame.frameId,
  workId: work && work.data && work.data.id,
  paperId: selection && selection.paper && selection.paper.paperId,
  currency: work.data.currency
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
