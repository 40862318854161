import { connect } from 'react-redux';
import { fetchGiftCardBalance, clearGiftCardBalance } from 'arts/actions/gift_card_balance';
import GiftCardBalance from './balance';

const mapStateToProps = ({ giftCardBalance }) => {
  const {
    error,
    loading,
    balance,
    locale,
    loaded,
    giftCardNumber,
    showForm
  } = giftCardBalance;

  return {
    error,
    loading,
    balance,
    loaded,
    giftCardNumber,
    showForm,
    locale
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchGiftCardBalance: (giftCardNumber, pin) => {
    dispatch(fetchGiftCardBalance(giftCardNumber, pin));
  },
  clearGiftCardBalance: () => { dispatch(clearGiftCardBalance()); }
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardBalance);
