import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumbsGeneric from '../bread_crumbs';
import Classes from './styles';

const ART_PRINTS = { text: 'Art Prints', to: '/art-prints' };
const ORIGINAL_ART = { text: 'Original Art', to: '/original_arts' };

const BreadCrumbs = ({
  authorName, authorId, authorSlug, originalArt
}) => {
  const links = [
    originalArt ? ORIGINAL_ART : ART_PRINTS,
    { text: authorName, to : `/artists/${ authorId }/${ authorSlug }` }
  ];
  return (
    <BreadCrumbsGeneric links={ links } rootClassName={ Classes.bc } />
  );
};

BreadCrumbs.propTypes = {
  authorName: PropTypes.string,
  authorId: PropTypes.number,
  authorSlug: PropTypes.string,
  originalArt: PropTypes.bool
};

export default BreadCrumbs;
