import React from 'react';
import T from 'prop-types';
import PinIt from 'arts/components/Social/pin_it';
import Favorite from '../favorite';

import Classes from './styles';

const Likes = ({
  id,
  favType,
  href,
  imageUrl,
  description,
  children
}) => (
  <div
    className={ Classes.likes }
  >
    <div suppressHydrationWarning>
      <Favorite id={ id } type={ favType || 'Work' } className={ Classes.favorite } />
      <PinIt
        href={ href }
        description={ description }
        imageUrl={ imageUrl }
      />
    </div>
    { children }
  </div>
);

Likes.propTypes = {
  id: T.number.isRequired,
  favType: T.string,
  href: T.string.isRequired,
  imageUrl: T.string.isRequired,
  description: T.string.isRequired,
  children: T.node
};

Likes.defaultProps = {
  children: null
};

export default Likes;
