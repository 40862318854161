import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  alsoLike: {
    error: null,
    data: []
  },
  recentlyView: {
    error: null,
    data: []
  }
});

const relatedWorks = createReducer(getDefaultState(), {
  // ALSO LIKE
  [Constants.RelatedWorks.LOAD_ALSO_LIKE_START](state) {
    return {
      ...state,
      alsoLike: getDefaultState().alsoLike
    };
  },

  [Constants.RelatedWorks.LOAD_ALSO_LIKE_FAILURE](state, action) {
    return {
      ...state,
      alsoLike: {
        ...state.alsoLike,
        error: action.error
      }
    };
  },
  [Constants.RelatedWorks.LOAD_ALSO_LIKE_SUCCESS](state, action) {
    return {
      ...state,
      alsoLike: {
        ...state.alsoLike,
        data: action.data
      }
    };
  },
  // RECENTLY VIEW
  [Constants.RelatedWorks.LOAD_RECENTLY_VIEW_START](state) {
    return {
      ...state,
      recentlyView: {
        ...getDefaultState().recentlyView,
        ids: state.recentlyView.ids
      }
    };
  },

  [Constants.RelatedWorks.LOAD_RECENTLY_VIEW_FAILURE](state, action) {
    return {
      ...state,
      recentlyView: {
        ...state.recentlyView,
        error: action.error
      }
    };
  },
  [Constants.RelatedWorks.SET_RECENTLY_VIEWED](state, { ids }) {
    return {
      ...state,
      recentlyView: {
        ...state.recentlyView,
        ids
      }
    };
  },
  [Constants.RelatedWorks.LOAD_RECENTLY_VIEW_SUCCESS](state, action) {
    return {
      ...state,
      recentlyView: {
        ...state.recentlyView,
        data: action.data
      }
    };
  }
});

export default relatedWorks;
