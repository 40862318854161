import React from 'react';
import PropTypes from 'prop-types';
import Classes from '../walls/styles';

const NoResults = ({ loading, walls }) => {
  const noResults = !loading && walls.length === 0;
  return noResults ? (
    <div className={ Classes.noResults }>
      There are no results that match your selection - please try again.
    </div>
  ) : null;
};

NoResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  walls: PropTypes.array.isRequired
};

export default NoResults;
