import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OriginalArtSection from '../original_art/original_art_section';
import Classes from '../original_art/styles';

const ArtistOriginalArts = ({
  id, userOriginalArt, fetchOriginalAuthorArt
}) => {
  useEffect(() => {
    fetchOriginalAuthorArt(id);
  }, []);

  if (!userOriginalArt || !userOriginalArt.length) {
    return null;
  }
  return (
    <div className={ Classes.author }>
      <OriginalArtSection
        collection={ userOriginalArt }
        getListName="artist-original-art"
        gtmPageType="category"
      />
    </div>
  );
};

ArtistOriginalArts.propTypes = {
  id: PropTypes.number.isRequired,
  fetchOriginalAuthorArt: PropTypes.func.isRequired,
  userOriginalArt: PropTypes.array
};

ArtistOriginalArts.defaultProps = {
  userOriginalArt: []
};

export default ArtistOriginalArts;
