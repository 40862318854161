/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import { createReferralApi } from 'arts/config/api';
import Classes from '../checkout/styles';
import AppClasses from '../Application/styles';
import { toCamelCase } from '../../config/convertCase';

const BecomeForm = ({ email, fullName, setUrlAndtitle }) => {
  const ref = useRef(null);

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    createReferralApi(values).then(({ currentReferralUrl, title }) => {
      if (!ref.current) { return; }
      setSubmitting(false);
      setUrlAndtitle(currentReferralUrl, title);
    }).catch((error) => {
      if (!ref.current) { return; }
      setSubmitting(false);
      if (error.status === 422) {
        error.json().then(errors => {
          const newErrors = {};
          const errorsCC = toCamelCase(errors);
          Object.keys(errorsCC).forEach(k => {
            newErrors[k] = errorsCC[k].join(', ');
          });
          setErrors(newErrors);
        });
      }
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  return (
    <Formik
      initialValues={ { fullName, email } }
      validate={ validate }
      onSubmit={ onSubmit }
    >
      {
        ({
          isSubmitting
        }) => (
          <Form className={ Classes.genericForm } ref={ ref }>
            <div className={ Classes.guestRow } role="group">
              <div className={ Classes.simpleWideField }>
                <label htmlFor="email">
                  Enter Your Email Address
                </label>
                <Field id="email" type="email" name="email" disabled={ isSubmitting } />
                <ErrorMessage name="email" component="div" className={ Classes.error } />
              </div>
            </div>
            <div className={ Classes.guestRow } role="group">
              <div className={ Classes.simpleWideField }>
                <label htmlFor="fullName">
                  Enter Your Full Name (optional)
                </label>
                <Field id="fullName" type="text" name="fullName" disabled={ isSubmitting } />
                <ErrorMessage name="fullName" component="div" className={ Classes.error } />
              </div>
            </div>
            <div className={ Classes.guestRow } role="group">
              {
                isSubmitting ? (
                  <Loader active inline />
                ) : (
                  <button type="submit" className={ classnames(AppClasses.button, Classes.button) }>
                    CONTINUE
                  </button>
                )
              }
            </div>
          </Form>
        )
      }
    </Formik>
  );
};

BecomeForm.propTypes = {
  email: PropTypes.string,
  fullName: PropTypes.string,
  setUrlAndtitle: PropTypes.func.isRequired
};

export default BecomeForm;
