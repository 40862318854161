import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { request, toCamelCase } from 'arts/../shared/request';
import Classes from './styles';
import Look from './look';
import Shop from './shop';
import QuickShop from '../QuickShopModal/quick_shop';
import { trackEvent } from '../../config/analytics';

const ShoppableLooks = () => {
  const [looks, setLooks] = useState(null);
  const [storeItems, setStoreItems] = useState(null);
  const [shopVisible, setShopVisible] = useState(false);

  useEffect(() => {
    if (looks && looks.length) {
      trackEvent('inspirations center', { 'looks count': looks.length });
    }
  }, [looks ? looks.length : 0]);

  useEffect(() => {
    if (window.shoppableLooks) {
      setLooks(toCamelCase(window.shoppableLooks));
      return;
    }
    request('/inspirations').then(({ shoppableLooks }) => {
      setLooks(toCamelCase(shoppableLooks));
    }).catch(() => {});
  }, []);

  const hideShop = () => setShopVisible(false);

  const onClicked = (id) => {
    const look = looks.find(({ id: lookId }) => lookId === id);
    if (look) {
      setStoreItems({ items: look.storeItems, id });
      setShopVisible(true);
    } else {
      setShopVisible(false);
    }
  };

  const renderLooks = looks ? looks.map(
    (look) => (
      <Look
        key={ look.id }
        { ...look }
        onClicked={ onClicked }
      />
    )
  ) : (
    <div className={ Classes.loader }>
      <Loader active inline />
    </div>
  );

  return (
    <>
      { shopVisible ? <Shop { ...storeItems } hideShop={ hideShop } /> : null }
      <div className={ Classes.looks }>
        <h1>Inspirations</h1>
        { renderLooks }
        <QuickShop />
      </div>
    </>
  );
};

export default ShoppableLooks;
