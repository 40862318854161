import { connect } from 'react-redux';
import { placeOrder } from '../../actions/checkout_process';
import AffirmPlaceOrderButton from './affirm_place_order_button';
import PaypalPlaceOrderButton from './paypal_place_order_button';
import ApplePlaceOrderButton from './apple_place_order_button';

const mapStateToProps = ({ checkoutProcess, applePaySession }) => ({
  ...checkoutProcess,
  applePaySession
});

export const AffirmPlaceOrder = connect(mapStateToProps, {
  placeOrder
})(AffirmPlaceOrderButton);

export const PaypalPlaceOrder = connect(mapStateToProps, {
  placeOrder
})(PaypalPlaceOrderButton);

export const ApplePlaceOrder = connect(mapStateToProps, {
  placeOrder
})(ApplePlaceOrderButton);
