import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import MoreArtistWorks from '../more_artist_works';
import MayAlsoLike from '../may_also_like';
import RecentlyView from '../recently_view';
import Classes from './styles';

const MoreDetails = ({
  artistId, id, name, about
}) => {
  if (!id) return null;

  const moreWorks = artistId ? (
    <MoreArtistWorks
      skipId={ id }
      artistId={ artistId }
      name={ name }
      about={ about }
    />
  ) : null;

  return (
    <div className={ classnames(Classes.work, Classes.moreDetails) }>
      { moreWorks }
      <MayAlsoLike sourceId={ id } />
      <RecentlyView />
    </div>
  );
};

MoreDetails.propTypes = {
  artistId: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  about: PropTypes.string
};

export default MoreDetails;
