import React, {
  useState, useEffect, useRef, useMemo
} from 'react';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { getCurrentReferralApi } from 'arts/config/api';
import BecomeForm from './become_form';
import CopyForm from './copy_form';
import WA from './wa';

import Classes from './styles';

export const FooterText = () => (
  <>
    20% off promo code is not valid on Original Art purchases,
    and cannot be used in conjunction with other promo codes.
    By using this service, you are agreeing to our&nbsp;
    <a href="/terms_of_use" target="_blank">Terms Of Use</a>
    &nbsp;and&nbsp;
    <a href="/privacy" target="_blank">Privacy Policy</a>
    .
  </>
);

const MyReferral = () => {
  const ref = useRef(null);
  const [baseEmail, setBaseEmail] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [currentReferralUrl, setRefUrl] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const header = useMemo(() => (
    <div className={ Classes.header }>
      <h1>
        Give &amp; Get 20% Off
      </h1>
      <div className={ Classes.div } />
      <p>
        Give friends 20% off their first purchase at Artfully Walls,
        and you&lsquo;ll get 20% off of your next purchase once they order.
      </p>
    </div>
  ), []);

  const setUrlAndtitle = (newRefUrl, newTitle, newDescription, newEmail) => {
    setRefUrl(newRefUrl);
    if (newEmail) { setEmail(newEmail); }
  };

  useEffect(() => {
    getCurrentReferralApi().then(({
      baseEmail: newBaseEmail, fullName: newFullName, currentReferralUrl: newRefUrl,
      title: newTitle, description: newDescription, email: newEmail
    }) => {
      if (!ref.current) { return; }
      if (newBaseEmail) {
        setBaseEmail(newBaseEmail);
        if (newFullName) {
          setFullName(newFullName);
        }
      } else if (newRefUrl) {
        setUrlAndtitle(newRefUrl, newTitle, newDescription, newEmail);
      }
      setLoading(false);
    }).catch(() => {});
  }, []);

  let content = null;
  if (isLoading) {
    content = (
      <Loader active inline style={ { margin: 20 } } />
    );
  } else if (currentReferralUrl) {
    // title, description, Share URL
    // <PromoRewards />
    content = (
      <>
        <div className={ classnames(Classes.image, Classes.image2) } />
        <div className={ Classes.referral }>
          <div className={ Classes.r }>
            { header }
            <div className={ Classes.smallDiv } />
            <CopyForm
              text={ currentReferralUrl }
              title="Copy and Paste Your Personal Link Anywhere"
              button="COPY LINK"
            />
            <h2>Share Via:</h2>
            <div className={ Classes.share }>
              <a href={ `mailto:?body=${ encodeURIComponent(email) }` } target="_blank" rel="noreferrer">
                <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.5 10C0.5 6.96243 2.96243 4.5 6 4.5H34C37.0376 4.5 39.5 6.96243 39.5 10V30C39.5 33.0376 37.0376 35.5 34 35.5H6C2.96243 35.5 0.5 33.0376 0.5 30V10ZM6 7.5C5.92435 7.5 5.84949 7.50336 5.77555 7.50994L20 19.0673L34.2244 7.50994C34.1505 7.50336 34.0756 7.5 34 7.5H6ZM36.4611 9.55809L20.9679 22.1463C20.6922 22.3796 20.3473 22.4998 20 22.5C19.7802 22.5002 19.5594 22.4522 19.3546 22.3545C19.2402 22.3 19.1317 22.2306 19.0322 22.1464L3.53893 9.5581C3.51335 9.70153 3.5 9.84921 3.5 10V30C3.5 31.3807 4.61929 32.5 6 32.5H34C35.3807 32.5 36.5 31.3807 36.5 30V10C36.5 9.84921 36.4866 9.70153 36.4611 9.55809Z" fill="black" />
                </svg>
                Email
              </a>
              <a
                href={ `http://www.facebook.com/share.php?u=${ currentReferralUrl }` }
                target="_blank"
                rel="noreferrer"
                className={ Classes.mid }
              >
                <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0.5 6C0.5 2.96243 2.96243 0.5 6 0.5H34C37.0376 0.5 39.5 2.96243 39.5 6V34C39.5 37.0376 37.0376 39.5 34 39.5H6C2.96243 39.5 0.5 37.0376 0.5 34V6ZM6 3.5C4.61929 3.5 3.5 4.61929 3.5 6V34C3.5 35.3807 4.61929 36.5 6 36.5H34C35.3807 36.5 36.5 35.3807 36.5 34V6C36.5 4.61929 35.3807 3.5 34 3.5H6Z" fill="black" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M20.5 14C20.5 10.4101 23.4101 7.5 27 7.5H28.5C29.3284 7.5 30 8.17157 30 9C30 9.82843 29.3284 10.5 28.5 10.5H27C25.067 10.5 23.5 12.067 23.5 14V37.25C23.5 38.0784 22.8284 38.75 22 38.75C21.1716 38.75 20.5 38.0784 20.5 37.25V14Z" fill="black" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M15.5 19.5C15.5 18.6716 16.1716 18 17 18H28.5C29.3284 18 30 18.6716 30 19.5C30 20.3284 29.3284 21 28.5 21H17C16.1716 21 15.5 20.3284 15.5 19.5Z" fill="black" />
                </svg>
                Facebook
              </a>
              <a href={ `https://wa.me/?text=${ encodeURIComponent(email) }` } target="_blank" rel="noreferrer">
                <WA />
                WhatsApp
              </a>
            </div>
            <h2>
              <Link to="/my/referral/rewards">PROMO REWARDS EARNED</Link>
            </h2>
            <p className={ Classes.footer2 }>
              <FooterText />
            </p>
          </div>
        </div>
      </>
    );
  } else {
    content = (
      <>
        <div className={ classnames(Classes.image, Classes.image1) } />
        <div className={ Classes.referral }>
          <div className={ Classes.r }>
            { header }
            <BecomeForm
              email={ baseEmail }
              setUrlAndtitle={ setUrlAndtitle }
              fullName={ fullName }
            />
            <p className={ Classes.footer1 }>
              <FooterText />
            </p>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={ Classes.referrals } ref={ ref }>
      { content }
    </div>
  );
};
export default MyReferral;
