import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import { uniqCollection, removeItemById } from './utils';

const getDefaultState = () => ({
  loading: false,
  walls: [],
  hasMore: true,
  page: -1,
  deletingId: null
});

const myWallsReducer = createReducer(getDefaultState(), {
  [Constants.MyWalls.FETCH](state) {
    return {
      ...state,
      loading: true
    };
  },

  [Constants.MyWalls.FETCH_SUCCESS](state, { walls, page }) {
    return {
      ...state,
      loading: false,
      walls: uniqCollection(state.walls, walls),
      hasMore: walls.length > 0,
      page
    };
  },

  [Constants.MyWalls.DELETE](state, { id }) {
    return {
      ...state,
      deletingId: id
    };
  },

  [Constants.MyWalls.DELETE_SUCCESS](state, { id }) {
    if (state.deletingId === id) {
      return {
        ...state,
        walls: removeItemById(state.walls, id),
        deletingId: null
      };
    }
    return state;
  },

  [Constants.MyWalls.DELETE_FAILURE](state, { id }) {
    if (state.deletingId === id) {
      return {
        ...state,
        deletingId: null
      };
    }
    return state;
  }
});

export default myWallsReducer;
