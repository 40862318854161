import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  loading: false,
  loaded: false,
  Work: {},
  Wall: {},
  User: {}
});

const parseFavorites = ({ favorites }) => {
  const res = {
    Work: {},
    Wall: {},
    User: {}
  };
  favorites.forEach((f) => {
    if (res[f.type]) {
      res[f.type][f.id] = f;
    }
  });
  return res;
};

const addFavorite = (state, favorite) => ({
  ...state,
  [favorite.type]: {
    ...state[favorite.type],
    [favorite.id]: favorite
  }
});

const removeFavorite = (state, favorite) => {
  const {
    [favorite.id]: _,
    ...rest
  } = state[favorite.type];

  return {
    ...state,
    [favorite.type]: rest
  };
};

const favoritesReducer = createReducer(getDefaultState(), {
  [Constants.Favorites.LOAD]: () => ({
    ...getDefaultState(),
    loading: true
  }),
  [Constants.Favorites.LOADED]: (_, { favorites }) => ({
    loading: false,
    loaded: true,
    ...parseFavorites(favorites)
  }),
  [Constants.Favorites.ADD]: (state, { favorite }) => addFavorite(state, favorite),
  [Constants.Favorites.REMOVE]: (state, { favorite }) => removeFavorite(state, favorite)
});

export default favoritesReducer;
