import {
  useEffect, useRef, useState
} from 'react';

export const useComponentSize = () => {
  const ref = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const calculateSize = () => {
    requestAnimationFrame(() => {
      const rect = ref.current?.getBoundingClientRect();
      setSize({ width: rect.width, height: rect.height });
    });
  };

  useEffect(() => {
    setTimeout(() => calculateSize(), 100);
    window.addEventListener('resize', calculateSize);
    return (() => window.removeEventListener('resize', calculateSize));
  }, []);

  return [ref, size];
};
