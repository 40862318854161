import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import { toCamelCase } from '../../wall_builder/config/utils';

const getDefaultState = () => {
  const details = window.collectionDetails || {};
  const res = {};
  Object.keys(details).forEach(slug => {
    res[slug] = toCamelCase(details[slug]);
  });
  return res;
};

export default createReducer(getDefaultState(), {
  [Constants.CollectionDetails.LOAD]: (state, { slug }) => ({
    ...state,
    [slug]: {
      ...state[slug],
      loading: true
    }
  }),
  [Constants.CollectionDetails.LOADED]: (state, { item, slug }) => ({
    ...state,
    [slug]: item
  })
});
