import Constants from 'arts/config/constants';
import { getProducts } from 'arts/config/api';

const fetchProductsLisStart = () => ({
  type: Constants.ProductsList.FETCH
});

const fetchProductsListSuccess = (data) => ({
  type: Constants.ProductsList.FETCH_SUCCESS,
  data
});

const fetchProductsListFailure = (error) => ({
  type: Constants.ProductsList.FETCH_FAILURE,
  error
});

export const fetchProductsList = () => (dispatch) => {
  dispatch(fetchProductsLisStart());

  getProducts().then(
    (data) => {
      dispatch(fetchProductsListSuccess(data.products));
    },
    (error) => dispatch(fetchProductsListFailure(error))
  );
};
