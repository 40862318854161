import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Classes from './styles';

export const renderText = (title, text, hasImages = false) => {
  if (!text) { return null; }
  return (
    <div className={ Classes.section }>
      {
        title ? (<div className={ Classes.title }>{ title }</div>) : null
      }
      <div className={ classnames(Classes.content, { [Classes.withImages]: hasImages }) }>
        { text }
      </div>
    </div>
  );
};

const renderArray = (title, texts) => {
  if (!texts || !texts.length) { return null; }
  return (
    <div className={ Classes.section }>
      <div className={ Classes.title }>{ title }</div>
      <div className={ Classes.content }>{ texts.join('\n') }</div>
    </div>
  );
};

/* eslint-disable jsx-a11y/anchor-is-valid */
const ArtistDetails = ({
  background, exhibitions, statement, styles
}) => (
  <div className={ Classes.author }>
    <a name="profile" key="a" />
    <div className={ Classes.details }>
      <div className={ Classes.column }>
        { renderText('Background', background) }
        { renderText('Statement', statement) }
        {
          styles && styles.length ? (
            <div className={ classnames(Classes.styles, Classes.content) }>
              Art Style Emphasis:&nbsp;
              {
                styles.map(({ name, path }, index) => (
                  <span key={ index }>
                    <Link key={ index } to={ path }>{ name }</Link>
                    { index < styles.length - 1 ? (<>,&nbsp;</>) : null }
                  </span>
                ))
              }
            </div>
          ) : null
        }
      </div>
      <div className={ Classes.column }>
        { renderArray('Selected Exhibitions', exhibitions) }
      </div>
    </div>
  </div>
);
/* eslint-enable jsx-a11y/anchor-is-valid */

ArtistDetails.propTypes = {
  background: PropTypes.string,
  exhibitions: PropTypes.arrayOf(PropTypes.string),
  statement: PropTypes.string,
  styles: PropTypes.array
};

export default ArtistDetails;
