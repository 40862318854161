/* eslint-disable import/no-import-module-exports */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from '../reducers';

const middleware = [
  thunk
];

if (process.env.NODE_ENV === 'development') {
  /* eslint-disable global-require */
  const { createLogger } = require('redux-logger');
  /* eslint-enable global-require */
  const logger = createLogger({ diff: true });
  middleware.push(logger);
}

export const buildStore = () => {
  const store = createStore(
    reducer,
    undefined,
    applyMiddleware(...middleware)
  );
  // eslint-disable-next-line no-undef
  if (module.hot) {
    module.hot.accept(() => {
      /* eslint-disable global-require */
      const nextRootReducer = require('../reducers/index').default;
      /* eslint-enable global-require */
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
};
