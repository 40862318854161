import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  filters: null
});

const wallFilters = createReducer(getDefaultState(), {
  [Constants.WallFilters.FETCH_SUCCESS](state, { filters }) {
    return { ...state, filters };
  }
});

export default wallFilters;
