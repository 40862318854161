import T from 'prop-types';

export const artistWorkShape = T.shape({
  id: T.number.isRequired,
  imageHeight: T.number.isRequired,
  imageWidth: T.number.isRequired,
  href: T.string.isRequired,
  title: T.string.isRequired,
  imgUrl: T.string.isRequired
});

export const artistShape = T.shape({
  id: T.number,
  works: T.arrayOf(artistWorkShape)
});

export const mayAlsoLikeShape = T.arrayOf(artistWorkShape);
