import React from 'react';
import T from 'prop-types';
import {
  frameShape,
  paperShape
} from 'arts/reducers/work';
import Constants, { TAPESTRY } from 'arts/config/constants';
import { humanSize } from 'arts/config/lib';
import Entry from './entry';
import Expandable from '../expandable';

const PRODUCT_DETAILS = 'Product Details and Dimensions';

const title = (frame, paper) => {
  if (paper.paperMaterial === 'Canvas') {
    return `Canvas ${ PRODUCT_DETAILS }`;
  }

  if (frame.frameId) {
    if (paper.paperMaterial !== TAPESTRY) {
      return `Framed ${ PRODUCT_DETAILS }`;
    }
  }

  return PRODUCT_DETAILS;
};

export const renderDetailEtries = (frame, paper, landscape) => {
  const entries = [];
  let sizes = null;
  if (frame.frameId && frame.frameMat === 0) {
    sizes = `${ humanSize(landscape, paper.displayPrintWidthNoMat, paper.displayPrintHeightNoMat, 'x') }${ window.currentLocale.unit }`;
  } else {
    sizes = `${ humanSize(landscape, paper.displayPrintWidth, paper.displayPrintHeight, 'x') }${ window.currentLocale.unit }`;
  }
  entries.push(<Entry label="image size" value={ sizes } key="is" />);
  if (frame.frameId) {
    entries.push(<Entry label={ `${ frame.frameLabel } Moulding` } value={ frame.frameName } key="fn" />);
    entries.push(<Entry label={ `${ frame.frameLabel } Width` } value={ `${ frame.frameDisplayWidth }${ window.currentLocale.unit }` } key="fdw" />);
    if (paper.paperMaterial !== TAPESTRY) {
      entries.push(<Entry label="Frame Depth" value={ frame.frameDisplayDepth } key="fdd" />);
    }
    const finalSize =  paper.paperMaterial === TAPESTRY ? 'Final Size' : 'Final Framed Size';
    entries.push(<Entry label={ finalSize } value={ frame.frameFinalFramedSize } key="ffs" />);
  } else if (paper.paperMaterial === 'Canvas') {
    entries.push(<Entry label="canvas size" value={ sizes } key="cs" />);
  } else {
    entries.push(<Entry label="Print Dimensions" value={ `${ humanSize(landscape, paper.displayPaperWidth, paper.displayPaperHeight, 'x') }${ window.currentLocale.unit }` } key="pd" />);
  }
  return entries;
};

const SelectionDetails = ({
  frame, paper, hasSelection, landscape
}) => {
  if (!hasSelection) { return null; }

  return (
    <Expandable title={ title(frame, paper) } eKey={ Constants.Expandable.KEYS.selectionDetails }>
      { renderDetailEtries(frame, paper, landscape) }
    </Expandable>
  );
};

SelectionDetails.propTypes = {
  paper: paperShape,
  frame: frameShape,
  hasSelection: T.bool,
  landscape: T.bool
};

export default SelectionDetails;
