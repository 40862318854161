/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import { toCamelCase } from 'arts/config/convertCase';
import { loadAddressApi, updateAddressApi } from 'arts/config/api';
import AppClasses from '../Application/styles';
import Classes from '../checkout/styles';

const renderStates = (country) => {
  if (!country || !window.addressStates[country]) { return null; }
  return window.addressStates[country].map((s) => (
    <option value={ s[1] } key={ s[1] }>{ s[0] }</option>
  ));
};

const renderCountries = () => Object.keys(window.addressStates).map((s) => (
  <option value={ s } key={ s }>{ s }</option>
));

const allFields = ['firstName', 'lastName', 'streetAddress', 'city', 'postalCode', 'phone'];
const validate = (values) => {
  const errors = {};
  allFields.forEach(f => {
    if (!values[f]) {
      errors[f] = 'Required field';
    }
  });
  return errors;
};

const defaultAddress = (billing) => {
  const res = toCamelCase((window.orderAddresses || {})[billing ? 'billing' : 'shipping'] || {});
  if (!window.addressStates[res.country]) {
    // eslint-disable-next-line prefer-destructuring
    res.country = Object.keys(window.addressStates)[0];
  }
  if (!res.state) {
    // eslint-disable-next-line prefer-destructuring
    res.state = window.addressStates[res.country][0][1];
  }
  return res;
};

const EditAddress = ({ billing }) => {
  const ref = useRef(null);
  const [address, setAddress] = useState(defaultAddress(billing));

  useEffect(() => {
    if (!Object.keys(address).length) {
      loadAddressApi(billing).then((a) => {
        if (!ref.current) { return; }
        setAddress(a);
      }).catch(() => {});
    }
  }, []);

  const onSubmit = (values, { setSubmitting, setErrors }) => {
    const {
      firstName, lastName, streetAddress, addressLine2,
      city, state, postalCode, country, phone, useShippingAddressAsBilling
    } = values;
    const object = {
      user: {
        [billing ? 'billingAddressAttributes' : 'shippingAddressAttributes']: {
          firstName,
          lastName,
          streetAddress,
          addressLine2,
          city,
          state,
          postalCode,
          country,
          phone
        },
        useShippingAddressAsBilling
      }
    };
    updateAddressApi(object).then(({ url, errors }) => {
      if (!ref.current) { return; }
      if (errors) {
        setErrors(errors);
        setSubmitting(false);
        return;
      }
      if (url) {
        window.location.href = url;
      }
    }).catch(() => {
      setSubmitting(false);
    });
    setSubmitting(true);
  };

  const error = address.error ? (
    <div className={ Classes.row } role="group">
      <div className={ classnames(Classes.wideField, Classes.error) }>
        { address.error }
      </div>
    </div>
  ) : null;

  const changeStates = (target, setFieldValue) => {
    const { value } = target;
    const values =  window.addressStates[value].map((s) => ({
      value: s[1],
      label: s[0]
    }));
    setFieldValue('state', values[0].value);
    setFieldValue('country', value);
  };

  const renderStateField = (values, isSubmitting) => (
    (window.currentLocale.currentLocale === 'en') ? (
      <div className={ Classes.field }>
        <label htmlFor="state">
          State
        </label>
        <Field id="state" as="select" name="state" disabled={ isSubmitting }>
          { renderStates(values.country) }
        </Field>
        <ErrorMessage name="state" component="div" className={ Classes.error } />
      </div>
    ) : null
  );

  const content = !address.id ? (
    <Loader active inline />
  ) : (
    <Formik
      initialValues={ address }
      validate={ validate }
      onSubmit={ onSubmit }
    >
      {
        ({
          isSubmitting, values, setFieldValue
        }) => (
          <Form className={ Classes.genericForm }>
            { error }
            <div className={ Classes.row } role="group">
              <div className={ Classes.field }>
                <label htmlFor="firstName">
                  First Name
                </label>
                <Field id="firstName" type="text" name="firstName" disabled={ isSubmitting } />
                <ErrorMessage name="firstName" component="div" className={ Classes.error } />
              </div>
              <div className={ Classes.field }>
                <label htmlFor="lastName">
                  Last Name
                </label>
                <Field id="lastName" type="text" name="lastName" disabled={ isSubmitting } />
                <ErrorMessage name="lastName" component="div" className={ Classes.error } />
              </div>
            </div>
            <div className={ Classes.row } role="group">
              <div className={ Classes.wideField }>
                <label htmlFor="streetAddress">
                  Street Address
                </label>
                <Field id="streetAddress" type="text" name="streetAddress" disabled={ isSubmitting } />
                <ErrorMessage name="streetAddress" component="div" className={ Classes.error } />
              </div>
            </div>
            <div className={ Classes.row } role="group">
              <div className={ Classes.wideField }>
                <label htmlFor="addressLine2" className={ Classes.optional }>
                  Address Line 2 (optional)
                </label>
                <Field id="addressLine2" type="text" name="addressLine2" disabled={ isSubmitting } />
                <ErrorMessage name="addressLine2" component="div" className={ Classes.error } />
              </div>
            </div>
            <div className={ Classes.row } role="group">
              <div className={ Classes.field }>
                <label htmlFor="city">
                  City
                </label>
                <Field id="city" type="text" name="city" disabled={ isSubmitting } />
                <ErrorMessage name="city" component="div" className={ Classes.error } />
              </div>
              { renderStateField(values, isSubmitting) }
            </div>
            <div className={ Classes.row } role="group">
              <div className={ Classes.field }>
                <label htmlFor="postalCode">
                  Postal Code
                </label>
                <Field id="postalCode" type="text" name="postalCode" disabled={ isSubmitting } />
                <ErrorMessage name="postalCode" component="div" className={ Classes.error } />
              </div>
            </div>
            <div className={ Classes.row } role="group">
              <div className={ Classes.wideField }>
                <label htmlFor="country">
                  Country
                </label>
                <Field id="country" as="select" name="country" disabled={ isSubmitting } onChange={ (e) => changeStates(e.target, setFieldValue) }>
                  { renderCountries() }
                </Field>
                <ErrorMessage name="country" component="div" className={ Classes.error } />
              </div>
            </div>
            <div className={ Classes.row } role="group">
              <div className={ Classes.field }>
                <label htmlFor="phone">
                  Phone
                </label>
                <Field id="phone" type="text" name="phone" disabled={ isSubmitting } />
                <ErrorMessage name="phone" component="div" className={ Classes.error } />
              </div>
            </div>
            {
              billing ? null : (
                <div className={ Classes.row } role="group">
                  <div className={ Classes.wideField }>
                    <label htmlFor="useShippingAddressAsBilling" className={ Classes.optional }>
                      <Field
                        id="useShippingAddressAsBilling"
                        type="checkbox"
                        name="useShippingAddressAsBilling"
                        disabled={ isSubmitting }
                      />
                      Use this address as my billing information
                    </label>
                  </div>
                </div>
              )
            }
            <div className={ Classes.submitRow } role="group">
              {
                isSubmitting ? (
                  <Loader active inline />
                ) : (
                  <button type="submit" className={ classnames(AppClasses.button, Classes.button) }>
                    CONTINUE
                  </button>
                )
              }
            </div>
          </Form>
        )
      }
    </Formik>
  );

  return (
    <div ref={ ref } className={ Classes.content }>
      { content }
    </div>
  );
};

EditAddress.propTypes = {
  billing: PropTypes.bool
};

EditAddress.defaultProps = {
  billing: false
};

export default EditAddress;
