import React from 'react';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { DEFAULT_GRID, THUMB } from './grid_selection';
import WallsAsGrid from '../walls_as_grid';
import WallsAsPage from '../walls_as_page';
import { VALID_QUERY_PARAMS } from './shared';

export const clearFilters = (params, skipParam = null) => {
  const out = {};
  VALID_QUERY_PARAMS.forEach(key => {
    const val = params[key];
    if (val && key !== skipParam) {
      out[key] = val;
    }
  });

  return out;
};

const Walls = ({ history }) => {
  const extraParams = queryString.parse(window.location.search);
  const params = { ...useParams(), ...extraParams };
  const activeFilters = clearFilters(params);
  const format = params.as || DEFAULT_GRID;
  const props = {
    history, activeFilters
  };
  if (format === THUMB) {
    return <WallsAsGrid { ...props } />;
  }
  return <WallsAsPage { ...props } />;
};

Walls.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};

export default Walls;
