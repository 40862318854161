import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Sidebar } from 'semantic-ui-react';
import Classes from './styles';
import Items from './items';

const Shop = ({ id, items, hideShop }) => {
  const [sideBarVisible, setSideBarVisible] = useState(false);

  const hideSibar = () => setSideBarVisible(false);

  useEffect(() => setSideBarVisible(true), []);

  return (
    <Sidebar.Pushable className={ Classes.looksKeeper } onClick={ hideSibar }>
      <Sidebar
        animation="overlay"
        className={ Classes.sidebar }
        direction="right"
        as="div"
        // vertical
        visible={ sideBarVisible }
        onHidden={ hideShop }
      >
        <Items
          hideSibar={ hideSibar }
          items={ items }
          id={ id }
        />
      </Sidebar>
      <Sidebar.Pusher dimmed={ sideBarVisible }>
        &nbsp;
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

Shop.propTypes = {
  id: PropTypes.number,
  items: PropTypes.array,
  hideShop: PropTypes.func.isRequired
};

export default Shop;
