import { connect } from 'react-redux';
import { fetchWalls, resetWalls, fetchWallFilters } from 'arts/actions/walls';
import WallsAsPage from './walls_as_page';

const mapStateToProps = ({ walls, wallFilters: { filters } }) => ({
  ...walls,
  wallFilters: filters
});

export default connect(mapStateToProps, { fetchWalls, resetWalls, fetchWallFilters })(WallsAsPage);
