import React, { Suspense } from 'react';
import classnames from 'classnames';
import { Loader } from 'semantic-ui-react';
import Classes from '../explore/styles';
import AppClasses from '../Application/styles';

const SearchQuery = React.lazy(() => import('./search_query'));

const AlignedLoader = () => (
  <div className={ AppClasses.innerContainerArt }>
    <Loader active inline />
  </div>
);

export default () => (
  <Suspense fallback={ <AlignedLoader /> }>
    <SearchQuery />
  </Suspense>
);
