import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'arts/components/Modal';
import { MODAL_SELECTOR } from 'arts/config/constants';
import Classes from '../Modal/styles';

const WallPreview = ({ children, onCancel }) => (
  <Modal
    selector={ MODAL_SELECTOR }
    onCancel={ onCancel }
    className={ Classes.wallScale }
  >
    { children }
  </Modal>
);

WallPreview.propTypes = {
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default WallPreview;
