import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AppClasses from '../Application/styles';
import Classes from '../checkout/styles';
import Bluesnap from './bluesnap';
import Stripe from './stripe';

const EditCreditCard = ({ failed, id }) => {
  if (failed) {
    return (
      <div className={ Classes.genericForm }>
        <p className={ Classes.error }>
          Unfortunatelly, we cannot charge your credit card right now,
          please check the card number and expiration again or use another card.
        </p>
        <div className={ Classes.content }>
          <a
            href={ `/orders/${ id }/payment/new` }
            className={ classnames(AppClasses.button, Classes.button) }
          >
            TRY AGAIN
          </a>
        </div>
      </div>
    );
  }

  if (window.blueSnapConfig) {
    return (
      <Bluesnap
        { ...window.blueSnapConfig }
        form={ document.getElementById('bluesnap_capture') }
        unsubscribeElement={ document.getElementById('unsubscribe_user_on_checkout') }
      />
    );
  }

  if (window.stripeConfig) {
    return <Stripe { ...window.stripeConfig } />;
  }

  return null;
};

EditCreditCard.propTypes = {
  failed: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired
};

export default EditCreditCard;
