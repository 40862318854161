import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { scheduleProductView } from '../../config/api';
import { onWorkDetails } from '../../config/analytics';

let isSpaNav = false;

export const GtmDetails = ({ data }) => {
  useEffect(() => {
    scheduleProductView(onWorkDetails(data));
  }, []);
  return null;
};

const GTMPageView = ({ pageType, section }) => {
  useEffect(() => {
    if (isSpaNav) {
      window.dataLayer.push({
        pageType,
        section,
        event: 'pageview',
        pagePath: window.location.href
      });
    }
    isSpaNav = true;
  }, [window.location.href]);
  return null;
};

GtmDetails.propTypes = {
  data: PropTypes.object
};

GTMPageView.propTypes = {
  pageType: PropTypes.string.isRequired,
  section: PropTypes.string
};

export default GTMPageView;
