import React from 'react';
import T from 'prop-types';
import PreviewModal from './preview_modal';
import ModalClasses from '../Modal/styles';

const ZoomModal = ({
  img, onHide
}) => (
  <PreviewModal
    onHide={ onHide }
    className={ ModalClasses.zoom }
    contentOptions={ { style: { backgroundImage: `url(${ img })` }, onClick: onHide } }
  />
);

ZoomModal.propTypes = {
  img: T.string,
  onHide: T.func,
  withFrame: T.bool
};

export default ZoomModal;
