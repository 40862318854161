import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import AppClasses from '../Application/styles';
import Section from '../checkout/section';
import Classes from '../checkout/styles';
import EmailSection from '../checkout/email_section';
import CartItems from '../cart_items';
import CheckoutSummary from '../checkout_summary';
import ShowAddress from '../addresses/show';
import PromoCode from './promo_code';
import PlacePartnerOrder from './place_partner_order';
import VirtualOrder from './virtual_order';
import SelectPaymentMethod from '../select_payment_method';
import PlacedOrder from '../edit_order_payment/placed_order';
import GiftCards from './gift_cards';
import GiftMessage from './gift_message';
import GTMPageView from '../Application/data_layer';

const NewOrderPayment = ({
  loading,
  calculatingShipping,
  paymentStatus,
  currentPaymentStatus,
  appliedPromoCode,
  promoCodeEligible,
  isPartner,
  reviewText,
  virtualOrder,
  allowAffirmCheckout,
  allowApplePay,
  paypalOrderText,
  giftCardUsages,
  history,
  giftMessage
}) => {
  const { id } = useParams();

  let sections = null;
  let giftCardMessage = null;
  if (!loading) {
    if (paymentStatus) {
      sections = (
        <Section title="PLACE ORDER">
          <PlacedOrder
            currentPaymentStatus={ currentPaymentStatus }
            paymentStatus={ paymentStatus }
            virtualOrder={ virtualOrder }
            id={ id }
          />
        </Section>
      );
    } else {
      let placeOrderForm = null;
      if (isPartner) {
        placeOrderForm = <PlacePartnerOrder { ...isPartner } />;
      } else {
        placeOrderForm = virtualOrder ? <VirtualOrder /> : (
          <SelectPaymentMethod
            allowAffirmCheckout={ allowAffirmCheckout }
            allowApplePay={ allowApplePay }
            paypalOrderText={ paypalOrderText }
            orderId={ parseInt(id, 10) }
          />
        );
      }
      giftCardMessage = (
        <GiftMessage message={ giftMessage || '' } { ...(window.giftMessageIssues || {}) } />
      );
      sections = (
        <>
          <Section title="GIFT CARD">
            <GiftCards giftCardUsages={ giftCardUsages } { ...(window.giftCard || {}) } />
          </Section>
          <Section title="PLACE ORDER">
            <PromoCode
              appliedPromoCode={ appliedPromoCode }
              promoCodeEligible={ promoCodeEligible }
            />
            <div className={ Classes.content }>
              { reviewText }
            </div>
            { placeOrderForm }
          </Section>
        </>
      );
    }
  }

  const polling = calculatingShipping || paymentStatus === 'being_charged';

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="checkout" section="checkout" />
      <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
        <h1>Checkout</h1>
      </div>
      <div className={ AppClasses.gridCheckout }>
        <div className={ AppClasses.left }>
          <EmailSection />
          <Section title="SHIPPING ADDRESS" edit="/my/address/edit">
            <ShowAddress />
            { giftCardMessage }
          </Section>
          <Section title="BILLING ADDRESS" edit="/my/address/edit?billing=true">
            <ShowAddress billing />
          </Section>
          { sections }
        </div>
        <div className={ AppClasses.right }>
          <Section title="SUMMARY OF CHARGES">
            <div className={ Classes.content }>
              <CheckoutSummary noRedirect history={ history } />
            </div>
          </Section>
          <Section title="SHOPPING CART" edit="/cart_items">
            <div className={ Classes.content }>
              <CartItems
                narrow
                orderId={ parseInt(id, 10) }
                polling={ polling }
                checkoutStep={ 4 }
              />
            </div>
          </Section>
        </div>
      </div>
    </div>
  );
};

NewOrderPayment.propTypes = {
  loading: PropTypes.bool.isRequired,
  appliedPromoCode: PropTypes.string,
  reviewText: PropTypes.string,
  promoCodeEligible: PropTypes.bool,
  isPartner: PropTypes.object,
  virtualOrder: PropTypes.bool,
  allowAffirmCheckout: PropTypes.bool,
  allowApplePay: PropTypes.bool,
  paypalOrderText: PropTypes.string,
  paymentStatus: PropTypes.string,
  calculatingShipping: PropTypes.string,
  currentPaymentStatus: PropTypes.string,
  giftCardUsages: PropTypes.array,
  history: ReactRouterPropTypes.history.isRequired,
  giftMessage: PropTypes.string
};

export default NewOrderPayment;
