import React, { useState, useRef, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  createAppleWalletApi,
  completeApplePaymentApi
} from 'arts/config/api';

const STYLES = {
  display: 'inline-block',
  borderRadius: 0,
  WebkitAppearance: '-apple-pay-button',
  ApplePayButtonType: 'plain',
  ApplePayButtonStyle: 'white-outline',
  width: '235px'
};

const ApplePlaceOrderButton = ({
  placeOrder, orderId, applePaySession, status
}) => {
  const [error, setError] = useState(null);
  const appleSesionRef = useRef(null);

  const { session, paymentId } = applePaySession || {};

  useEffect(() => {
    if (!applePaySession) {
      placeOrder(orderId);
    }
  }, []);

  const setAppleError = () => setError('Apple Pay is not available on this device');

  const onClick = () => {
    try {
      appleSesionRef.current = new window.ApplePaySession(2, session);
    } catch (e) {
      setAppleError();
      return;
    }

    appleSesionRef.current.onvalidatemerchant = ({ validationURL }) => {
      createAppleWalletApi(orderId, paymentId, validationURL).then(({ token }) => {
        appleSesionRef.current.completeMerchantValidation(token);
      }).catch(() => {
        try {
          if (appleSesionRef.current) { appleSesionRef.current.abort(); }
        } catch (e) {
          //
        }
        setAppleError();
      });
    };

    appleSesionRef.current.onpaymentauthorized = ({ payment }) => {
      completeApplePaymentApi(orderId, paymentId, JSON.stringify(payment)).then(({ url }) => {
        window.location.href = url;
      }).catch(() => {
        if (appleSesionRef.current) {
          appleSesionRef.current.completePayment(window.ApplePaySession.STATUS_FAILURE);
        }
      });
    };

    appleSesionRef.current.begin();
  };

  if (error) {
    return error;
  }

  return applePaySession && status === 'select' ? (
    <button type="button" style={ STYLES } onClick={ onClick } aria-label="Pay">&nbsp;</button>
  ) : (
    <Loader active inline size="tiny" />
  );
};

ApplePlaceOrderButton.propTypes = {
  applePaySession: PropTypes.object,
  placeOrder: PropTypes.func.isRequired,
  orderId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired
};

export default ApplePlaceOrderButton;
