import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AppClasses from '../Application/styles';
import Classes from '../walls/styles';

const Container = React.forwardRef(({ children }, ref) => (
  <ul className={ classnames(AppClasses.gridContainer2inRow, Classes.grid) } ref={ ref }>
    { children }
  </ul>
));

Container.propTypes = {
  children: PropTypes.node.isRequired
};

export default Container;
