import Constants from 'arts/config/constants';
import { loadCollectionsApi } from 'arts/config/api';

export const loadCollections = () => (dispatch) => {
  dispatch({ type: Constants.Collections.LOAD });
  loadCollectionsApi({}).then((items) => {
    dispatch({ type: Constants.Collections.LOADED, items });
  }).catch(() => {});
};

export const loadDesignerPicks = () => (dispatch) => {
  dispatch({ type: Constants.DesignerPicks.LOAD });
  loadCollectionsApi({ picks: true }).then((items) => {
    dispatch({ type: Constants.DesignerPicks.LOADED, items });
  }).catch(() => {});
};

export const loadGiftGuides = () => (dispatch) => {
  dispatch({ type: Constants.GiftGuides.LOAD });
  loadCollectionsApi({ gifts: true }).then((items) => {
    dispatch({ type: Constants.GiftGuides.LOADED, items });
  }).catch(() => {});
};
