import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  [Constants.Expandable.KEYS.additionalInfo]: false,
  [Constants.Expandable.KEYS.selectionDetails]: false,
  [Constants.Expandable.KEYS.prints]: false,
  [Constants.Expandable.KEYS.frames]: false,
  [Constants.Expandable.KEYS.shipping]: false
});

const expandable = createReducer({
  ...getDefaultState(),
  [Constants.Expandable.KEYS.additionalInfo]: true
}, {
  [Constants.Expandable.ACTIONS.expand](state, { key }) {
    return {
      ...getDefaultState(),
      [key]: true
    };
  }
});

export default expandable;
