import React from 'react';

const SvgComponent = (props) => (
  <svg viewBox="0 0 20 20" { ...props } width={ 20 } height={ 20 }>
    <g clipPath="url(#clip0_165_166)">
      <rect width="20" height="20" fill="white" fillOpacity="0.5" />
      <path d="M8 3H4C3.44772 3 3 3.44772 3 4V8H4V4H8V3Z" fill="black" fillOpacity="0.5" />
      <path d="M12.1959 4H16V8H17V4C17 3.44772 16.5523 3 16 3H12.1959V4Z" fill="black" fillOpacity="0.5" />
      <path d="M16 16V12.1959H17V16C17 16.5523 16.5523 17 16 17H12.1959V16H16Z" fill="black" fillOpacity="0.5" />
      <path d="M8 16H4V12H3V16C3 16.5523 3.44772 17 4 17H8V16Z" fill="black" fillOpacity="0.5" />
      <path d="M12 10C12 11.1046 11.1046 12 10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10Z" fill="#110000" fillOpacity="0.5" />
    </g>
    <defs>
      <clipPath id="clip0_165_166">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
