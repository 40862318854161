import Constants from 'arts/config/constants';
import { loadAdditionalWorks, loadRecentWorks } from 'arts/config/api';

// YOU MAY ALSO LIKE
const loadAlsoLikeStart = () => ({
  type: Constants.RelatedWorks.LOAD_ALSO_LIKE_START
});

const loadAlsoLikeSuccess = (data) => ({
  type: Constants.RelatedWorks.LOAD_ALSO_LIKE_SUCCESS,
  data
});

const loadAlsoLikeFailure = (error) => ({
  type: Constants.RelatedWorks.LOAD_ALSO_LIKE_FAILURE,
  error
});

export const loadAlsoLike = (sourceId) => (dispatch) => {
  dispatch(loadAlsoLikeStart());
  loadAdditionalWorks(sourceId).then(
    (data) => dispatch(loadAlsoLikeSuccess(data)),
    (error) => dispatch(loadAlsoLikeFailure(error))
  );
};

// RECENTLY VIEW
const loadRecentlyViewStart = () => ({
  type: Constants.RelatedWorks.LOAD_RECENTLY_VIEW_START
});

const loadRecentlyViewSuccess = (data) => ({
  type: Constants.RelatedWorks.LOAD_RECENTLY_VIEW_SUCCESS,
  data
});

const loadRecentlyViewFailure = (error) => ({
  type: Constants.RelatedWorks.LOAD_RECENTLY_VIEW_FAILURE,
  error
});

export const loadRecentlyView = (ids) => (dispatch) => {
  dispatch(loadRecentlyViewStart());
  loadRecentWorks(ids).then(
    (data) => dispatch(loadRecentlyViewSuccess(data)),
    (error) => dispatch(loadRecentlyViewFailure(error))
  );
};

export const setRecentWorkIds = (ids) => ({
  type: Constants.RelatedWorks.SET_RECENTLY_VIEWED,
  ids
});
