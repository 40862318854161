import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumbsGeneric from '../bread_crumbs';

const BreadCrumbsTrios = ({
  title, id
}) => {
  const links = [
    { text: 'Trios', to: '/trios' }
  ];
  if (id) {
    links.push({
      text: title,
      to: `/trios/${ id }`
    });
  }
  return (
    <BreadCrumbsGeneric links={ links } />
  );
};

BreadCrumbsTrios.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number
};

export default BreadCrumbsTrios;
