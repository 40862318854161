import React from 'react';
import PropTypes from 'prop-types';
import Classes from './styles';

const MobileFilterLink = ({ onClick, id, name }) => {
  const onClicked = () => onClick(id);

  return (
    <button onClick={ onClicked } type="button" className={ Classes.l }>
      { name }
    </button>
  );
};

MobileFilterLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default MobileFilterLink;
