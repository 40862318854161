import { connect } from 'react-redux';
import { fetchArtists, resetArtists, fetchArtistFilters } from 'arts/actions/artists';
import Artists from './artists';

const mapStateToProps = ({ artists, artistFilters: { filters } }) => ({
  ...artists,
  artistFilters: filters
});

export default connect(mapStateToProps, {
  fetchArtists, resetArtists, fetchArtistFilters
})(Artists);
