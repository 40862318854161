import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import Classes from '../checkout/styles';
import AddGiftMessage, { doRemove } from './add_gift_message';

const GiftMessage = ({ message, error }) => {
  const [showForm, setShowForm] = useState(!!error);
  const [isRemoving, setRemoving] = useState(false);

  const remove = () => {
    setRemoving(true);
    doRemove();
  };

  const show = () => setShowForm(true);

  let content = null;

  if (showForm)  {
    content = <AddGiftMessage message={ message } error={ error } />;
  } else if (message) {
    const buttons = isRemoving ? (<Loader active inline />) : (
      <>
        <button type="button" className={ Classes.actionLink } onClick={ show }>
          Edit
        </button>
        &nbsp;&nbsp;
        <button type="button" className={ Classes.actionLink } onClick={ remove }>
          Remove
        </button>
      </>
    );
    content = (
      <>
        <p>
          <strong>Gift Message:</strong>
          <br />
          { message }
        </p>
        { buttons }
      </>
    );
  } else {
    content = (
      <button type="button" className={ Classes.actionLink } onClick={ show }>
        Add a Gift Message
      </button>
    );
  }

  return (
    <div className={ Classes.content }>
      { content }
    </div>
  );
};

GiftMessage.propTypes = {
  message: PropTypes.string,
  error: PropTypes.string
};

export default GiftMessage;
