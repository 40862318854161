import React, { useState, useRef, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { toCamelCase } from 'arts/config/convertCase';
import { loadAddressApi } from 'arts/config/api';
import Classes from '../checkout/styles';

const ShowAddress = ({ billing }) => {
  const ref = useRef(null);
  const [address, setAddress] = useState(toCamelCase((window.orderAddresses || {})[billing ? 'billing' : 'shipping'] || {}));

  useEffect(() => {
    if (!Object.keys(address).length) {
      loadAddressApi(billing).then((a) => {
        if (!ref.current) { return; }
        setAddress(a);
      }).catch(() => {});
    }
  }, []);

  if (address.id && !address.name) {
    return null;
  }

  const content = !address.id ? (
    <Loader active inline />
  ) : (
    <>
      <strong>
        { address.name }
      </strong>
      {
        (address.toShort || []).map((v, i) => (
          <p key={ i }>
            { v }
          </p>
        ))
      }
    </>
  );

  return (
    <div ref={ ref } className={ Classes.content }>
      { content }
    </div>
  );
};

ShowAddress.propTypes = {
  billing: PropTypes.bool
};

ShowAddress.defaultProps = {
  billing: false
};

export default ShowAddress;
