import React, { useState, useEffect, useRef } from 'react';
import { loadInfoPage } from 'arts/config/api';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import AppClasses from '../Application/styles';
import Classes from './styles';
import GTMPageView from '../Application/data_layer';

const StaticPage = ({ location: { pathname, hash } }) => {
  const ref = useRef(null);
  const [page, setPage] = useState(null);
  const id = pathname.substr(1);

  useEffect(() => {
    loadInfoPage(id).then(({ content, pageId }) => {
      if (ref.current && id === pageId) {
        setPage(content);
        if (hash === '#ccpa') {
          setTimeout(() => {
            const ccpa = document.querySelector('#ccpa');
            if (ccpa) {
              ccpa.scrollIntoView({
                behavior: 'smooth'
              });
            }
          }, 500);
        }
      }
    }).catch(() => {});
  }, []);

  const content = page ? (
    // eslint-disable-next-line react/no-danger
    <div className={ Classes.root } dangerouslySetInnerHTML={ { __html: page } } />
  ) : (
    <Loader active inline />
  );

  return (
    <div className={ AppClasses.container20 } ref={ ref }>
      <GTMPageView pageType="other" />
      <div className={ AppClasses.innerContainer20 }>
        { content }
      </div>
    </div>
  );
};

StaticPage.propTypes = {
  location: PropTypes.object
};

export default StaticPage;
