// import '../vendor/semantic.min.css';
import React from 'react';
import 'intersection-observer';
import { buildStore } from 'arts/config/store';
import Application from 'arts/components/Application';
import 'react-day-picker/lib/style.css';
import 'react-alice-carousel/lib/alice-carousel.css';
import './semantic_custom.less';

export const store = buildStore();
const Arts = (props) => <Application { ...props } />;

export default Arts;
