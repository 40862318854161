import React from 'react';
import T from 'prop-types';
import Modal from 'arts/components/Modal';
import Work from 'arts/components/Work';
import { MODAL_SELECTOR } from 'arts/config/constants';
import ModalClasses from '../Modal/styles';

const QuickShopModal = ({ id, onCancel, cancelUrl }) => (
  <Modal
    selector={ MODAL_SELECTOR }
    onCancel={ onCancel }
    cancelUrl={ cancelUrl }
    className={ ModalClasses.quickShop }
  >
    <Work
      id={ id }
      modal
    />
  </Modal>
);

QuickShopModal.propTypes = {
  id: T.number.isRequired,
  onCancel: T.func,
  cancelUrl: T.string
};

export default QuickShopModal;
