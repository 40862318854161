import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import {
  toCamelCase
} from 'arts/config/convertCase';

const getDefaultState = () => ({
  loading: false,
  error: null,
  data: {
    id: null,
    images: [],
    sections: [],
    user: {}
  }
});

const product = createReducer(getDefaultState(), {
  [Constants.Product.FETCH](state) {
    return {
      ...state,
      loading: true,
      data: {
        id: null,
        images: [],
        sections: [],
        user: {}
      }
    };
  },

  [Constants.Product.FETCH_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },

  [Constants.Product.FETCH_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      data: toCamelCase(action.data)
    };
  }
});

export default product;
export * from './proptypes';
