import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  loading: false,
  error: null,
  query: null,
  works: []
});

const exploreArt = createReducer(getDefaultState(), {
  [Constants.ExploreArt.FETCH](state, { query }) {
    return {
      ...state,
      error: null,
      loading: true,
      works: [],
      query
    };
  },

  [Constants.ExploreArt.FETCH_FAILURE](state, { error }) {
    return {
      ...state,
      loading: false,
      error
    };
  },

  [Constants.ExploreArt.FETCH_SUCCESS](state, { works, query }) {
    if (state.query !== query) { return state; }
    return {
      ...state,
      loading: false,
      works
    };
  },

  [Constants.ExploreArt.RESET]() {
    return getDefaultState();
  }
});

export default exploreArt;
