import React from 'react';
import T from 'prop-types';
import queryString from 'query-string';
import Work from '../Work';
import Scroll from '../Application/scroll';

const parseFrameAndPaper = (search) => {
  const query = queryString.parse(search);
  return {
    paperId: query.paper_id ? parseInt(query.paper_id, 10) : null,
    frameId: query.frame_id ? parseInt(query.frame_id, 10) : null
  };
};

const parseSlugAndId = (workSlug) => {
  const entries = workSlug.split(/-/);
  const id = parseInt(entries[entries.length - 1], 10);
  const slug = entries.slice(0, -1).join('-');
  return { id, slug };
};

const WorksShow = ({ match: { params }, history: { location: { search, state } } }) => {
  const { id, slug } = parseSlugAndId(params.workSlug);

  return (
    <>
      <Scroll key={ id } />
      <Work
        id={ id }
        slug={ slug }
        detail={ state }
        { ...parseFrameAndPaper(search) }
      />
    </>
  );
};

WorksShow.propTypes = {
  match: T.shape({
    params: T.shape({
      workSlug: T.string.isRequired
    }).isRequired
  }).isRequired,
  history: T.shape({
    location: T.shape({
      search: T.string,
      state: T.any
    }).isRequired
  }).isRequired
};

export default WorksShow;
