import { connect } from 'react-redux';
import { fetchWall } from 'arts/actions/wall';
import { addWallToCart, addWallPicturesToCart } from 'arts/actions/cart_items';
import { fetchWalls, resetWalls, fetchWallFilters } from 'arts/actions/walls';
import Wall from './wall';

const mapStateToProps = ({
  wall, walls, wallFilters: { filters }
}, { match: { params: { id } } }) => {
  let wallId = parseInt(id, 10);
  let sharingToken = null;
  if (id !== wallId.toString()) {
    if (id === 'style' || id === 'room') {
      wallId = id;
    } else {
      sharingToken = id;
    }
  }
  const w = (walls.walls || []).find(v => v.id === wallId);
  if (w) {
    return {
      ...w,
      single: false,
      wallFilters: filters,
      walls
    };
  }
  const loadedWall = wall[wallId] || wall[sharingToken];
  if (loadedWall) {
    return {
      ...loadedWall,
      single: true,
      sharingToken
    };
  }
  return {
    id: sharingToken ? null : wallId,
    single: true,
    sharingToken
  };
};

export default connect(mapStateToProps, {
  fetchWall, fetchWalls, resetWalls, fetchWallFilters, addWallToCart, addWallPicturesToCart
})(Wall);
