/* eslint-disable react/no-children-prop */
import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import numeral from 'arts/config/numeral';
import ReactMarkdown from 'react-markdown';
import giftCardsHeader from 'arts/assets/gift_card_header.jpg';
import eGiftCardsImage from 'arts/assets/e_gift_cards_image.png';
import balanceInquiryImage from 'arts/assets/balance_inquiry_image.png';
import { trackEvent } from 'arts/config/analytics';
import AppClasses from '../Application/styles';
import Classes from './styles';
import GTMPageView from '../Application/data_layer';

export const eGiftCards = () => {
  numeral.locale(window.currentLocale.currentLocale);
  const from = numeral(25).format('$0');
  const to = numeral(2000).format('$0');
  return ({
    image: eGiftCardsImage,
    title: 'E-Gift Cards',
    text: `Show them how much you care with our e-gift card. It is emailed directly to the recipient with a personal message from you on any date you select. Available amount from ${ from } - ${ to }.`,
    link: {
      name: 'SHOP NOW',
      href: '/gift_cards/card'
    }
  });
};

const BALANCE_INQUERY = {
  image: balanceInquiryImage,
  title: 'Balance Inquiry',
  text: 'See the amount of funds left on your e-gift card.',
  link: {
    name: 'CHECK BALANCE',
    href: '/gift_cards/balance_inquiry'
  }
};

const TERMS = `Artfully Walls Gift Cards are issued by Artfully Walls Ltd. ("we", "us", and "our").

The Gift Cards may only be redeemed toward the purchase of products our Web Site, www.artfullywalls.com.  In addition to the terms set forth below, the purchase and use of the Gift Cards and the use of our Web Site are governed by the our term of use that can be accessed at https://www.artfullywalls.com/terms_of_use.

Coupons and discounts cannot be applied to gift card purchases. If other merchandise is included in your order, the coupon value will apply only to those items.

Artfully Walls shall not be responsible for the loss, unauthorized use or theft of a Gift Card.

Gift Card are nonrefundable and cannot be transferred, exchanged or resold and cannot be redeemed for cash (except where required by applicable law).`;

/* eslint-disable jsx-a11y/anchor-is-valid */
const section = (items) => (
  <div className={ Classes.section }>
    <div className={ Classes.items }>
      <img src={ items.image } alt={ items.title } className={ Classes.image } />
      <div className={ Classes.title }>{ items.title }</div>
      <ReactMarkdown
        className={ Classes.text }
        escapeHtml
        children={ items.text }
      />
      <div className={ Classes.delimiterBlackHorizontal } />
      <Link to={ items.link.href } key="link" className={ Classes.link }>
        <button type="button" className={ Classes.button } key="button">
          { items.link.name }
        </button>
      </Link>
    </div>
  </div>
);
/* eslint-enable jsx-a11y/anchor-is-valid */

const GiftCards = () => {
  useEffect(() => {
    trackEvent('gift cards');
  }, []);

  return (
    <div className={ AppClasses.container20 }>
      <GTMPageView pageType="category" section="gift cards" />
      <div className={ AppClasses.innerContainer20  }>
        <img src={ giftCardsHeader } alt="Gift Cards" className={ Classes.imageTop } />
        <div className={ Classes.container }>
          { section(eGiftCards()) }
          <div className={ Classes.delimiterGaryVertical } />
          { section(BALANCE_INQUERY) }
        </div>
        <div className={ Classes.terms } id="GiftCardsTerms">
          <span className={ Classes.termsTitle }>e-Gift Card Terms and Conditions:</span>
          <ReactMarkdown
            className={ Classes.termsText }
            escapeHtml
            children={ TERMS }
          />
        </div>
      </div>
    </div>
  );
};

export default GiftCards;
