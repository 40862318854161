import React, {
  useState, useEffect, useMemo, useRef
} from 'react';
import { Loader } from 'semantic-ui-react';
import classnames from 'classnames';
import { request, toCamelCase } from 'arts/../shared/request';
import { scrollPageTo } from 'arts/config/lib';
import PropTypes from 'prop-types';
import SmallItemsCarousel, { WorkImage, calculateWith } from '../small_items_carousel';
import AppClasses from '../Application/styles';
import Classes from './styles';
import { trackEvent } from '../../config/analytics';

const onMoreLikeThis = () => true;

const renderImage = ({
  // eslint-disable-next-line react/prop-types
  href, title, imgUrl, id, imageWidth, imageHeight
}, index) => {
  const width = calculateWith(imageWidth, imageHeight);
  return (
    <div key={ id } style={ { width } }>
      <WorkImage
        imageWidth={ imageWidth }
        imageHeight={ imageHeight }
        imgUrl={ imgUrl }
        href={ href }
        title={ title }
        onClick={ onMoreLikeThis }
        index={ index }
      />
    </div>
  );
};

const MoreLikeBar = ({ workId, setMlBannerWorkId }) => {
  const [works, setWorks] = useState(null);
  const [hasInitial, setHasInitial] = useState(true);

  const ref = useRef(null);

  useEffect(() => {
    setHasInitial(false);
    if (workId) {
      trackEvent('more like bar', { 'work id': workId });
      request(`/more-like-this/${ workId }?limit=25`).then(response => {
        const { items } = toCamelCase(response);
        if (ref.current) {
          setWorks(items);
          const topPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
          if (topPosition + 150 > window.pageYOffset + window.innerHeight) {
            scrollPageTo(topPosition - window.innerHeight + 250, 250);
          }
        }
      }).catch(() => {});
    }
  }, [workId]);

  const onClose = () => setHasInitial(true);

  const onTransitionEnd = () => {
    if (hasInitial) {
      setMlBannerWorkId(null);
    }
  };

  const content = useMemo(() => {
    if (works) {
      return (
        <SmallItemsCarousel
          gtmListName="recommended"
          items={ works }
          renderItem={ renderImage }
        />
      );
    }
    return (
      <div className={ Classes.loading }>
        <Loader inline active content="Loading Art..." />
      </div>
    );
  }, [workId, works, works ? works.length : 0]);

  return (
    <li
      className={
        classnames(AppClasses.moreLike, Classes.moreBar, { [Classes.moreBarInitial]: hasInitial })
      }
      onTransitionEnd={ onTransitionEnd }
      ref={ ref }
    >
      <div className={ Classes.close }>
        <button type="button" onClick={ onClose }>&times;</button>
      </div>
      { content }
    </li>
  );
};

MoreLikeBar.propTypes = {
  workId: PropTypes.number,
  setMlBannerWorkId: PropTypes.func
};

export default MoreLikeBar;
