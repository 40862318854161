import React, { PureComponent } from 'react';
import T from 'prop-types';
import { mayAlsoLikeShape as recentlyView } from 'arts/reducers/artist';
import deepEqual from 'deep-equal';
import SmallItemsCarousel from '../small_items_carousel';

export default class RecentlyView extends PureComponent {
  static propTypes = {
    ids:                 T.arrayOf(T.number),
    data:                recentlyView,
    loadRecentlyView:    T.func.isRequired
  };

  componentDidMount() {
    const { ids, loadRecentlyView } = this.props;
    if (ids) {
      loadRecentlyView(ids);
    }
  }

  componentDidUpdate({ ids: idsWere }) {
    const { ids, loadRecentlyView } = this.props;
    if (!deepEqual(ids, idsWere)) {
      loadRecentlyView(ids);
    }
  }

  render() {
    const { data } = this.props;
    return (
      <SmallItemsCarousel items={ data } title="Recently Viewed" gtmListName="recent" />
    );
  }
}
