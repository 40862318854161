import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PaymentMethod from './payment_method';
import classes from './classes';
import { AffirmPlaceOrder, PaypalPlaceOrder, ApplePlaceOrder } from '../place_order';
import PleaseWait from './please_wait';
import Error from './error';

const SelectPaymentMethod = ({
  status,
  method,
  placeOrder,
  setMethod,
  allowAffirmCheckout,
  allowApplePay,
  paypalOrderText,
  orderId,
  error
}) => {
  useEffect(() => {
    if (paypalOrderText) {
      setMethod('paypal');
    }
  }, [paypalOrderText]);

  const doPlaceOrder = () => {
    placeOrder(orderId, false);
  };

  const affirmTitle = (
    <div className={ classes.title }>
      <img src="https://cdn-assets.affirm.com/images/black_logo-white_bg.jpg" alt="Affirm" className={ classes.affirm } />
      <span>Monthly Payments</span>
    </div>
  );

  const affirm = allowAffirmCheckout ? (
    <PaymentMethod
      title={ affirmTitle }
      method="affirm"
      currentMethod={ method }
      setMethod={ setMethod }
    >
      <div className={ classes.details }>
        <strong>How does Affirm work?</strong>
        <br />
        Just enter your basic information and get a real-time credit decision.
        You will complete your payment on the Affirm website.
        If you are approved and confirm your loan, your purchase will be on
        its way as soon as it is ready.
        <AffirmPlaceOrder orderId={ orderId }  />
      </div>
    </PaymentMethod>
  ) : null;

  const renderForm = status !== 'processing';
  const renderError = status === 'error' ? <Error error={ error } /> : null;

  const paypalPrompt = renderForm ? (
    paypalOrderText || 'After clicking PayPal, you will be redirected to PayPal to select your payment method.'
  ) : null;

  const payingWith = renderForm && paypalOrderText ? (
    <div>
      <strong>Paying with</strong>
      <img src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" alt="Paypal" className={ classes.paypal } />
    </div>
  ) : null;

  const applePay = (
    renderForm
    && allowApplePay
    && window.ApplePaySession
    && window.ApplePaySession.canMakePayments()
  ) ? (
    <PaymentMethod
      title="Apple Pay"
      method="applepay"
      currentMethod={ method }
      setMethod={ setMethod }
    >
      <div className={ classes.details }>
        <ApplePlaceOrder orderId={ orderId } />
      </div>
    </PaymentMethod>
    ) : null;

  const pp = renderForm ? (
    <PaymentMethod
      title="PayPal"
      method="paypal"
      currentMethod={ method }
      setMethod={ setMethod }
    >
      <div className={ classes.details }>
        { payingWith }
        { paypalPrompt }
        <PaypalPlaceOrder orderId={ orderId } hasPPOrder={ !!paypalOrderText } />
      </div>
    </PaymentMethod>
  ) : null;

  const content = renderForm ? (
    <>
      { renderError }
      <PaymentMethod
        title="Credit Card"
        method="cc"
        currentMethod={ method }
        setMethod={ setMethod }
        onSelected={ doPlaceOrder }
      >
        <div className={ classes.details }>
          Pay with Credit Card.
        </div>
      </PaymentMethod>
      { applePay }
      { pp }
      { affirm }
    </>
  ) : (
    <PleaseWait method={ method } skipRedirectingToPaypal={ !!paypalOrderText } />
  );

  return (
    <div className={ classes.checkout }>
      { content }
    </div>
  );
};

SelectPaymentMethod.propTypes = {
  status: PropTypes.string.isRequired,
  method: PropTypes.string,
  setMethod: PropTypes.func.isRequired,
  placeOrder: PropTypes.func.isRequired,
  allowAffirmCheckout: PropTypes.bool.isRequired,
  allowApplePay: PropTypes.bool.isRequired,
  paypalOrderText: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  error: PropTypes.string
};

SelectPaymentMethod.defaultProps = {
  error: null,
  method: null
};

export default SelectPaymentMethod;
