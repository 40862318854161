import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';
import { uniqCollection, updateCollectionStoreItems } from './utils';

const getDefaultState = () => ({
  loading: false,
  walls: [],
  hasMore: true,
  page: -1
});

const wallsReducer = createReducer(getDefaultState(), {
  [Constants.Walls.FETCH](state) {
    return {
      ...state,
      loading: true
    };
  },

  [Constants.Walls.FETCH_SUCCESS](state, { walls, page, params }) {
    return {
      ...state,
      loading: false,
      walls: uniqCollection(state.walls, walls),
      hasMore: walls.length > 0,
      page,
      params
    };
  },

  [Constants.WallStoreItems.ADDED](state, { wallId, storeItemId }) {
    return {
      ...state,
      walls: updateCollectionStoreItems(state.walls, wallId, storeItemId)
    };
  },

  [Constants.WallStoreItems.REMOVED](state, { wallId }) {
    return {
      ...state,
      walls: updateCollectionStoreItems(state.walls, wallId, null)
    };
  },

  [Constants.Walls.RESET]() {
    return getDefaultState();
  }
});

export default wallsReducer;
