import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({});

const authorOriginalArtReducer = createReducer(getDefaultState(), {
  [Constants.AuthorOriginalArt.FETCH]: (state, { id }) => ({
    ...state,
    [id]: {
      id,
      loading: true,
      userOriginalArt: []
    }
  }),
  [Constants.AuthorOriginalArt.FETCH_SUCCESS]: (state, { id, userOriginalArt }) => ({
    ...state,
    [id]: {
      id,
      userOriginalArt
    }
  })

});

export default authorOriginalArtReducer;
