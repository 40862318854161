import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { artistShape } from 'arts/reducers/artist';
import Loader from 'arts/components/Loader';
import Delimiter from 'arts/components/Delimiter';
import SmallItemsCarousel from '../small_items_carousel';
import { renderText } from '../ArtistWorks/artist_details';

const onMoreOfArtist = () => true;

const MoreArtistWorks = ({
  artistId,
  skipId,
  artist,
  name,
  about,
  loadMoreWorks
}) => {
  useEffect(() => {
    if (artistId) { loadMoreWorks(artistId); }
  }, [artistId]);

  const renderCarousel = () => {
    const { works } = artist;
    const filteredWorks = works.filter(w => w.id !== skipId);
    const title = (
      <>
        More of&nbsp;
        { name }
        &apos;s work
      </>
    );
    return (
      <SmallItemsCarousel
        items={ filteredWorks }
        title={ title }
        onClick={ onMoreOfArtist }
        gtmListName="artist-works"
      />
    );
  };

  const renderArtist = () => {
    if (!about) { return null; }
    return (
      <div>
        { renderCarousel() }
        { renderText('About The Artist', about) }
      </div>
    );
  };

  const content = about ? renderArtist() : <Loader text="Loading artist details..." />;
  return (
    <div>
      <Delimiter />
      { content }
    </div>
  );
};

MoreArtistWorks.propTypes = {
  artistId:   PropTypes.number.isRequired,
  skipId:     PropTypes.number.isRequired,
  artist:     artistShape,
  name:       PropTypes.string.isRequired,
  about:      PropTypes.string,
  loadMoreWorks: PropTypes.func.isRequired
};

export default MoreArtistWorks;
