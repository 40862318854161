import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({
  loading: false,
  error: null,
  works: []
});

const artist = createReducer(getDefaultState(), {
  [Constants.Artist.FETCH](state) {
    return {
      ...state,
      error: null,
      loading: true
    };
  },

  [Constants.Artist.FETCH_FAILURE](state, action) {
    return {
      ...state,
      loading: false,
      error: action.error
    };
  },

  [Constants.Artist.FETCH_SUCCESS](state, action) {
    return {
      ...state,
      works: action.data.works,
      loading: false
    };
  },

  [Constants.Artist.RESET]() {
    return getDefaultState();
  }
});

export default artist;
export * from './proptypes';
