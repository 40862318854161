import React from 'react';
import classnames from 'classnames';
import ReactRouterPropTypes from 'react-router-prop-types';
import AppClasses from '../Application/styles';
import Section from './section';
import Classes from './styles';
import CartItems from '../cart_items';
import CheckoutSummary from '../checkout_summary';
import GTMPageView from '../Application/data_layer';

const ReviewCartItems = ({ history }) => (
  <div className={ AppClasses.container20 }>
    <GTMPageView pageType="cart" section="cart" />
    <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
      <h1>Checkout</h1>
    </div>
    <div className={ AppClasses.gridCheckout }>
      <div className={ AppClasses.left }>
        <Section title="CART ITEMS">
          <div className={ Classes.content }>
            <CartItems removable checkoutStep={ 1 } />
          </div>
        </Section>
      </div>
      <div className={ AppClasses.right }>
        <Section title="SUMMARY OF CHARGES">
          <div className={ Classes.content }>
            <CheckoutSummary checkoutButton history={ history } />
          </div>
        </Section>
      </div>
    </div>
  </div>
);

ReviewCartItems.propTypes = {
  history: ReactRouterPropTypes.history.isRequired
};

export default ReviewCartItems;
