import React from 'react';
import T from 'prop-types';
import { MODAL_LEVEL2_SELECTOR } from 'arts/config/constants';
import Modal from 'arts/components/Modal';

const PreviewModal = ({
  onHide, children, className, contentOptions
}) => (
  <Modal
    selector={ MODAL_LEVEL2_SELECTOR }
    onCancel={ onHide }
    className={ className }
    contentOptions={ contentOptions }
  >
    { children }
  </Modal>
);

PreviewModal.propTypes = {
  onHide: T.func,
  children: T.node,
  className: T.string,
  contentOptions: T.object
};

export default PreviewModal;
