import React from 'react';
import PropTypes from 'prop-types';
import Classes from './styles';
import AppClasses from '../Application/styles';

const Container = React.forwardRef(({ children }, ref) => (
  <div className={ Classes.grid } ref={ ref }>
    <ul className={ AppClasses.gridContainer4inRow }>
      { children }
    </ul>
  </div>
));

Container.propTypes = {
  children: PropTypes.node.isRequired
};

export default Container;
