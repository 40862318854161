import T from 'prop-types';

const imageShape = T.shape({
  imageUrl: T.string,
  thumbUrl: T.string
});
const imagesShape = T.arrayOf(imageShape);

const sectionShape = T.shape({
  id: T.number,
  name: T.string,
  content: T.string
});
const sectionsShape = T.arrayOf(sectionShape);

const userShape = T.shape({
  id: T.number,
  name: T.string
});

const productShape = T.shape({
  id:               T.number,
  description:      T.string,
  retailPrice:      T.string,
  shippingPrice:    T.string,
  title:            T.string,
  images:           imagesShape.isRequired,
  sections:         sectionsShape.isRequired,
  user:             userShape.isRequired
});

export {
  productShape,
  userShape,
  sectionsShape,
  imageShape
};
