/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  createRef, Component
} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Loader } from 'semantic-ui-react';
import classes from './classes';
import AppClasses from '../Application/styles';
import Classes from '../checkout/styles';
import Unsubscribe from '../place_order/unsubscribe';

const DEFAULT_ERRORS = {
  invalidCcNumber: 'Invalid card number',
  invalidCvv: 'Incorrect CVV code',
  invalidExpDate: 'Incorrect expiration date',
  invalidInput: 'Empty or Incorrect value'
};

export default class BlueSnap extends Component {
  ccn = createRef();

  exp = createRef();

  cvv = createRef();

  static propTypes = {
    jsSrc: PropTypes.string.isRequired,
    form: PropTypes.instanceOf(Element).isRequired,
    unsubscribeElement: PropTypes.instanceOf(Element),
    token: PropTypes.string.isRequired,
    use3DS: PropTypes.object
  };

  static defaultProps = {
    use3DS: null,
    unsubscribeElement: null
  };

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      isSubmitting: false,
      errors: {},
      unsubscribe: false
    };
  }

  componentDidMount() {
    const { jsSrc } = this.props;
    if (!window.bluesnap) {
      const el = document.createElement('script');
      el.setAttribute('src', jsSrc);
      el.onload = () => {
        this.bindBluesnap();
      };
      document.body.appendChild(el);
    } else {
      this.bindBluesnap();
    }
  }

  onSetupComplete = () => {
    this.setState({ ready: true });
  };

  onValid = (tagId) => {
    setTimeout(() => {
      const { errors } = this.state;
      const nErrors = { ...errors };
      delete nErrors[tagId];
      delete nErrors.base;
      this.setState({ errors: nErrors });
    }, 0);
  };

  onError = (tagId, errorCode, errorDescription) => {
    setTimeout(() => {
      const { errors } = this.state;
      const nErrors = {
        ...errors,
        [tagId]: DEFAULT_ERRORS[errorDescription] || 'Incorrect payment details'
      };
      this.setState({ isSubmitting: false, errors: nErrors });
    }, 0);
  };

  handleUnsubsribeChange = (event) => {
    const { target } = event;
    this.setState({ unsubscribe: target.checked });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSubmitting: true });
    const { use3DS } = this.props;
    const callback = ({ cardData, error }) => {
      if (cardData) {
        const { unsubscribe } = this.state;
        const { unsubscribeElement, form } = this.props;
        if (unsubscribe && unsubscribeElement) {
          unsubscribeElement.value = 'true';
        }
        form.submit();
        return;
      }
      const { errors } = this.state;
      const nErrors = { ...errors };
      error.forEach(e => {
        if (['cnn', 'exp', 'cvv'].indexOf(e.tagId) > -1) {
          nErrors[e.tagId] = DEFAULT_ERRORS[e.errorDescription] || e.errorDescription;
        } else {
          nErrors.base = nErrors.base || [];
          nErrors.base.push(DEFAULT_ERRORS[e.errorDescription] || e.errorDescription);
        }
      });
      this.setState({ isSubmitting: false, errors: nErrors });
    };
    if (use3DS) {
      use3DS.amount = parseFloat(use3DS.amount);
    }
    window.bluesnap.hostedPaymentFieldsSubmitData(callback, use3DS);
  };

  bindBluesnap() {
    const that = this;
    const { token, use3DS } = this.props;
    const bsObj = {
      ccnPlaceHolder: 'xxxx xxxx xxxx xxxx',
      cvvPlaceHolder: 'xxx',
      expPlaceHolder: 'MM/YY',
      style: {
        input: {
          'font-size': '14px',
          'font-family': 'proxima-nova, sans-serif',
          'line-height': '1.42857143',
          color: '#555'
        }
      },
      onFieldEventHandler: {
        onBlur: () => {},
        onFocus: () => {},
        onType: () => {},
        onValid: that.onValid,
        onError: that.onError,
        setupComplete: that.onSetupComplete
      },
      token
    };
    if (use3DS) { bsObj['3DS'] = true; }
    window.bluesnap.hostedPaymentFieldsCreate(bsObj);
  }

  renderErrors(tagId) {
    const { errors } = this.state;
    return (errors[tagId] ? (
      <div className={ classes.error }>
        { errors[tagId] }
      </div>
    ) : null);
  }

  render() {
    const { use3DS } = this.props;
    const { ready, isSubmitting } = this.state;
    const className = classnames(classes.form, { [classes.hidden]: !ready || isSubmitting });

    const loader = ready ? null : (
      <Loader active inline>
        Connecting...
      </Loader>
    );

    const processing = isSubmitting ? (
      <Loader active inline>
        Processing credit card...
      </Loader>
    ) : null;

    const submitProps = {
      className: classnames(AppClasses.button, Classes.button, classes.button),
      disabled: !ready
    };

    if (use3DS) { submitProps['data-bluesnap'] = 'submitButton'; }

    return (
      <div>
        { loader}
        <form onSubmit={ this.onSubmit } className={ className }>
          { this.renderErrors('base') }
          <label htmlFor="ccn">
            Card Number
          </label>
          <div className={ classes.field } data-bluesnap="ccn" id="ccn" ref={ this.ccn } />
          { this.renderErrors('ccn') }
          <div className={ classes.lastLine }>
            <div className={ classes.subfield }>
              <label htmlFor="exp">
                Expiration Date
              </label>
              <div className={ classnames(classes.field, classes.exp) } data-bluesnap="exp" id="exp" ref={ this.exp } />
              { this.renderErrors('exp') }
            </div>
            <div className={ classes.subfield }>
              <label htmlFor="cvv">
                Security Code
              </label>
              <div className={ classnames(classes.field, classes.cvv) } data-bluesnap="cvv" id="cvv" ref={ this.cvv } />
              { this.renderErrors('cvv') }
            </div>
          </div>
          <button { ...submitProps } type="submit">
            Place Order
          </button>
          <Unsubscribe
            checked={ this.state.unsubscribe }
            onChange={ this.handleUnsubsribeChange }
          />
        </form>
        { processing }
      </div>
    );
  }
}
