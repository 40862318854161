import { connect } from 'react-redux';
import EditOrderPayment from './edit_order_payment';

const mapStateToProps = ({ cartItems }) => {
  const { summaryOfCharges, loading } = cartItems || {};
  return {
    loading,
    ...(summaryOfCharges || {})
  };
};

export default connect(mapStateToProps)(EditOrderPayment);
