import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Classes from '../WorkListItem/styles';
import Item from './item';

const Work = ({
  id,
  name,
  imageUrl,
  path,
  authorName,
  authorId,
  authorSlug,
  remove
}) => {
  const authorLink = `/artists/${ authorId }/${ authorSlug }`;

  const author = (
    <div className={ Classes.author }>
      &nbsp;
      <span>
        By&nbsp;
        <Link to={ authorLink }>
          { authorName }
        </Link>
      </span>
    </div>
  );

  const removeFavorite = () => remove(id, 'Work');

  return (
    <Item
      name={ name }
      path={ path }
      imageUrl={ imageUrl }
    >
      <div className={ Classes.left }>
        <Link to={ path } className={ Classes.art } title={ name }>
          &nbsp;
          <span>
            { name }
          </span>
        </Link>
        { author }
      </div>
      <div className={ Classes.removeFavorite }>
        <button type="button" onClick={ removeFavorite } title="Remove">
          &times;
        </button>
      </div>
    </Item>
  );
};

Work.propTypes = {
  id: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  authorId: PropTypes.number.isRequired,
  authorSlug: PropTypes.string.isRequired
};

export default Work;
