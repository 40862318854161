import React from 'react';

const Zoomin = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    { ...props }
  >
    <g opacity={ 0.6 } stroke="#000" strokeLinecap="round" strokeLinejoin="round">
      <path
        d="m20 20-5-5m2-5a7 7 0 1 1-14 0 7 7 0 0 1 14 0v0Z"
        strokeWidth={ 2 }
      />
      <path d="M20.5 20.5 17 17" strokeWidth={ 3 } />
    </g>
    <path
      d="M10 8v4m2-2H8"
      stroke="#000"
      strokeOpacity={ 0.5 }
      strokeLinecap="round"
    />
  </svg>
);

export default Zoomin;
