import createReducer from 'arts/config/create_reducer';
import Constants from 'arts/config/constants';

const getDefaultState = () => ({});

const wallFilters = createReducer(getDefaultState(), {
  [Constants.Wall.FETCH]: (state, { id, sharingToken }) => ({
    ...state,
    [sharingToken || id]: {
      loading: true,
      id,
      sharingToken
    }
  }),
  [Constants.Wall.FETCH_SUCCESS]: (state, { wall, sharingToken }) => ({
    ...state,
    [sharingToken || wall.id]: wall
  })

});

export default wallFilters;
