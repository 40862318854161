/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Left from 'arts/assets/chevron_left';
import Right from 'arts/assets/chevron_right';
import { MODAL_SELECTOR } from 'arts/config/constants';
import Modal from '../Modal';
import PageClasses from '../works/styles';
import Classes from './styles';

const RearrangeModal = ({
  visible, onCancel
}) => {
  const [page, setPage] = useState(1);

  const setCPage = (e, p) => {
    e.preventDefault();
    setPage(p);
  };

  const prevPage = (e) => {
    e.preventDefault();
    let p = page - 1;
    if (p === 0) {
      p = 1;
    }
    setPage(p);
  };

  const nextPage = (e) => {
    e.preventDefault();
    let p = page + 1;
    if (p === 6) {
      p = 5;
    }
    setPage(p);
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      selector={ MODAL_SELECTOR }
      onCancel={ onCancel }
    >
      <div className={ Classes.raTutorial }>
        <h1>
          REARRANGE WALL TUTORIAL
        </h1>
        <div className={ Classes.raPages }>
          <p>Style this Gallery Wall as you wish, with these few super easy steps!</p>
          <div className={ PageClasses.pHolder }>
            <div className={ classnames(PageClasses.pagination, PageClasses.small) }>
              <div className={ PageClasses.prevNext }>
                <a href="#" onClick={ prevPage } aria-label="Prev">
                  <Left className={ classnames(PageClasses.sign, PageClasses.left) } />
                </a>
              </div>
              <div className={ PageClasses.main }>
                <a href="#" className={ classnames({ [PageClasses.active]: page === 1 }) } onClick={ (e) => setCPage(e, 1) }>1</a>
                <a href="#" className={ classnames({ [PageClasses.active]: page === 2 }) } onClick={ (e) => setCPage(e, 2) }>2</a>
                <a href="#" className={ classnames({ [PageClasses.active]: page === 3 }) } onClick={ (e) => setCPage(e, 3) }>3</a>
                <a href="#" className={ classnames({ [PageClasses.active]: page === 4 }) } onClick={ (e) => setCPage(e, 4) }>4</a>
                <a href="#" className={ classnames({ [PageClasses.active]: page === 5 }) } onClick={ (e) => setCPage(e, 5) }>5</a>
              </div>
              <div className={ PageClasses.prevNext }>
                <a href="#" onClick={ nextPage } aria-label="Next">
                  <Right className={ classnames(PageClasses.sign, PageClasses.right) } />
                </a>
              </div>
            </div>
          </div>
        </div>
        <img src={ `https://assets.artfullywalls.com/assets/rearrange-promo/${ page }.jpg` } alt="About" />
      </div>
    </Modal>
  );
};

RearrangeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default RearrangeModal;
