export const calculatePaperProps = ({
  paperHeight,
  paperWidth,
  printHeight,
  printWidth,
  landscape
}) => {
  let width;
  let height;
  let borderH;
  let borderW;
  const ratio = (100 * paperHeight) / paperWidth;
  const dH = (100 * ((paperHeight - printHeight) / 2)) / paperWidth;
  const dW = (100 * ((paperWidth - printWidth) / 2)) / paperWidth;
  if (landscape) {
    width = '100%';
    height = `${ ratio }%`;
    borderH = dH;
    borderW = dW;
  } else {
    width = `${ ratio }%`;
    height = '100%';
    borderH = dW;
    borderW = dH;
  }
  return {
    width, height, borderH, borderW
  };
};

export const calculateFrameThickness = (width, frameThickness) => (
  (frameThickness / width) * 100
);
