import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import { validateEmail } from '../checkout/guest_checkout';
import Classes from './styles';

const SubscribeForm = ({ onSubmit }) => {
  const initialValues = { email: '' };

  return (
    <div className={ Classes.subscribe }>
      <Formik initialValues={ initialValues } onSubmit={ onSubmit }>
        {
          ({ isSubmitting }) => (
            <Form>
              <Field
                type="email"
                name="email"
                disabled={ isSubmitting }
                validate={ validateEmail }
                placeholder="Enter your email"
              />
              <ErrorMessage name="email" component="div" className={ Classes.error } />
              {
                isSubmitting ? (
                  <Loader active inline />
                ) : (
                  <button type="submit" className={ Classes.button } disabled={ isSubmitting }>
                    Subscribe
                  </button>
                )
              }
            </Form>
          )
        }
      </Formik>
    </div>
  );
};

SubscribeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SubscribeForm;
