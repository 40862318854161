import React from 'react';

const SvgComponent = (props) => (
  <svg viewBox="0 0 24 24" { ...props }>
    <path
      d="M18.36 14.29L13 19.66V0h-2v19.66l-5.36-5.37-1.42 1.42L12 23.49l7.78-7.78z"
    />
  </svg>
);

export default SvgComponent;
