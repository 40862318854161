import React from 'react';

import Section from './section';
import Classes from './styles';

const EmailSection = () => {
  const { title, email } = window.currentOrderEmail;
  if (!title) {
    return null;
  }
  return (
    <Section title={ title }>
      <div className={ Classes.content }>
        <p className={ Classes.title }>
          { email }
        </p>
      </div>
    </Section>
  );
};

export default EmailSection;
