import React from 'react';
import T from 'prop-types';

import Transition from './transition';
import Classes from './styles';

const Collapsible = ({ children, collapsed }) => (
  <section className={ Classes.root }>
    <Transition collapsed={ collapsed }>
      { children }
    </Transition>
  </section>
);

Collapsible.propTypes = {
  children: T.node,
  collapsed: T.bool
};

Collapsible.defaultProps = {
  collapsed: true,
  children: null
};

export default Collapsible;
