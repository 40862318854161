import React from 'react';

const ScaleIcon = (props) => (
  <svg
    viewBox="0 0 108 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    { ...props }
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.876 0H22.112C16.695 0 5.857 3.523 5.857 17.613c0 1.258 0 3.774 10.004 3.774 5.97 0 6.24 6.651 6.252 23.895 0 1.39 1.12 2.524 2.502 2.524H83.386c1.382 0 2.501-1.134 2.502-2.524.012-17.244.282-23.895 6.252-23.895 10.004 0 10.004-2.516 10.004-3.774C102.144 3.523 91.306 0 85.887 0H55.877ZM20.862 64.161c-10.004 0-15.006-5.032-18.757-13.838-4.672-10.97-.93-21.914 5.002-23.904 3.751-1.258 8.753 0 8.753 6.29 0 .361-.01.88-.024 1.517-.077 3.806-.24 11.79 1.275 14.839 1.25 2.516 4.585 2.516 5.002 2.516h63.774c.417 0 3.752 0 5.002-2.517 1.515-3.048 1.352-11.032 1.275-14.838a86.397 86.397 0 0 1-.024-1.516c0-6.29 5.002-7.549 8.753-6.29 5.932 1.989 9.674 12.932 5.002 23.903-3.751 8.806-10.004 13.838-18.757 13.838 0 1.678 2.5 5.033 6.252 6.29 3.752 1.259 5.002 6.29 2.501 7.549h-7.503L77.134 64.161H30.866L19.612 78h-7.503c-2.501-1.258-1.25-6.29 2.5-7.548 3.752-1.258 6.253-4.613 6.253-6.29Z"
    />
  </svg>
);

export default ScaleIcon;
