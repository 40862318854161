import React from 'react';

export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={ 28 }
    height={ 28 }
    viewBox="0 0 28 28"
    fill="none"
    { ...props }
  >
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={ 2 }
      d="M18.912 18.912 21.5 21.5 27 27m-5.102-15.551a10.45 10.45 0 1 1-20.898 0 10.45 10.45 0 0 1 20.898 0Z"
    />
  </svg>
);
