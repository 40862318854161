import { connect } from 'react-redux';
import {
  setFramePreview,
  setOtherImage
} from 'arts/actions/selection';
import Viewer from './viewer';

const mapStateToProps = ({ selection, work: { data, thumbnails } }) => ({
  selectedFrame: selection.frame,
  selectedPaper: selection.paper,
  otherImageUrl: selection.otherImageUrl,
  hasSelection: Boolean(selection.frame && selection.paper),
  data,
  thumbnails
});

const mapDispatchToProps = (dispatch) => ({
  setFramePreview: (paper, frame) => dispatch(setFramePreview(paper, frame)),
  setOtherImage: (url) => dispatch(setOtherImage(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
