import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useComponentSize } from '../../config/use_component_size';
import Classes from './styles';

const { dotitemsize, dotcontainerpadding } = Classes;

const Dots = ({ count, activeIndex }) => {
  const [ref, size] = useComponentSize();
  const [offset, setOffset] = useState(0);
  const { width } = size;

  const activeWidth = width - 2 * parseInt(dotcontainerpadding, 10);
  const visibleItemsCount = Math.min(Math.floor(activeWidth / parseInt(dotitemsize, 10)), count);

  useEffect(() => {
    if (visibleItemsCount < count) {
      if (activeIndex <= offset) {
        setOffset(Math.max(activeIndex - 1, 0));
      } else if (activeIndex >= offset + visibleItemsCount - 1) {
        if (activeIndex === count - 1) {
          setOffset(activeIndex - visibleItemsCount + 1);
        } else {
          setOffset(activeIndex - visibleItemsCount + 2);
        }
      }
    }
  }, [activeIndex, count, visibleItemsCount]);

  /*
   optional small dot
   optional dots
   active index
   optional dots
   optional small dot
   [O O O A o], visibleItemsCount = 5, activeIndex = 3
   [o O O A o]
  */

  const firstSmall = offset > 0;
  const lastSmall = offset + visibleItemsCount < count;
  const dots = Array.from({ length: visibleItemsCount }, (v, index) => {
    if (index === activeIndex - offset) { return 'A'; }
    if (index === 0 && firstSmall) { return 'o'; }
    if (index === visibleItemsCount - 1 && lastSmall) { return 'o'; }
    return 'O';
  });

  return (
    <div ref={ ref } className={ Classes.dots }>
      {
        dots.map((dot, index) => {
          let className;
          if (dot === 'A') {
            className = Classes.active;
          }
          if (dot === 'o') {
            className = Classes.small;
          }
          return (
            <div key={ index } className={ className } />
          );
        })
      }
    </div>
  );
};

Dots.propTypes = {
  count: PropTypes.number.isRequired,
  activeIndex: PropTypes.number.isRequired
};

export default Dots;
