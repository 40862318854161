import { connect } from 'react-redux';
import { fetchWork } from 'arts/actions/work';
import Work from './work';

const findWorkDetails = (works, id, detail) => (
  detail || ((works || {}).collection || []).find(w => w.id === id)
);

const mapStateToProps = ({ selection, works, work: { data, error, loading } }, { id, detail }) => ({
  data,
  error,
  loading,
  selectedFrame: (selection || {}).frame,
  selectedPaper: (selection || {}).paper,
  otherImageUrl: (selection || {}).otherImageUrl,
  detail: findWorkDetails(works, id, detail)
});

const mapDispatchToProps = (dispatch) => ({
  fetchWork: (id, detail) => { dispatch(fetchWork(id, detail)); }
});

export default connect(mapStateToProps, mapDispatchToProps)(Work);
