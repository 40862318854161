import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import T from 'prop-types';
import numeral from 'arts/config/numeral';
import { FORMAT } from '../checkout_summary/checkout_summary';

import Classes from './styles';

class Info extends PureComponent {
  static propTypes = {
    title: T.string.isRequired,
    authorName: T.string.isRequired,
    price: T.string.isRequired,
    id: T.number.isRequired,
    isHidden: T.bool,
    productLink: T.string.isRequired,
    addProductToCart: T.func.isRequired
  };

  static defaultProps = {
    isHidden: false
  }

  constructor() {
    super();

    this.doAddToCart = this.doAddToCart.bind(this);
  }

  doAddToCart() {
    const { addProductToCart, id } = this.props;
    addProductToCart({ productId: id });
  }

  renderQuickShop() {
    return (
      <button
        type="button"
        className={ Classes.quickShop }
        onClick={ this.doAddToCart }
      >
        Add to Cart
      </button>
    );
  }

  renderPrices() {
    const {
      price
    } = this.props;

    if (!price) {
      return null;
    }

    return (
      <div className={ Classes.prices }>
        { numeral(price).format(FORMAT) }
      </div>
    );
  }

  render() {
    const {
      title,
      isHidden,
      productLink
    } = this.props;

    const hiddenIcon = isHidden ? <i className="icon-eye-close" /> : null;

    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <div className={ Classes.info }>
        <div className={ Classes.left }>
          <Link className={ Classes.workLink } to={ productLink }>
            { hiddenIcon }
            { title }
          </Link>
        </div>
        { this.renderQuickShop() }
        { this.renderPrices() }
      </div>
    );
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

export default Info;
