import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Classes from '../WorkListItem/styles';
import Info from '../WorkListItem/info';
import SvgO from './o';

const toInfoProps = (
  name, path, isAvailable, props
) => ({
  title: (
    <div className={ Classes.originalArt }>
      <SvgO className={ Classes.o } />
      <Link to={ path } className={ Classes.art } title={ name }>
        &nbsp;
        <span>
          { name }
        </span>
      </Link>
    </div>
  ),
  authorName: props.user.name,
  href: path,
  authorSlug: props.authorSlug,
  authorId: props.user.id,
  prices: isAvailable ? [props.price] : null,
  skipQuickShop: true,
  name
});

const renderSoldOut = () => (
  <div className={ Classes.sold }>
    <span>SOLD OUT</span>
  </div>
);

const Item = ({
  id, name, path, images, isAvailable, position, onClick, ...props
}) => {
  const onItemClick = () => onClick({ id, name, ...props }, position);

  return (
    <li className={ classnames(Classes.root, `originalArt-${ id }`) }>
      { isAvailable ? null : renderSoldOut() }
      <div className={ Classes.anchor }>
        <Link to={ path } className={ Classes.keeper } title={ name } onClick={ onItemClick }>
          <img
            alt={ name }
            src={ images[0].imageUrl }
            className={ Classes.image }
          />
        </Link>
      </div>
      <Info { ...toInfoProps(name, path, isAvailable, props) } />
    </li>
  );
};

Item.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    imageUrl: PropTypes.string.isRequired
  })).isRequired,
  isAvailable: PropTypes.bool.isRequired,
  position: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Item;
