import Constants from 'arts/config/constants';
import { addToStoreItemsApi, removeFromStoreItemsApi } from 'arts/config/api';
import { updateUI } from './wall_store_items';

export const add = (opts) => (dispatch) => {
  addToStoreItemsApi(opts)
    .then(
      (data) => {
        if (!data) { return; }
        const { id, storableId, totalItems } = data;
        dispatch({
          storeItemId: id,
          workId: storableId,
          type: Constants.WorkStoreItems.ADDED
        });
        updateUI(totalItems);
      }
    ).catch(() => {});
};

export const remove = (workId, storeItemId) => (dispatch) => {
  removeFromStoreItemsApi(storeItemId)
    .then(
      (data) => {
        if (!data) { return; }
        const { totalItems } = data;
        dispatch({
          workId,
          type: Constants.WorkStoreItems.REMOVED
        });
        updateUI(totalItems);
      }
    ).catch(() => {});
};
