import Constants from 'arts/config/constants';
import { loadMyWallsApi, deleteMyWallApi } from 'arts/config/api';

export const loadMyWalls = (page) => (dispatch) => {
  dispatch({
    type: Constants.MyWalls.FETCH
  });

  loadMyWallsApi(page)
    .then((walls) => dispatch({
      type: Constants.MyWalls.FETCH_SUCCESS,
      walls,
      page
    }));
};

export const deleteMyWall = (id) => (dispatch) => {
  dispatch({
    type: Constants.MyWalls.DELETE,
    id
  });
  deleteMyWallApi(id).then(() => {
    dispatch({
      type: Constants.MyWalls.DELETE_SUCCESS,
      id
    });
  }).catch(() => {
    dispatch({
      type: Constants.MyWalls.DELETE_FAILURE,
      id
    });
  });
};
