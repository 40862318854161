/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OriginalArts from '../original_arts/original_arts';
import Classes from './styles';
import { onOriginalArtItems } from '../../config/analytics';
import AppClasses from '../Application/styles';

const OriginalArtSection = ({
  name,
  collection,
  getListName,
  gtmPageType
}) => {
  useEffect(() => {
    onOriginalArtItems(collection, gtmPageType, getListName);
  }, []);

  return (
    <OriginalArts
      collection={ collection }
      hasMore={ false }
      loading={ false }
      skipBanner
    >
      <div className={ AppClasses.container20 }>
        <div className={ AppClasses.innerContainer20 }>
          <div className={ Classes.artistWorks }>
            {
              name ? (
                <>
                  { name }&rsquo;s&nbsp;
                </>
              ) : null
            }
            Original Art
          </div>
        </div>
      </div>
    </OriginalArts>
  );
};

OriginalArtSection.propTypes = {
  name: PropTypes.string,
  collection: PropTypes.array.isRequired,
  getListName: PropTypes.string.isRequired,
  gtmPageType: PropTypes.string.isRequired
};

OriginalArtSection.defaultProps = {
  name: null
};

export default OriginalArtSection;
