import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Left from 'arts/assets/chevron_left';
import Right from 'arts/assets/chevron_right';
import { buildPath } from './filter';
import Classes from './styles';

const buildGroup = (page, totalPages) => {
  const group = [];
  if (page > 2) { group.push(page - 1); }
  group.push(page);
  if (page + 1 < totalPages) {
    group.push(page + 1);
  }
  return group;
};

const linkTo = (path, params, page, activePage, label = null) => ({
  path: buildPath(path, { ...params, page }),
  isActive: activePage === page,
  label: label || page
});

const Element = ({
  label, path, isActive, history
}) => {
  if (!path) {
    return (
      <span>
        &hellip;
      </span>
    );
  }

  const onClick = (e) => {
    e.preventDefault();
    history.push(path);
  };

  return (
    <a href={ path } onClick={ onClick } className={ classnames({ [Classes.active]: isActive }) }>
      { label }
    </a>
  );
};

Element.propTypes = {
  label: PropTypes.any,
  path: PropTypes.string,
  isActive: PropTypes.bool,
  history: PropTypes.object.isRequired
};

Element.defaultProps = { label: null, isActive: null, path: null };

const buildElements = (path, params, page, totalPages) => {
  const elements = [];
  let firstEl = null;
  let lastEl = null;
  const group = buildGroup(page, totalPages);
  if (page !== 1) {
    firstEl = linkTo(path, params, page - 1, null, (
      <Left className={ classnames(Classes.sign, Classes.left) } />
    ));
  }
  if (group.indexOf(1) === -1) {
    elements.push(linkTo(path, params, 1, page, <span className={ Classes.text }>{ 1 }</span>));
  }
  const first = group[0];
  const last = group[group.length - 1];
  if (first === 3) {
    elements.push(linkTo(path, params, 2, page, <span className={ Classes.text }>{ 2 }</span>));
  }
  if (first > 3) {
    elements.push(null);
  }
  group.forEach(p => elements.push(linkTo(
    path, params, p, page, <span className={ Classes.text }>{ p }</span>
  )));
  if (last + 2 < totalPages) {
    elements.push(null);
  } else if (last + 2 === totalPages) {
    elements.push(linkTo(
      path, params, last + 1, page, <span className={ Classes.text }>{ last + 1 }</span>
    ));
  }
  if (group.indexOf(totalPages) === -1) {
    elements.push(linkTo(
      path, params, totalPages, page, <span className={ Classes.text }>{ totalPages }</span>
    ));
  }
  if (page < totalPages) {
    lastEl = linkTo(path, params, page + 1, null, (
      <Right className={ classnames(Classes.sign, Classes.right) } />
    ));
  }
  return [firstEl, elements, lastEl];
};

const Paginator = ({
  path, params, page, totalPages, history, className
}) => {
  if (totalPages < 2) { return null; }

  const [first, elements, last] = buildElements(path, params, page, totalPages);
  return (
    <div className={ Classes.pHolder }>
      <div className={ classnames(Classes.pagination, className) }>
        <div className={ Classes.prevNext }>
          { first ? <Element { ...first } history={ history } /> : null }
        </div>
        <div className={ Classes.main }>
          {
            elements.map(
              (e, i) => <Element { ...e } key={ i } history={ history } />
            )
          }
        </div>
        <div className={ Classes.prevNext }>
          { last ? <Element { ...last } history={ history } /> : null }
        </div>
      </div>
    </div>
  );
};

Paginator.propTypes = {
  path: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  className: PropTypes.string
};

Paginator.defaultProps = {
  className: null
};

export default Paginator;
