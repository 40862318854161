import Constants from 'arts/config/constants';
import { getArtist } from 'arts/config/api';

const fetchArtistStart = () => ({
  type: Constants.Artist.FETCH
});

const fetchArtistSuccess = (data) => ({
  type: Constants.Artist.FETCH_SUCCESS,
  data
});

const fetchArtistFailure = (error) => ({
  type: Constants.Artist.FETCH_FAILURE,
  error
});

export const fetchArtist = (id) => (dispatch) => {
  dispatch(fetchArtistStart());

  getArtist(id).then(
    (data) => dispatch(fetchArtistSuccess(data)),
    (error) => dispatch(fetchArtistFailure(error))
  );
};
