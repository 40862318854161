import React, { useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import Loader from 'arts/components/Loader';
import NoResults from '../walls_as_grid/no_results';
import WallsList from '../walls/list';

// this is only loader and redirector

const WallsAsPage = ({
  walls,
  loading,
  fetchWalls,
  hasMore,
  page,
  activeFilters,
  wallFilters,
  fetchWallFilters,
  history,
  resetWalls
}) => {
  const canFetch = () => !loading && hasMore;

  const loadNextPage = () => {
    if (!canFetch()) { return; }
    fetchWalls(page + 1, activeFilters);
  };

  useEffect(() => {
    if (!walls.length) {
      loadNextPage();
    }
  }, []);

  if (walls.length) {
    const index = 0;
    const to = `${ walls[index].href }?${ queryString.stringify(activeFilters) }`;
    return <Redirect to={ to } />;
  }

  return (
    <WallsList
      fetchWallFilters={ fetchWallFilters }
      resetWalls={ resetWalls }
      wallFilters={ wallFilters }
      activeFilters={ activeFilters }
      history={ history }
      loadNextPage={ loadNextPage }
    >
      {
        loading
          ? <Loader text="Loading more items, hold on for a sec..." />
          : null
      }
      <NoResults loading={ loading } walls={ walls } />
    </WallsList>
  );
};

WallsAsPage.propTypes = {
  fetchWalls: PropTypes.func.isRequired,
  fetchWallFilters: PropTypes.func.isRequired,
  resetWalls: PropTypes.func.isRequired,
  // walls
  loading: PropTypes.bool.isRequired,
  walls: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  // filters
  wallFilters: PropTypes.arrayOf(PropTypes.any),
  activeFilters: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired
};

WallsAsPage.defaultProps = {
  wallFilters: null
};

export default WallsAsPage;
