import Constants from 'arts/config/constants';
import { loadWallsApi, loadWallFiltersApi } from 'arts/config/api';

export const fetchWalls = (page, params) => (dispatch) => {
  dispatch({
    type: Constants.Walls.FETCH,
    page
  });

  loadWallsApi(page, params)
    .then((walls) => dispatch({
      type: Constants.Walls.FETCH_SUCCESS,
      walls,
      page,
      params
    })).catch(() => {});
};

export const resetWalls = () => ({
  type: Constants.Walls.RESET
});

export const fetchWallFilters = () => (dispatch) => {
  loadWallFiltersApi()
    .then(
      (filters) => dispatch({
        type: Constants.WallFilters.FETCH_SUCCESS,
        filters
      })
    ).catch(() => {});
};
