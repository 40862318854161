import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Classes from './styles';
import WorkClasses from '../WorkListItem/styles';

const Artist = ({
  id,
  name,
  path,
  imageUrl,
  remove
}) => {
  const removeFavorite = () => remove(id, 'User');

  const style = { backgroundImage: `url("${ imageUrl }")` };
  const img = imageUrl ? (
    <div className={ Classes.img } style={ style } />
  ) : null;

  return (
    <li className={ Classes.artist }>
      <div className={ Classes.holder }>
        { img }
        <Link to={ path } title={ name }>
          &nbsp;
          <span>
            { name }
          </span>
        </Link>
        <div className={ WorkClasses.removeFavorite }>
          <button type="button" onClick={ removeFavorite } title="Remove">
            &times;
          </button>
        </div>
      </div>
    </li>
  );
};

Artist.propTypes = {
  id: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired
};

Artist.defaultProps = {
  imageUrl: null
};

export default Artist;
