import React from 'react';
import T from 'prop-types';
import PreviewModal from './preview_modal';
import Classes from './styles';
import ModalClasses from '../Modal/styles';

const silhouette = require('./silhouette-2.png');

const sizeX = 234.4;
const sizeY = 139.95;

const calculateStyle = (width, height) => ({
  width: `${ (100 * width / sizeX) }%`,
  height: `${ (100 * height / sizeY) }%`,
  position: 'absolute',
  marginLeft: `-${ (50 * width / sizeX) }%`,
  marginTop: `-${ (50 * height / sizeY) }%`,
  left: '50%',
  top: '50%'
});

const ScaleModal = ({
  img, onHide, previewWidth2, previewHeight2
}) => (
  <PreviewModal onHide={ onHide } className={ ModalClasses.scale }>
    <div className={ Classes.silhouette } style={ { backgroundImage: `url(${ silhouette })` } }>
      <img src={ img } alt="" style={ calculateStyle(previewWidth2, previewHeight2) } />
    </div>
  </PreviewModal>
);

ScaleModal.propTypes = {
  img: T.string,
  onHide: T.func,
  previewWidth2: T.number,
  previewHeight2: T.number
};

export default ScaleModal;
