export const humanSize = (landscape, w, h, joinWith) => {
  let sizes = [w, h];
  if (landscape && w < h) {
    sizes = [h, w];
  } else if (!landscape && w > h) {
    sizes = [h, w];
  }
  return sizes.join(joinWith);
};

export const linkTo = (href) => {
  const hostname = `${ window.location.hostname }/`;
  const pos = (href || '').indexOf(hostname);
  if (pos === -1) {
    return href;
  }
  return href.substr(pos + hostname.length - 1);
};

// https://gist.github.com/felipenmoura/650e7e1292c1e7638bcf6c9f9aeb9dd5

export const scrollPageTo = (to, duration = 500) => {
  // t = current time
  // b = start value
  // c = change in value
  // d = duration
  const easeInOutQuad = (t, b, c, d) => {
    // eslint-disable-next-line no-param-reassign
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    // eslint-disable-next-line no-param-reassign
    t -= 1;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  return new Promise((resolve, reject) => {
    const element = document.scrollingElement;

    if (typeof to === 'string') {
      // eslint-disable-next-line no-param-reassign
      to = document.querySelector(to) || reject();
    }
    if (typeof to !== 'number') {
      // eslint-disable-next-line no-param-reassign
      to = to.getBoundingClientRect().top + element.scrollTop;
    }

    const start = element.scrollTop,
      change = to - start,
      increment = 50;
    let currentTime = 0;

    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      } else {
        resolve();
      }
    };
    animateScroll();
  });
};

export const wallPictureToPreview = (wp) => {
  const {
    workId,
    wallPictureId,
    landscape,
    isAvailable,
    title,
    workSizes,
    workHref,
    authorHref,
    authorName,
    prices, // - we will take it as is
    workSizeId,
    frameId,

    // all these is a part of Paper - we will take it as is
    paperId,
    paperHeight,
    paperWidth,
    printHeight,
    printWidth,
    paperMaterial,
    displayPaperWidth,
    displayPaperHeight,
    displayPrintWidth,
    displayPrintHeight,

    zoomPreviewUrl,
    noFramePreviewUrl,

    frameFinalFramedSize
  } = wp;

  const frameOption = (
    ((workSizes || [])[0] || {}).options || {}
  )[0] || {};

  return {
    workId,
    wallPictureId,
    landscape,
    isAvailable,
    title,
    frameOption,
    workHref,
    authorHref,
    authorName,
    prices,
    workSizeId,
    frameId,
    paperId,
    paperHeight,
    paperWidth,
    printHeight,
    printWidth,
    zoomPreviewUrl,
    noFramePreviewUrl,
    paperMaterial,
    frameFinalFramedSize,
    displayPaperWidth,
    displayPaperHeight,
    displayPrintWidth,
    displayPrintHeight
  };
};
