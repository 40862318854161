import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const VisitManager = ({ children }) => {
  const location = useLocation();
  const [visited, setVisited] = useState(false);
  const [visitsCount, setVisitsCount] = useState(parseInt(Cookies.get('vn') || 0, 10));

  useEffect(() => {
    if (visited) {
      Cookies.set('vn', visitsCount + 1, { expires: 3650, path: '/' });
      setVisitsCount(visitsCount + 1);
    }
    setVisited(true);
  }, [location]);

  const { pathname } = location;
  return children({ visitsCount, pathname });
};

VisitManager.propTypes = {
  children: PropTypes.func.isRequired
};

export default VisitManager;
