import { connect } from 'react-redux';
import {
  fetchOriginalArt
} from 'arts/actions/original_arts';
import OriginalArt from './original_art';

const mapStateToProps = ({ originalArt }, { match: { params: { id } } }) => {
  const oaId = parseInt(id, 10);
  return {
    id: oaId,
    ...originalArt[oaId]
  };
};

export default connect(mapStateToProps, {
  fetchOriginalArt
})(OriginalArt);
