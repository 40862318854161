import React from 'react';
import T from 'prop-types';
import Classes from './styles';

const Entry = ({ label, value }) => (
  <div className={ Classes.entry }>
    <div className={ Classes.label }>
      { label }
      :
    </div>
    <div className={ Classes.value }>
      { value }
    </div>
  </div>
);

Entry.propTypes = {
  label: T.string,
  value: T.string
};

export default Entry;
