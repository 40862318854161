/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import numeral from 'arts/config/numeral';
import { FORMAT } from '../checkout_summary/checkout_summary';
import Classes from '../checkout/styles';

const RemoveGiftCard = ({
  id,
  cardNumber,
  amount,
  balance
}) => {
  const [isRemoving, setRemoving] = useState(false);

  if (isRemoving) {
    return (
      <Loader active inline />
    );
  }

  const onRemove = () => {
    document.querySelector('#remove_gift_card_usage input[name=gift_card_usage_id]').value = id;
    document.getElementById('remove_gift_card_usage').submit();
    setRemoving(true);
  };

  numeral.locale(window.currentLocale.currentLocale);

  return (
    <div className={ Classes.content }>
      <p>
        <strong>
          Gift Card #&nbsp;
          { cardNumber }
        </strong>
      </p>
      <p>
        Amount Applied:&nbsp;
        { numeral(amount).format(FORMAT) }
      </p>
      <p>
        Remaining Balance:&nbsp;
        { numeral(balance).format(FORMAT) }
      </p>
      <button type="button" className={ Classes.actionLink } onClick={ onRemove }>
        Remove Card
      </button>
    </div>
  );
};

RemoveGiftCard.propTypes = {
  id: PropTypes.number.isRequired,
  cardNumber: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  balance: PropTypes.string.isRequired
};

export default RemoveGiftCard;
