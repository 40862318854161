/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import AppClasses from '../Application/styles';
import Classes from '../checkout/styles';

const PromoCode = ({
  appliedPromoCode,
  promoCodeEligible
}) => {
  if (appliedPromoCode) {
    return (
      <div className={ Classes.content }>
        Promo Code (applied):&nbsp;
        <strong>
          { appliedPromoCode }
        </strong>
      </div>
    );
  }
  if (!promoCodeEligible) {
    return null;
  }

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    document.querySelector('#apply_promo_code input[type=text]').value = values.promoCode;
    document.getElementById('apply_promo_code').submit();
  };

  const { code, error } = window.promo || {};

  return (
    <div className={ Classes.content }>
      <Formik
        initialValues={ { promoCode: code } }
        onSubmit={ onSubmit }
      >
        {
          ({
            isSubmitting
          }) => (
            <Form className={ Classes.genericForm }>
              <div className={ Classes.row } role="group">
                <div className={ Classes.field }>
                  <label htmlFor="promoCode">
                    Promo Code
                  </label>
                  <Field id="firstName" type="text" name="promoCode" disabled={ isSubmitting } />
                  <ErrorMessage name="promoCode" component="div" className={ Classes.error } />
                  {
                    error ? (
                      <div className={ Classes.error }>
                        { error }
                      </div>
                    ) : null
                  }
                </div>
                <div className={ Classes.field }>
                  <label>&nbsp;</label>
                  {
                    isSubmitting ? (
                      <Loader active inline />
                    ) : (
                      <button type="submit" className={ classnames(AppClasses.button, Classes.applyButton) }>
                        APPLY
                      </button>
                    )
                  }
                </div>
              </div>
            </Form>
          )
        }
      </Formik>
    </div>
  );
};

PromoCode.propTypes = {
  appliedPromoCode: PropTypes.string,
  promoCodeEligible: PropTypes.bool
};

export default PromoCode;
