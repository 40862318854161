import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Classes from './styles';

const Subtitle = ({ text, noCenter }) => (
  <>
    <div className={ classnames(Classes.description, { [Classes.noCenter]: noCenter }) }>
      <ReactMarkdown>
        { text }
      </ReactMarkdown>
    </div>
    <div className={ Classes.divider } />
  </>
);

Subtitle.propTypes = {
  text: PropTypes.string,
  noCenter: PropTypes.bool
};

export default Subtitle;
