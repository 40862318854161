/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import Classes from './styles';

const Incompatible = ({ incompatible, id }) => {
  if (!incompatible) { return null; }
  const href = `mailto:support@artfullywalls.com?subject=Trouble Processing Order ${ id }`;
  return (
    <div className={ Classes.incompatible }>
      We are having trouble processing your order at this time, and apologize for the trouble.
      Please reach out to&nbsp;
      <a href={ href } target="_blank">support@artfullywalls.com</a>
      &nbsp;for help to resolve this issue.
    </div>
  );
};

Incompatible.propTypes = {
  incompatible: PropTypes.bool,
  id: PropTypes.string.isRequired
};

export default Incompatible;
