import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Expandable from 'arts/components/expandable';
import PinIt from 'arts/components/Social/pin_it';
import SummaryClasses from '../WorkSummary/styles';
import AddToCart from '../AddToCart/smart_add_to_cart';

import Classes from './styles';

const renderText = (text) => (
  <div className={ Classes.text }>
    {
      text.split(/\n/).map((line, index) => (
        <p key={ index }>
          { line }
        </p>
      ))
    }
  </div>
);

const renderSection = (section, index) => {
  const lines = section.split(/\n/);
  const title = lines.shift();
  const text = lines.join('\n');
  return (
    <Expandable title={ title } eKey={ `s-${ index }` } key={ index }>
      { renderText(text) }
    </Expandable>
  );
};

const renderSections = (sections) => sections.replace(/\r\n/g, '\n').split(/\n\n/).map(renderSection);

const SideBar = ({
  id, name, user, authorSlug, price, details, sections, isAvailable, href, imageUrl
}) => (
  <>
    <div className={ SummaryClasses.inner }>
      <div className={ Classes.original }>
        <Link to="/original_arts">
          One-Of-A-Kind
        </Link>
      </div>
      <div className={ SummaryClasses.title }>
        <p className={ Classes.heading }>
          { name }
        </p>
        <div className={ SummaryClasses.controls }>
          <PinIt
            href={ `${ window.location.protocol }//${ window.location.host }${ href }` }
            imageUrl={ imageUrl }
            description={ `${ name } on Artfully Walls` }
          />
        </div>
      </div>
      <p className={ SummaryClasses.author }>
        by&nbsp;
        <Link
          className={ SummaryClasses.name }
          to={ `/artists/${ user.id }/${ authorSlug }` }
        >
          { user.name }
        </Link>
      </p>
      { renderText(details) }
      <div>
        <div className={ Classes.noDiscount }>
          - Promo codes and trade accounts are not valid on original art purchases.
        </div>
      </div>
    </div>
    <AddToCart
      price={ Number(price) }
      currency="$"
      inStock={ isAvailable }
      selection
      originalArtId={ id }
      AddToCart={ false }
    />
    { renderSections(sections) }
  </>
);

SideBar.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  authorSlug: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  sections: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  href: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired
};

export default SideBar;
