import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import SubscribePopup from '../subscribe_to_newsletter/subscribe_popup';

const FIRST_SHOWN = 'npsf';
const SECOND_SHOWN = 'npss';
const SHOW_SECOND_AT = 5;

const CHECKOUT_PATHS = [
  /\/promo$/,
  /\/cart_items$/,
  /\/my\/order$/,
  /\/ref\//,
  /\/my\/address\/edit$/,
  /\/orders\/\d+\/edit$/,
  /\/orders\/\d+\/payment\/new$/,
  /\/orders\/\d+\/payment\/edit$/,
  /\/orders\/\d+\/payment$/,
  /\/orders\/\d+\/thankyou$/
];

const isCheckoutFlow = (pathname) => {
  let pos = 0;
  const cnt = CHECKOUT_PATHS.length;
  while (pos < cnt) {
    if (CHECKOUT_PATHS[pos].test(pathname)) {
      return true;
    }
    pos += 1;
  }
  return false;
};

const SubscribePopupCounters = ({
  visitsCount, pathname, allowMailSubscription, disallow
}) => {
  if (!allowMailSubscription) { return null; }
  const [doShow, setShow] = useState(false);

  const hidePopup = () => setShow(false);

  useEffect(() => {
    if (isCheckoutFlow(pathname)) { return; }
    const firstShownAt = parseInt(Cookies.get(FIRST_SHOWN) || 0, 10);
    if (!firstShownAt) {
      if (pathname === '/' || visitsCount > 2) {
        Cookies.set(FIRST_SHOWN, visitsCount, { expires: 3650, path: '/' });
        setShow(true);
      }
    } else {
      const secondShownAt = parseInt(Cookies.get(SECOND_SHOWN) || 0, 10);
      if (!secondShownAt && visitsCount >= firstShownAt + SHOW_SECOND_AT - 1) {
        Cookies.set(SECOND_SHOWN, visitsCount, { expires: 3650, path: '/' });
        setShow(true);
      }
    }
  }, [visitsCount]);

  if (!doShow) {
    return null;
  }

  return (
    <SubscribePopup
      hidePopup={ hidePopup }
      disallow={ disallow }
      visitsCount={ visitsCount }
    />
  );
};

SubscribePopupCounters.propTypes = {
  visitsCount: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
  allowMailSubscription: PropTypes.bool.isRequired,
  disallow: PropTypes.func.isRequired
};

export default SubscribePopupCounters;
