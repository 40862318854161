import Constants from 'arts/config/constants';

export const resetOtherImage = () => ({
  type: Constants.Selection.RESET_OTHER
});

export const setFrame = (paper, frame) => ({
  type: Constants.Selection.SET_FRAME,
  paper,
  frame
});

export const setOtherImage = (url) => ({
  type: Constants.Selection.SET_OTHER,
  url
});

export const resetSelection = () => ({
  type: Constants.Selection.RESET
});

export const setNonGlare = (nonGlare) => ({
  type: Constants.Selection.SET_NON_GLARE,
  nonGlare
});

export const setFramePreview = (paper, frame) => (dispatch) => {
  dispatch(setFrame(paper, frame));
  dispatch(resetOtherImage());
};
