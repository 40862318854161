/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import {
  Formik, Form, Field, ErrorMessage
} from 'formik';
import AppClasses from '../Application/styles';
import Classes from '../checkout/styles';

const MAX_CHARS = 150;

const doSaveGiftMessage = (value) => {
  document.querySelector('#add_gift_message input[name=gift_message]').value = value;
  document.getElementById('add_gift_message').submit();
};

export const doRemove = () => doSaveGiftMessage('-');

const AddGiftMessage = ({ message, error }) => {
  const [isRemoving, setRemoving] = useState(false);
  const [messageLength, setMessageLength] = useState((message || '').length);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    doSaveGiftMessage(values.message || '-');
  };

  const onRemove = () => {
    setRemoving(true);
    doRemove();
  };

  const validate = (values) => {
    const errors = {};
    if (values.message && values.message.length > MAX_CHARS)  {
      errors.message = `can be up to ${ MAX_CHARS } characters`;
    }
    setMessageLength(values.message.length);
    return errors;
  };

  const charsLeft = (messageLength > MAX_CHARS) ? null : (
    `${ MAX_CHARS - messageLength } chararctes left`
  );

  const initialValues = { message };

  return (
    <Formik
      initialValues={ initialValues }
      validate={ validate }
      onSubmit={ onSubmit }
      validateOnChange
    >
      {
        ({
          isSubmitting
        }) => (
          <Form className={ classnames(Classes.genericForm, Classes.giftMessage) }>
            <div className={ Classes.row } role="group">
              <div className={ Classes.field }>
                <label htmlFor="code">
                  Gift Message
                </label>
                <Field
                  id="message"
                  as="textarea"
                  name="message"
                  disabled={ isSubmitting }
                  rows={ 6 }
                />
                {
                  isSubmitting || isRemoving ? null : (
                    <div className={ Classes.footer }>
                      <button type="button" className={ Classes.actionLink } onClick={ onRemove }>
                        Remove
                      </button>
                      <span>
                        { charsLeft }
                      </span>
                    </div>
                  )
                }
                <ErrorMessage name="message" component="div" className={ Classes.error } />
                {
                  error ? (
                    <div className={ Classes.error }>
                      { error }
                    </div>
                  ) : null
                }
              </div>
              <div className={ Classes.field }>
                <label>&nbsp;</label>
                {
                  isSubmitting || isRemoving ? (
                    <Loader active inline />
                  ) : (
                    <button type="submit" className={ classnames(AppClasses.button, Classes.applyButton) }>
                      SAVE
                    </button>
                  )
                }
              </div>
            </div>

          </Form>
        )
      }
    </Formik>
  );
};

AddGiftMessage.propTypes = {
  message: PropTypes.string,
  error: PropTypes.string
};

export default AddGiftMessage;
