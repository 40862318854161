import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import SmartFooter from './smart_footer';

const FooterApplication = ({ store }) => (
  <Provider store={ store }>
    <SmartFooter />
  </Provider>
);

FooterApplication.propTypes = {
  store: PropTypes.object.isRequired
};

export default FooterApplication;
