import Constants from 'arts/config/constants';
import {
  addFavoriteApi,
  removeFavoriteApi,
  loadFavoritesApi
} from 'arts/config/api';

export const add = (id, type) => (dispatch) => addFavoriteApi(id, type)
  .then(
    (favorite) => dispatch({ type: Constants.Favorites.ADD, favorite })
  ).catch(() => {});

export const remove = (id, type) => (dispatch) => removeFavoriteApi(id, type)
  .then(
    () => dispatch({ type: Constants.Favorites.REMOVE, favorite: { id, type } })
  ).catch(() => {});

export const load = () => (dispatch, getState) => {
  if (!window.currentUser) {
    return;
  }
  setTimeout(() => {
    const { favorites: { loaded, loading } } = getState();
    if (!loaded && !loading) {
      dispatch({ type: Constants.Favorites.LOAD });
      loadFavoritesApi().then((favorites) => dispatch({
        type: Constants.Favorites.LOADED,
        favorites
      })).catch(() => {});
    }
  }, 0);
};
