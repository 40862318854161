import { connect } from 'react-redux';
import { fetchProduct } from 'arts/actions/product';
// rework adding smart_add_product_to_card that will use addProductToCart directly
import { addProductToCart } from 'arts/actions/cart_items';

import Product from './product';

const mapStateToProps = ({ product: { data, error, loading } }) => ({
  data,
  error,
  loading
});

export default connect(mapStateToProps, {
  addProductToCart, fetchProduct
})(Product);
