import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  productShape
} from 'arts/reducers/product';
import Info from './itemInfo';

import Classes from './styles';

class ProductsListItem extends PureComponent {
  static propTypes = {
    data: productShape,
    addProductToCart: PropTypes.func.isRequired
  };

  render() {
    const {
      addProductToCart,
      data: {
        user,
        title,
        id,
        retailPrice,
        defaultImage
      }
    } = this.props;

    const { imageUrl } = defaultImage;
    const productLink = `/trios/${ id }`;
    const style = { backgroundImage: `url("${ imageUrl }")`, backgroundSize: 'cover' };
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return (
      <div className={ [Classes.root, `product-${ id }`].join(' ') }>
        <Link to={ productLink } className={ Classes.anchor }>
          <div
            style={ style }
            className={ Classes.image }
          />
        </Link>
        <Info
          title={ title }
          authorName={ user.name }
          price={ retailPrice }
          id={ id }
          isHidden= { false }
          productLink={ productLink }
          addProductToCart={ addProductToCart }
        />
      </div>
    );
    /* eslint-enable jsx-a11y/anchor-is-valid */
  }
}

export default ProductsListItem;
