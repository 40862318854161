import React from 'react';
import classnames from 'classnames';
import T from 'prop-types';
import { Link } from 'react-router-dom';
import PinIt from 'arts/components/Social/pin_it';
import Classes from './styles';
import Favorite from '../favorite';

export const author = (authorId, authorSlug, authorName, isMobile) => {
  if (!authorName || !authorId) {
    return null;
  }
  return (
    <p className={ classnames(Classes.author, { [Classes.mobile]: isMobile }) }>
      By&nbsp;
      <Link
        className={ Classes.name }
        to={ `/artists/${ authorSlug }-${ authorId }` }
      >
        { authorName }
      </Link>
    </p>
  );
};

const adminLink = (id) => (window.hasELinks ? (
  <>
    <a className={ Classes.admin } href={ `/admin/work_details/${ id }/edit` }>Admin</a>
    <br />
  </>
) : null);

export const renderTitle = (isHidden, title, id) => {
  const hiddenIcon = isHidden ? [
    <i key="eye" className="icon-eye-close" />,
    <span key="space">&nbsp;</span>
  ] : null;
  return (
    <p className={ Classes.heading }>
      { hiddenIcon }
      { title }
      { adminLink(id) }
    </p>
  );
};

const renderPrintedOn = (printedOn, limitedEdition) => {
  if (!printedOn) { return null; }
  return (
    <p className={ Classes.text }>
      Printed on&nbsp;
      { printedOn }
      { limitedEdition ? '' : ' (Open Edition)' }
    </p>
  );
};

const WorkSummary = ({
  title,
  authorName,
  authorId,
  authorSlug,
  description,
  printedOn,
  limitedEdition,
  isHidden,
  id,
  frameId,
  paperId,
  href,
  isMobile
}) => {
  const theTitle = (
    <div className={ Classes.title }>
      { renderTitle(isHidden, title, id) }
      <div className={ Classes.controls }>
        <PinIt
          href={ `${ window.location.protocol }//${ window.location.host }${ href }` }
          imageUrl={ `//${ window.location.host }/pinit/?work_id=${ id }&paper_id=${ paperId }&frame_id=${ frameId }` }
          description={ `${ title } on Artfully Walls` }
        />
        { id ? (<Favorite id={ id } type="Work" />) : null }
      </div>
    </div>
  );

  const s1 = isMobile ? (
    <div className={ classnames(Classes.mobileVisible, Classes.mobileHeader) }>
      { theTitle }
      { author(authorId, authorSlug, authorName, true) }
    </div>
  ) : null;

  const s2 = isMobile ? null : (
    <>
      <div className={ Classes.mobileHidden }>
        { theTitle }
        { author(authorId, authorSlug, authorName, false) }
      </div>
      <div className={ Classes.mobileHidden }>
        <p className={ Classes.text }>
          { description }
        </p>
        { renderPrintedOn(printedOn, limitedEdition) }
      </div>
    </>
  );

  return (
    <>
      { s1 }
      { s2 }
    </>
  );
};

WorkSummary.propTypes = {
  id: T.number,
  frameId: T.number,
  paperId: T.number,
  title: T.string,
  authorName: T.string,
  authorId: T.number,
  authorSlug: T.string,
  description: T.string,
  printedOn: T.string,
  limitedEdition: T.bool,
  isHidden: T.bool,
  href: T.string,
  isMobile: T.bool
};

export default WorkSummary;
