import React, { PureComponent } from 'react';
import T from 'prop-types';
import classnames from 'classnames';
import numeral from 'arts/config/numeral';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import eGiftCardsImage from 'arts/assets/e_gift_cards_image.png';
import GiftCardAddToCart from '../../AddToCart/smart_gift_card_add_to_cart';
import Classes from './styles';
import AppClasses from '../../Application/styles';
import GTMPageView from '../../Application/data_layer';
import { FORMAT } from '../../checkout_summary/checkout_summary';

dayjs.extend(customParseFormat);

const TITLE = 'Gift Card';
const AMOUNTS = [25.00, 50.00, 75.00, 100.00, 125.00, 150.00, 175.00, 200.00,
  250.00, 300.00, 400.00, 500.00, 1000.00, 1500.00, 2000.00];

const formatDate = (date, format) => dayjs(date).format(format);

const parseDate = (date, format) => dayjs(date, format).toDate();

const Amount = ({
  price, onClick, className, label
}) => {
  const handleClick = () => onClick(price);

  return (
    <div className={ className } onClick={ handleClick }>{ label }</div>
  );
};

Amount.propTypes = {
  className: T.string.isRequired,
  price: T.number.isRequired,
  label: T.string.isRequired,
  onClick: T.func.isRequired
};

/* eslint-disable no-useless-escape */
const validateEmail = (email) => {
  const  re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(email);
};
/* eslint-enable no-useless-escape */

class GiftCard extends PureComponent {
  static propTypes = {};

  constructor() {
    super();

    this.state = {
      amount: AMOUNTS[0],
      senderName: '',
      senderNameError: '',
      name: '',
      nameError: '',
      email: '',
      emailError: '',
      message: '',
      messageError: '',
      daliveryDate: dayjs().format('YYYY-MM-DD')
    };
    this.selectAmount = this.selectAmount.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSenderName = this.onChangeSenderName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.toggleCalendare = this.toggleCalendare.bind(this);
    this.isRequiredFieldsEmpty = this.isRequiredFieldsEmpty.bind(this);
  }

  onChangeSenderName() {
    this.setState({ senderName: this.senderName.value, senderNameError: '' });
  }

  onChangeName() {
    this.setState({ name: this.name.value, nameError: '' });
  }

  onChangeEmail() {
    this.setState({ email: this.email.value, emailError: '' });
  }

  onChangeMessage() {
    this.setState({ message: this.message.value, messageError: '' });
  }

  onChangeDate(date) {
    this.setState({ daliveryDate: date });
  }

  selectAmount(amount) {
    this.setState({ amount });
  }

  isRequiredFieldsEmpty() {
    const {
      name, senderName, email, message
    } = this.state;

    let result = false;
    if (name.trim() === '') {
      this.setState({ nameError: 'This field is required' });
      result = true;
    }
    if (senderName.trim() === '') {
      this.setState({ senderNameError: 'This field is required' });
      result = true;
    }
    if (email.trim() === '') {
      this.setState({ emailError: 'This field is required' });
      result = true;
    }

    if (message.trim() === '') {
      this.setState({ messageError: 'This field should not be blank.' });
      result = true;
    }

    if (!validateEmail(email)) {
      this.setState({ emailError: 'Email not valid' });
      result = true;
    }
    return result;
  }

  toggleCalendare() {
    this.dayPicker.showDayPicker();
    this.dayPicker.input.focus();
  }

  renderAmount(n) {
    const { amount: sAmount } = this.state;
    return (
      <div className={ Classes.containerAmounts }>
        {
           AMOUNTS.map(amount => {
             let className = Classes.amount;
             if (amount === sAmount) {
               className += ` ${ Classes.selected }`;
             }
             return (
               <Amount
                 className={ className }
                 key={ amount }
                 price={ amount }
                 label={ n(amount).format(FORMAT) }
                 onClick={ this.selectAmount }
               />
             );
           })
        }
      </div>
    );
  }

  render() {
    const {
      senderNameError, senderName, name, nameError, email,
      emailError, message, messageError, daliveryDate, amount
    } = this.state;

    numeral.locale(window.currentLocale.currentLocale);
    const from = numeral(Math.min(...AMOUNTS)).format(FORMAT);
    const to = numeral(Math.max(...AMOUNTS)).format(FORMAT);

    return (
      <div className={ AppClasses.container20 }>
        <GTMPageView pageType="category" section="gift cards" />
        <div className={ classnames(AppClasses.innerContainer20, Classes.root)  }>
          <div className={ Classes.left }>
            <img src={ eGiftCardsImage } alt="e-Gift Card" className={ Classes.image } />
          </div>
          <aside className={ Classes.right }>
            <div className={ Classes.heading }>{ TITLE }</div>
            <div className={ Classes.priceFork }>{ `${ from } - ${ to }` }</div>
            <div className={ Classes.text }>Required *</div>
            <div className={ Classes.section }>
              <div className={ Classes.title }>Amount *</div>
              { this.renderAmount(numeral) }
            </div>
            <div className={ Classes.section }>
              <div className={ Classes.title }>Sender&#39;s Name *</div>
              <input
                type="text"
                placeholder="Enter a Name"
                value={ senderName }
                onChange={ this.onChangeSenderName }
                ref={ (el) => { this.senderName = el; } }
                className={ Classes.input }
              />
              <div className={ Classes.error }>{ senderNameError }</div>
            </div>
            <div className={ Classes.section }>
              <div className={ Classes.title }>Recipient&#39;s Name *</div>
              <input
                type="text"
                placeholder="Enter a Name"
                value={ name }
                onChange={ this.onChangeName }
                ref={ (el) => { this.name = el; } }
                className={ Classes.input }
              />
              <div className={ Classes.error }>{ nameError }</div>
            </div>
            <div className={ Classes.section }>
              <div className={ Classes.title }>Recipient&#39;s Email *</div>
              <input
                type="email"
                placeholder="Enter a Email Adress"
                value={ email }
                onChange={ this.onChangeEmail }
                ref={ (el) => { this.email = el; } }
                className={ Classes.input }
              />
              <div className={ Classes.error }>{ emailError }</div>
            </div>
            <div className={ Classes.section }>
              <div className={ Classes.title }>Gift Message *</div>
              <textarea
                maxLength="200"
                placeholder="Enter a Message"
                value={ message }
                onChange={ this.onChangeMessage }
                className={ Classes.textarea }
                ref={ (el) => { this.message = el; } }
              />
              <div className={ Classes.underMessage }>
                <div className={ Classes.remainder }>{ 200 - message.length }</div>
                <div className={ Classes.error }>{ messageError }</div>
              </div>
            </div>
            <div className={ Classes.section }>
              <div className={ Classes.title }>Delivery Date *</div>
              <div className={ Classes.dateContainer }>
                <DayPickerInput
                  value={ daliveryDate }
                  format="YYYY-MM-DD"
                  formatDate={ formatDate }
                  parseDate={ parseDate }
                  onDayChange={ this.onChangeDate }
                  ref={ (el) => { this.dayPicker = el; } }
                  inputProps={ { readOnly: true, className: Classes.date  } }
                  className={ { overlay: Classes.selected } }
                  dayPickerProps={
                    {
                      disabledDays: {  before: new Date()  },
                      modifiersStyles: {
                        selected: {
                          backgroundColor: 'black'
                        }
                      }
                    }
                  }
                />
                <i className= { `icon-calendar-empty  ${ Classes.icon }` } onClick={ this.toggleCalendare } />
              </div>
            </div>
            <GiftCardAddToCart
              price={ amount }
              label={ numeral(amount).format(FORMAT) }
              senderName={ senderName }
              name={ name }
              email={ email }
              message={ message }
              daliveryDate={ String(daliveryDate) }
              isRequiredFieldsEmpty={ this.isRequiredFieldsEmpty }
            />
          </aside>
        </div>
      </div>
    );
  }
}

export default GiftCard;
