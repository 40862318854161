import React from 'react';
import classnames from 'classnames';
import AppClasses from '../Application/styles';
import Classes from './styles';
import QueryForm from './query_form';

const QueryOrderStatus = () => (
  <div className={ AppClasses.container20 }>
    <div className={ classnames(AppClasses.innerContainer20, Classes.root) }>
      <QueryForm />
    </div>
  </div>
);

export default QueryOrderStatus;
