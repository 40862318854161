import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AliceCarousel from 'react-alice-carousel';
import { handleDragStart } from 'arts/components/slider_arrows';
import left from './left.svg';
import right from './right.svg';
import { trackEvent } from '../../config/analytics';

export const renderPicture = ({ sources, img }, name) => (
  <picture>
    {
      sources.map(
        (item, index) => <source key={ index } { ...item } />
      )
    }
    <img { ...img } alt={ name } />
  </picture>
);

const HpPromoV2 = ({ config }) => {
  const items = config.map((
    {
      id, href, name, img, svg
    }
  ) => (
    <a
      onDragStart={ handleDragStart }
      onDragEnd={ handleDragStart }
      href={ href }
      title={ name }
      key={ id }
    >
      { renderPicture(img, name) }
      { renderPicture(svg, name) }
    </a>
  ));

  const renderPrevButton = ({ isDisabled }) => {
    if (isDisabled) {
      return null;
    }
    return (
      <button type="button">
        <img src={ left } alt="left" />
      </button>
    );
  };

  const renderNextButton = ({ isDisabled }) => {
    if (isDisabled) {
      return null;
    }
    return (
      <button type="button">
        <img src={ right } alt="left" />
      </button>
    );
  };

  useEffect(() => {
    trackEvent('home page');
  }, []);

  return (
    <AliceCarousel
      animationDuration={ 400 }
      autoPlayStrategy="action"
      autoPlay
      autoPlayInterval={ 5000 }
      // disableButtonsControls
      // disableDotsControls
      renderPrevButton={ renderPrevButton }
      renderNextButton={ renderNextButton }
      disableSlideInfo
      infinite
      // autoWidth
      // autoHeight
      mouseTracking
      mouseDragEnabled
      responsive={ { 0: { items: 1 } } }
      ssrSilentMode
    >
      { items }
    </AliceCarousel>
  );
};

HpPromoV2.propTypes = {
  config: PropTypes.array.isRequired
};

export default HpPromoV2;
