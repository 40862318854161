import React, { Suspense } from 'react';
import { Loader } from 'semantic-ui-react';

const Explore = React.lazy(() => import('./async_index'));

export default () => (
  <Suspense fallback={ <Loader active inline /> }>
    <Explore />
  </Suspense>
);
