import { connect } from 'react-redux';
import {
  fetchCartItems, removeCartItem
} from 'arts/actions/cart_items';
import CartItems from './cart_items';

const mapStateToProps = ({ cartItems }) => cartItems || {};

export default connect(mapStateToProps, {
  fetchCartItems, removeCartItem
})(CartItems);
