import { createSelector } from 'reselect';

const getFavories = ({ favorites }) => favorites;

const getProps = (_, { id, type }) => ({ type, id });

export const fetchFavorites = createSelector(
  [getFavories, getProps],
  (favorites, { id, type }) => {
    const { loaded, loading, ...rest } = favorites;
    const res = {
      loaded,
      loading
    };
    const favorite = (rest[type] || {})[id];
    if (favorite) {
      res.isAdded = true;
    }
    return res;
  }
);
